export default {
    methods: {
        formatUrlProtocol(url) {
            const formattedUrl = url.replace(/ /g, '');

            return formattedUrl.match(/^(http|https)?:/)
                ? formattedUrl
                : 'http://' + formattedUrl;
        },
        async downloadFile(url, nom) {
            const downloadUrl = `${process.env.VUE_APP_API_SAFIR}/${url}`;
            const fileLink = document.createElement('a');

            fileLink.href = downloadUrl;
            fileLink.setAttribute('download', nom);
            document.body.appendChild(fileLink);
            const simulateClick = function (elem) {
                // bubbles: false to avoid closing modal on download
                const evt = new MouseEvent('click', { bubbles: false });

                elem.dispatchEvent(evt);
            };

            simulateClick(fileLink);
            document.body.removeChild(fileLink);
        }
    }
};
