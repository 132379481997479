<template>
  <label
    class="refonte-checkbox"
    v-bind:class="props.class">
    <input
      type="checkbox"
      class="checkbox-input"
      v-bind:checked="props.value"
      v-on:change="(e) => updateValue(e)" />
    <span class="checkbox-background"></span>
    <span class="checkbox-checkmark"></span>
    <div
      v-if="props.label"
      class="checkbox-label">
      <div>
        <span>{{ props.label }}</span>
        <span v-if="props.sub_label">{{ props.sub_label }}</span>
      </div>
      <refonte-number
        v-if="props.number"
        variant="filters"
        class="checkbox-label-number">
        {{ props.number }}
      </refonte-number>
    </div>
  </label>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      value?: boolean;
      label?: string;
      sub_label?: string;
      number?: number;
      class?: string;
    }>(),
    {
      value: false,
      class: ''
    }
  );

  const emit = defineEmits(['input']);

  function updateValue(e: Event) {
    const { checked } = e.target as unknown as { checked: boolean };
    emit('input', checked);
  }
</script>

<style lang="scss" scoped>
  .refonte-checkbox {
    @apply relative inline-flex cursor-pointer items-center self-stretch bg-transparent text-refonte_blue-600;
    padding: 7px 8px 7px 26px;
    border-radius: 4px;
    gap: 6px;
    user-select: none;

    .checkbox-input {
      @apply absolute h-0 w-0 cursor-pointer opacity-0;
    }
    .checkbox-background {
      @apply absolute bottom-0 left-0 right-0 top-0;
      border-radius: 4px;
    }
    input:checked ~ .checkbox-background {
      @apply bg-refonte-pale-violet-2;
    }
    .checkbox-checkmark {
      @apply absolute border border-refonte_blue-100 bg-refonte-white;
      top: 10px;
      left: 8px;
      height: 12px;
      width: 12px;
      border-radius: 3px;

      &:after {
        @apply absolute;
        content: '';
        display: none;
        left: 3px;
        top: 1px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    input:checked ~ .checkbox-checkmark {
      @apply absolute border-refonte_blue-600 bg-refonte_blue-600;

      &:after {
        display: block;
      }
    }
    .checkbox-label {
      @apply relative flex flex-grow cursor-pointer items-center text-refonte_blue-600;
      gap: 2px;
      z-index: 1;

      span {
        @apply overflow-hidden text-ellipsis;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
      }

      .checkbox-label-number {
        @apply text-refonte_blue-200;
      }
    }

    &:hover {
      .checkbox-background {
        @apply bg-refonte-pale-violet-3;
      }
      input:checked ~ .checkbox-background {
        @apply bg-refonte-pale-violet-3;
      }
    }
  }
</style>
