<template>
  <template v-if="!loading">
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <formations-number
          v-bind:number="annuaire.length"
          v-bind:label="annuaire.length > 1 ? 'Contacts' : 'Contact'" />
        <IconSeparatorVertical />
        <refonte-button
          v-bind:disabled="activeEntrepriseId === ALL_ENTREPRISE_FILTER"
          v-bind:on_click="() => setIsAddContactModalOpen(true)"
          v-bind:title="
            activeEntrepriseId === ALL_ENTREPRISE_FILTER
              ? 'Veuillez séléctionner un établissement pour pouvoir cliquer sur ce bouton'
              : undefined
          ">
          Ajouter un contact
        </refonte-button>
        <refonte-modal-add-contact
          v-bind:show="isAddContactModalOpen"
          v-bind:on_close="() => setIsAddContactModalOpen(false)"
          v-bind:on_submit="addContact" />
        <refonte-modal-info
          v-bind:show="isAlreadyExistModalOpen"
          v-bind:on_close="() => setIsAlreadyExistModalOpen(false)"
          content="Un contact existe déjà avec cette adresse email, ses informations ont été remplies automatiquement." />
      </div>
      <refonte-separator class="px-[12px]" />
      <annuaire-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import AnnuaireContent from '@/views/_espace/_refonte/gestion/_annuaire/annuaire-content.vue';
  import { useAnnuaireFilterStore, useAnnuaireStore } from '@/stores/annuaire';
  import { storeToRefs } from 'pinia';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import RefonteModalAddContact from '@/components/refonte/modals/refonte-modal-add-contact.vue';
  import RefonteModalInfo from '@/components/refonte/modals/refonte-modal-info.vue';
  import { API } from '@/http-common';
  import { AnnuaireForm } from '@/types/gestion/annuaire-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds, activeEntrepriseId } = storeToRefs(entrepriseStore);
  const annuaireFilterStore = useAnnuaireFilterStore();

  const { filters } = storeToRefs(annuaireFilterStore);

  const dataLoading = ref<boolean>(false);
  const annuaireStore = useAnnuaireStore();
  const { annuaire } = storeToRefs(annuaireStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await annuaireStore.fetchAnnuaire(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    annuaireFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();

  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });

  const isAddContactModalOpen = ref<boolean>(false);
  const isAlreadyExistModalOpen = ref<boolean>(false);

  function setIsAddContactModalOpen(value: boolean) {
    isAddContactModalOpen.value = value;
  }
  function setIsAlreadyExistModalOpen(value: boolean) {
    isAlreadyExistModalOpen.value = value;
  }
  async function addContact(contact: AnnuaireForm) {
    const body = {
      nom: contact.nom,
      prenom: contact.prenom,
      email: contact.email,
      clientId: activeEntrepriseId.value
    };

    const response = await API.post('client/v2/annuaires', body);
    if (response.status === 409) {
      isAlreadyExistModalOpen.value = true;
    }
  }
</script>

<style lang="scss" scoped></style>
