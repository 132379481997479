<template>
  <div class="p-[8px]">
    <template v-if="formations.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="formations-1"
        table_index="formations-1"
        main_tags_type="typeShortcut"
        main_tags_type_label="typeLabel"
        v-bind:main_sub_text="
          (row: Formation) =>
            `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu}`
        "
        v-bind:main_cursus="
          (row: Formation) => `${row.titreAlt ? row.titreAlt : ''}`
        "
        v-bind:main_cursus_on_click="goToCursusDetails"
        v-bind:main_data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:main_data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:columns="columns"
        v-bind:data="formations">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Formation } from '@/types/gestion/formations-types.js';
  import { useFormationStore } from '@/stores/formations';
  import { storeToRefs } from 'pinia';
  import { useRouter } from 'vue-router';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import { h } from 'vue';
  import FormationsTableItemDocuments from '@/views/_espace/_refonte/gestion/_formations/formations-table-item-documents.vue';

  const router = useRouter();
  const goToDetails = (row: Formation) => {
    router.push(
      `/refonte/gestion/session-participant?reference=${row.reference}&commandeId=${row.commandeId}&clientId=${row.clientId}`
    );
  };
  const goToCursusDetails = (row: Formation) => {
    return `/refonte/gestion/session-cursus?reference=${row.reference}&commandeId=${row.commandeId}&clientId=${row.clientId}`;
  };

  const columns = [
    {
      data_index: 'datesFormations',
      label: 'Dates de formations',
      width: 190
    },
    {
      data_index: 'participantNomPrenom',
      label: 'Participant',
      width: 140
    },
    {
      data_index: 'statutLabel',
      label: 'Statut',
      width: 85
    },
    {
      data_index: 'organisateur',
      label: 'Organisateur',
      width: 150
    },
    {
      data_index: 'id',
      label: 'Documents',
      width: 190,
      content_custom: (row: Formation) =>
        h(FormationsTableItemDocuments, {
          row
        }),
      class: (row: Formation) => {
        if (row.conventionStatut || row.pifStatut || row.certificatLabel) {
          return 'bg-refonte-pale-violet-2';
        }
        return '';
      }
    },
    {
      data_index: 'testPositionnementStatut',
      label: 'T. Posit.',
      width: 120,
      tooltip: 'Tests de positionnement'
    }
  ];

  const formationStore = useFormationStore();

  const { formations } = storeToRefs(formationStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
