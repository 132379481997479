<template>
  <div
    class="refonte-collapse"
    v-bind:class="props.class">
    <div
      class="collapse-header"
      v-on:click="() => toggleIsOpen()">
      <div class="collapse-header-content">
        <div class="collapse-header-title">
          <div
            v-if="props.title_icon"
            class="collapse-header-title-icon">
            <component v-bind:is="props.title_icon" />
          </div>
          <slot name="headerTags"></slot>
          <span
            v-bind:class="{
              'collapse-header-title-text': props.title_icon
            }">
            {{ props.title }}
          </span>
        </div>
        <div
          v-if="!!slots['headerSumUp']"
          class="collapse-header-sum-up">
          <slot name="headerSumUp"></slot>
        </div>
      </div>
      <div class="collapse-header-chevron">
        <IconChevronDown v-if="!isOpen" />
        <IconChevronUp v-else />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="collapse-content"
      v-bind:class="props.content_class">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconChevronUp from '@/assets/img/refonte/icons/display/icon-chevron-up.svg?component';
  import { ref, useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      title_icon?: Element;
      title: string;
      class?: string;
      content_class?: string;
      start_open?: boolean;
    }>(),
    {
      class: '',
      content_class: '',
      start_open: false
    }
  );

  const isOpen = ref<boolean>(props.start_open);

  function toggleIsOpen() {
    isOpen.value = !isOpen.value;
  }

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-collapse {
    @apply bg-refonte-white;
    border-radius: 8px;

    .collapse-header {
      @apply flex cursor-pointer items-center justify-between;
      padding: 8px;

      .collapse-header-content {
        @apply flex flex-wrap items-center;

        .collapse-header-title {
          @apply flex items-center;
          padding: 6px 10px;
          gap: 8px;

          .collapse-header-title-icon {
            @apply flex shrink-0 items-center justify-center;
            width: 24px;
            height: 24px;

            :deep(svg) {
              @apply text-refonte_blue-600;
              width: 24px;
              height: 24px;
            }
          }
          span {
            @apply text-refonte_blue-600;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;

            &.collapse-header-title-text {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .collapse-header-sum-up {
          @apply flex flex-wrap items-center;
          padding: 6px 8px;
          gap: 4px;

          :deep(.refonte-details-header-item) {
            .details-header-item-icon-left {
              :deep(svg) {
                @apply text-refonte_blue-600;
              }
            }
            span {
              @apply text-refonte_blue-600;
            }
            .details-header-item-separator {
              :deep(svg) {
                @apply text-refonte_blue-200;
              }
            }
            .details-header-item-icons {
              :deep(svg) {
                @apply text-refonte_blue-600;
              }
            }
          }
        }
      }
      .collapse-header-chevron {
        @apply flex shrink-0 items-center justify-center;
        width: 32px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
          height: 16px;
        }
      }
    }
    .collapse-content {
      padding: 0 8px 8px;
    }
  }
</style>
