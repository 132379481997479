export default {
    methods: {
        formatFilter(name, value) {
            return `${name}: ${value}`;
        },
        hasValue(value) {
            return value || value === false ? true : false;
        }
    }
};
