import { ContratResponse } from '@/types/gestion/contrats-types';

const natures = {
    CONTRAT: {
        id: 1,
        label: 'Contrat'
    },
    AVENANT: {
        id: 2,
        label: 'Avenant'
    }
};

const types = {
    ANNUEL: {
        id: 1,
        label: 'Annuel'
    },
    SESSION: {
        id: 2,
        label: 'Session'
    }
};

const statuts = {
    A_SIGNER: {
        id: 1,
        label: 'A signer'
    },
    SIGNE: {
        id: 2,
        label: 'Signé'
    },
    VALIDE: {
        id: 3,
        label: 'Validé'
    }
};

const modes = {
    INTER: {
        id: 1,
        label: 'Inter'
    },
    INTRA: {
        id: 2,
        label: 'Intra'
    }
};

const clients = {
    REELIANT: {
        id: 28389,
        denomination: 'Reeliant',
        siret: '832 014 872 00028'
    },
    CNCC_FORMATION: {
        id: 2,
        denomination: 'CNCC FORMATION'
    },
    STEAMULO: {
        id: 28390,
        denomination: 'Steamulo',
        siret: '795 367 655 00052'
    }
};

export const contratsData: ContratResponse[] = [
    {
        id: '1',
        client: clients.REELIANT,
        nature: natures.CONTRAT,
        formateur: {
            id: 1,
            nom: 'Doe',
            prenom: 'John'
        },
        periode: 'ABC',
        type: types.ANNUEL,
        formation: 'Elearning (Stage EC)',
        organisme: 'CNCC Formation',
        date: '02/09/2024',
        lieu: 'Paris',
        mode: modes.INTRA,
        statut: statuts.A_SIGNER
    },
    {
        id: '2',
        client: clients.CNCC_FORMATION,
        nature: natures.AVENANT,
        formateur: {
            id: 2,
            nom: 'Jin',
            prenom: 'Alexandre'
        },
        periode: 'ABC',
        type: types.SESSION,
        formation: 'Webinaire 123',
        organisme: 'Test',
        date: '01/09/2024',
        lieu: 'En ligne',
        statut: statuts.SIGNE
    },
    {
        id: '3',
        client: clients.STEAMULO,
        nature: natures.CONTRAT,
        formateur: {
            id: 2,
            nom: 'Jin',
            prenom: 'Alexandre'
        },
        periode: 'ABC',
        type: types.ANNUEL,
        formation: 'Element de cursus',
        organisme: 'CFPC',
        date: '30/08/2024',
        lieu: 'Paris',
        mode: modes.INTER,
        statut: statuts.VALIDE
    },
    {
        id: '4',
        client: clients.CNCC_FORMATION,
        nature: natures.AVENANT,
        formateur: {
            id: 1,
            nom: 'Doe',
            prenom: 'John'
        },
        periode: 'ABC',
        type: types.SESSION,
        formation: 'Webinaire 456',
        organisme: 'Test',
        date: '28/08/2024',
        lieu: 'En ligne'
    }
];
