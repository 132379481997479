<template>
  <template v-if="!loading">
    <component
      v-if="getComponent()"
      v-bind:is="getComponent()" />
    <error
      v-else
      no_return_home />
  </template>
  <template v-else>
    <div class="w-full lg:h-full">
      <spinner
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </template>
</template>
<script setup lang="ts">
  import spinner from '@/components/utils/spinner.vue';
  import error from '@/views/errors/error-404.vue';
  import GestionInscriptions from '@/views/_espace/_refonte/gestion/formations.vue';
  import GestionAccueil from '@/views/_espace/_refonte/gestion/accueil.vue';
  import GestionSessionParticipant from '@/views/_espace/_refonte/gestion/formations-details.vue';
  import GestionSessionCursus from '@/views/_espace/_refonte/gestion/formations-details-cursus.vue';
  import GestionFactures from '@/views/_espace/_refonte/gestion/factures.vue';
  import GestionCommandes from '@/views/_espace/_refonte/gestion/commandes.vue';
  import GestionInterlocuteurs from '@/views/_espace/_refonte/gestion/interlocuteurs.vue';
  import GestionEtablissements from '@/views/_espace/_refonte/gestion/etablissements.vue';
  import GestionAnnuaire from '@/views/_espace/_refonte/gestion/annuaire.vue';
  import GestionSatisfaction from '@/views/_espace/_refonte/gestion/satisfaction.vue';
  import GestionContrats from '@/views/_espace/_refonte/gestion/contrats.vue';
  import GestionEtablissementsDetails from '@/views/_espace/_refonte/gestion/etablissements-details.vue';
  import { onMounted, ref, Ref, watch } from 'vue';
  import { useArborescenceStore } from '@/stores/arborescence';
  import { useAccountStore } from '@/stores/account';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { useRoute } from 'vue-router';
  import { useCatalogueStore } from '@/stores/catalogue';
  const props = withDefaults(
    defineProps<{
      space_id: string;
      sub_space_id: string;
    }>(),
    {}
  );

  const components: {
    [key: string]: any;
  } = {
    GestionInscriptions,
    GestionAccueil,
    GestionSessionParticipant,
    GestionSessionCursus,
    GestionCommandes,
    GestionFactures,
    GestionInterlocuteurs,
    GestionEtablissements,
    GestionAnnuaire,
    GestionSatisfaction,
    GestionContrats,
    GestionEtablissementsDetails
  };

  function getComponent() {
    const rubriqueName =
      toPascalCase(props.space_id) + toPascalCase(props.sub_space_id);
    return components[rubriqueName];
  }
  const toPascalCase = (s: string) => {
    let newString = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    const separatorIndex = newString.indexOf('-');
    if (separatorIndex > -1) {
      newString = newString.replace('-', '');
      newString =
        newString.substring(0, separatorIndex) +
        newString.charAt(separatorIndex).toUpperCase() +
        newString.substring(separatorIndex + 1);
    }
    return newString;
  };

  const loading: Ref<boolean> = ref(false);
  watch(
    () => props.space_id,
    (newId) => {
      loading.value = true;
      arborescenceStore.setActiveSpaceId(newId);
      loading.value = false;
    }
  );
  watch(
    () => props.sub_space_id,
    (newId) => {
      loading.value = true;
      arborescenceStore.setActiveSubSpaceId(newId);
      loading.value = false;
    }
  );

  const arborescenceStore = useArborescenceStore();

  const accountStore = useAccountStore();

  const entrepriseStore = useEntrepriseStore();
  const fetchUserInfo = async () => {
    await accountStore.getProfile();
  };
  const route = useRoute();
  const catalogueStore = useCatalogueStore();
  const fetchEspaceInfo = async (spaceId: string) => {
    const { profile } = accountStore;
    if (spaceId === 'gestion' && profile.entreprise) {
      await entrepriseStore.getProfile();
      await entrepriseStore.initActiveEntrepriseIdFromQuery(
        route.query.clientIds
      );
    }
    await catalogueStore.fetchCatalogues();
  };
  onMounted(async () => {
    loading.value = true;
    arborescenceStore.setActiveSpaceId(props.space_id);
    arborescenceStore.setActiveSubSpaceId(props.sub_space_id);
    await fetchUserInfo();
    await fetchEspaceInfo(props.space_id);

    loading.value = false;
  });
</script>

<style lang="scss" scoped></style>
