<template>
  <div
    class="refonte-filters-tabs-item"
    v-bind:class="`${props.selected ? 'filters-tabs-item-selected' : ''} ${props.disabled ? 'filters-tabs-item-disabled' : ''} ${props.class}`"
    v-on:click="!props.disabled && props.on_click">
    <refonte-number
      v-if="!props.number_right"
      variant="segment"
      class="filters-tabs-item-number">
      {{ props.number }}
    </refonte-number>
    <span class="filters-tabs-item-text">
      {{ props.label }}
    </span>
    <refonte-number
      v-if="props.number_right"
      variant="filters"
      class="filters-tabs-item-number">
      {{ props.number }}
    </refonte-number>
  </div>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      selected?: boolean;
      disabled?: boolean;
      label: string;
      number?: number;
      number_right?: boolean;
      on_click?: Function;
      class?: string;
    }>(),
    {
      selected: false,
      disabled: false,
      number_right: false,
      number: 0,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-filters-tabs-item {
    @apply inline-flex shrink-0 cursor-pointer items-center justify-center bg-transparent text-refonte_blue-600;
    gap: 6px;
    border-radius: 8px;
    padding: 10px 12px;

    .filters-tabs-item-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
    }
    .filters-tabs-item-number {
      height: 16px;
      line-height: 16px;
      padding: 0;

      :deep(span) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    &.filters-tabs-item-selected {
      @apply bg-refonte_blue-600 text-refonte-white;

      .filters-tabs-item-number {
        @apply text-refonte-white;
      }
    }
    &.filters-tabs-item-disabled {
      @apply cursor-not-allowed;
      opacity: 0.2;
    }
  }
</style>
