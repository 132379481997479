<template>
  <template v-if="!loading">
    <refonte-page-title v-bind:description_in_card="true">
      Bienvenue dans le nouvel Espace entreprise JINIUS<br />
      Ce nouvel espace vous permettra de trouver plus facilement les
      informations sur vos formations ainsi que les documents associés.<br />
      Cet écran vous fournit également la liste des actions que vous avez à
      faire.
    </refonte-page-title>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      title="Inscriptions"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => router.push('/refonte/gestion/inscriptions')">
          <b>{{ nombreInscriptionsEnAttente }}</b> inscription(s) actuellement
          en attente de confirmation
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push(
                '/refonte/gestion/inscriptions?action=T_POS_NON_REALISE'
              )
          ">
          <b>{{ nombreInscriptionsTestsPositionnementNonRealise }}</b>
          participant(s) n'ont pas encore rempli leur test de positionnement.
          Ceci est nécessaire pour confirmer leur inscription
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push(
                '/refonte/gestion/inscriptions?action=CONVENTIONS_A_SIGNER'
              )
          ">
          <b>{{ nombreInscriptionsConventionsASigner }}</b> convention(s)
          actuellement en attente de signature
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push('/refonte/gestion/inscriptions?action=PIF_A_SIGNER')
          ">
          <b>{{ nombreInscriptionsPifsASigner }}</b> PIF (Protocole Individuel
          de Formation) actuellement en attente de signature
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="
              () => router.push('/refonte/gestion/inscriptions')
            ">
            Retrouvez vos inscriptions dans le menu Inscriptions
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconCommandes"
      title="Commandes"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <div>
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/commandes')">
            Retrouvez vos commandes dans le menu Commandes
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/refonte/gestion/factures?typeIds=2')
          ">
          <b>{{ nombreFacturesARegler }}</b> facture(s) non acquittées
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/factures')">
            Retrouvez toutes vos factures dans le menu Factures
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconTestPositionnement"
      title="Contrats"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/refonte/gestion/contrats?statutId=1')
          ">
          <b>{{ nombreContratsASigner }}</b> contrat(s) actuellement en attente
          de signature
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/contrats')">
            Retrouvez vos contrats dans le menu Contrats
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>
<script setup lang="ts">
  import RefontePageTitle from '@/components/refonte/titles/refonte-page-title.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { router } from '@/router';
  import { useContratStore } from '@/stores/contrats';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref } from 'vue';
  import { Contrat } from '@/types/gestion/contrats-types';
  import { useFactureStore } from '@/stores/factures';
  import { Facture } from '@/types/gestion/factures-types';
  import { useFormationStore } from '@/stores/formations';
  import { Formation } from '@/types/gestion/formations-types';
  import Spinner from '@/components/utils/spinner.vue';
  import IconTestPositionnement from '@/assets/img/refonte/icons/information/icon-test-positionnement.svg?component';

  const loading = ref(false);

  const formationStore = useFormationStore();
  const { formations } = storeToRefs(formationStore);

  const nombreInscriptionsEnAttente = computed(
    () =>
      formations.value.filter(
        (f: Formation) => f.statutLabel && f.statutLabel === 'En attente'
      ).length
  );

  const nombreInscriptionsConventionsASigner = computed(
    () =>
      formations.value.filter(
        (f: Formation) =>
          f.conventionStatut && f.conventionStatut === 'A signer'
      ).length
  );

  const nombreInscriptionsPifsASigner = computed(
    () =>
      formations.value.filter((f: Formation) => f.pifStatut === 'A signer')
        .length
  );

  const nombreInscriptionsTestsPositionnementNonRealise = computed(
    () =>
      formations.value.filter(
        (f: Formation) => f.testPositionnementStatut === 'Non réalisé'
      ).length
  );

  const contratStore = useContratStore();
  const { contrats } = storeToRefs(contratStore);

  const nombreContratsASigner = computed(
    () =>
      contrats.value.filter(
        (c: Contrat) => c.statutLabel && c.statutLabel === 'A signer'
      ).length
  );

  const factureStore = useFactureStore();
  const { factures } = storeToRefs(factureStore);

  const nombreFacturesARegler = computed(
    () =>
      factures.value.filter(
        (f: Facture) => f.statutLabel && f.statutLabel === 'Non acquittée'
      ).length
  );

  const fetchData = async () => {
    loading.value = true;
    await formationStore.fetchFormations({});
    await contratStore.fetchContrats({});
    await factureStore.fetchFactures({});
    loading.value = false;
  };

  onMounted(() => {
    fetchData();
  });
</script>

<style lang="scss" scoped>
  .btn-dashbord-container {
    @apply flex flex-col;
    margin-bottom: 8px;
    gap: 8px;
    padding: 0 10px;

    .btn-dashboard {
      @apply w-full justify-between text-left text-refonte-black;
      max-width: 100% !important;

      b {
        @apply mr-1 text-refonte_blue-600;
      }
    }
    :deep(.refonte-btn.btn-secondary span) {
      overflow: initial;
      text-overflow: initial;
      display: initial;
      -webkit-box-orient: initial;
      -webkit-line-clamp: initial;
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
    }
  }
</style>
