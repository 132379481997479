<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    v-bind:class="
      selectedFormation
        ? 'refonte-conventions-modal-detail'
        : 'refonte-conventions-modal-list'
    ">
    <template v-slot:modalHeader>
      <div class="refonte-conventions-header">
        <h2>Gestion des conventions</h2>
        <span v-if="selectedFormation">
          Télécharger en masse les conventions individuelles de plusieurs
          participants Charger chaque convention signée associé au participant
          concerné
        </span>
        <span v-else>
          Téléchargez en masse les conventions collectives et individuelles
          d’une ou plusieurs formations puis chargez les conventions signées
          dans la formation concernée en quelques clics
        </span>
      </div>
    </template>
    <template v-slot:modalBody>
      <div
        v-if="selectedFormation"
        class="flex flex-col gap-[16px]">
        <div class="flex flex-col gap-[4px] self-stretch">
          <refonte-table
            table_index="conventions-formation"
            v-bind:table_small="true"
            main_tags_count="count"
            main_tags_type="type"
            main_tags_type_label="typeLabel"
            v-bind:main_width="344"
            v-bind:columns="columns"
            v-bind:data="selectedFormation">
            <template v-slot:itemTags="{ rowIndex }">
              <refonte-tag v-bind:icon="IconFlagParcours">
                <refonte-number variant="count">{{ rowIndex }}</refonte-number>
              </refonte-tag>
            </template>
          </refonte-table>
          <refonte-separator />
        </div>
        <div class="flex flex-col gap-[8px] self-stretch">
          <refonte-item-convention
            v-bind:icon="IconConventionCollective"
            title="Convention collective"
            subtitle="Tous les participants"
            v-bind:on_click_download="
              () => console.log('Téléchargement convention')
            "
            v-bind:on_select="() => console.log('Téléverser')"
            select_label="Téléverser"
            convention_signed_date="Chargée le 31/09/2023"
            v-bind:on_click_download_signed="
              () => console.log('Téléchargement convention signée')
            " />
          <refonte-separator />
        </div>
        <refonte-item-convention
          v-bind:icon="IconMyAccount"
          title="Conventions individuelles"
          subtitle="Par participant"
          v-bind:no_status="true" />
        <div class="flex flex-grow flex-col gap-[6px] self-stretch">
          <div class="flex flex-wrap items-center gap-[8px] pl-[4px]">
            <refonte-checkbox
              label="Tout sélectionner"
              class="!self-center" />
            <div class="refonte-conventions-select">
              <refonte-filters-item>
                Tous les conventions (350)
                <template v-slot:dropdownContent>
                  <refonte-dropdown-item v-bind:number="2490">
                    Tous les statuts
                  </refonte-dropdown-item>
                  <refonte-dropdown-item v-bind:number="350">
                    En attente de signature
                  </refonte-dropdown-item>
                  <refonte-dropdown-item v-bind:number="12">
                    À venir
                  </refonte-dropdown-item>
                </template>
              </refonte-filters-item>
            </div>
            <refonte-button disabled>Télécharger</refonte-button>
          </div>
          <refonte-separator class="px-[8px]" />
          <div class="flex flex-grow flex-col gap-[8px]">
            <div class="px-[32px]">
              <refonte-search placeholder="Rechercher un participant..." />
            </div>
            <div class="refonte-conventions-participants">
              <div class="mr-[8px] flex flex-col gap-[4px] self-stretch">
                <refonte-item-convention
                  v-for="(participant, index) in participants"
                  v-bind:key="index"
                  v-bind:selectable="true"
                  v-bind:participant_status="participant.status"
                  v-bind:title="participant.name"
                  v-bind:subtitle="participant.email"
                  v-bind:on_click_download="
                    () => console.log('Téléchargement convention')
                  "
                  v-bind:on_select="() => console.log('Sélectionner')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-[12px]">
        <div class="flex flex-wrap items-end gap-[8px] pl-[4px]">
          <div class="flex h-[38px] items-center">
            <refonte-checkbox
              label="Tout sélectionner"
              class="!self-center" />
          </div>
          <div class="refonte-conventions-select select-formations">
            <refonte-label label="Formations" />
            <refonte-filters-item>
              Toutes (2490)
              <template v-slot:dropdownContent>
                <refonte-dropdown-item v-bind:number="2490">
                  Toutes
                </refonte-dropdown-item>
                <refonte-dropdown-item v-bind:number="3">
                  Aujourd’hui / En cours
                </refonte-dropdown-item>
                <refonte-dropdown-item v-bind:number="89">
                  À venir
                </refonte-dropdown-item>
                <refonte-dropdown-item v-bind:number="2380">
                  Terminées
                </refonte-dropdown-item>
              </template>
            </refonte-filters-item>
          </div>
          <div class="refonte-conventions-select select-conventions">
            <refonte-label label="Conventions" />
            <refonte-filters-item>
              En attente de signature (320)
              <template v-slot:dropdownContent>
                <refonte-dropdown-item v-bind:number="17290">
                  Toutes les conventions
                </refonte-dropdown-item>
                <refonte-dropdown-item v-bind:number="350">
                  En attente de signature
                </refonte-dropdown-item>
                <refonte-dropdown-item v-bind:number="16970">
                  Signées
                </refonte-dropdown-item>
              </template>
            </refonte-filters-item>
          </div>
          <refonte-button disabled>Télécharger</refonte-button>
        </div>
        <refonte-separator class="px-[8px]" />
        <div class="flex flex-grow flex-col gap-[8px]">
          <div class="px-[16px]">
            <refonte-search placeholder="Rechercher une formation..." />
          </div>
          <div class="refonte-conventions-formations">
            <refonte-table
              table_index="conventions-formations"
              v-bind:selectable="true"
              v-bind:table_small="true"
              main_tags_count="count"
              main_tags_type="type"
              main_tags_type_label="typeLabel"
              v-bind:main_width="344"
              v-bind:columns="columns"
              v-bind:data="props.formations"
              v-bind:on_details="gotToDetails">
              <template v-slot:itemTags="{ rowIndex }">
                <refonte-tag v-bind:icon="IconFlagParcours">
                  <refonte-number variant="count">{{
                    rowIndex
                  }}</refonte-number>
                </refonte-tag>
              </template>
            </refonte-table>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div
        v-if="selectedFormation"
        class="refonte-conventions-footer">
        <refonte-number variant="count">0</refonte-number>
        <span>Convention ajoutée / 12</span>
      </div>
      <div
        v-else
        class="refonte-conventions-footer mb-[32px]">
        <refonte-number variant="count">320</refonte-number>
        <span>Conventions en attente de signature</span>
      </div>
      <refonte-button
        v-if="selectedFormation"
        disabled
        v-bind:on_click="props.on_ok">
        Téléverser les conventions
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteItemConvention from '@/components/refonte/items/refonte-item-convention.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteSearch from '@/components/refonte/inputs/refonte-search.vue';
  import { ref, watch } from 'vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';

  const props = withDefaults(
    defineProps<{
      formations: any[];
      show: boolean;
      on_close: Function;
      on_ok: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const selectedFormation = ref<any>(
    props.formations && props.formations.length === 1
      ? props.formations
      : undefined
  );

  const columns = [
    {
      data_index: 'convention',
      data_icon: () => IconMyAccount,
      label: 'Convention/pif',
      width: 144
    }
  ];
  const participants = [
    {
      status: 'active',
      name: 'Salma BOUZIDI',
      email: 'salma.bouzidi@doka-bastia.com'
    },
    {
      status: 'active',
      name: 'Anne CORMIER',
      email: 'anne.cormier@doka-perpignan.com'
    },
    {
      status: 'active',
      name: 'Serge DOMINIQUE',
      email: 'serge.dominique@doka-stetienne.com'
    },
    {
      status: 'active',
      name: 'Mickaël FRANCONI',
      email: 'mickael.bouzidi@doka-bastia.com'
    },
    {
      status: 'active',
      name: 'Salma BOUZIDI',
      email: 'salma.bouzidi@doka-bastia.com'
    },
    {
      status: 'active',
      name: 'Anne CORMIER',
      email: 'anne.cormier@doka-perpignan.com'
    },
    {
      status: 'active',
      name: 'Serge DOMINIQUE',
      email: 'serge.dominique@doka-stetienne.com'
    },
    {
      status: 'active',
      name: 'Mickaël FRANCONI',
      email: 'mickael.bouzidi@doka-bastia.com'
    }
  ];

  watch(
    () => props.show,
    (previousValue, newValue) => {
      if (previousValue !== newValue) {
        selectedFormation.value =
          props.formations && props.formations.length === 1
            ? props.formations
            : undefined;
      }
    }
  );

  function gotToDetails(row: any) {
    selectedFormation.value = [row];
  }
</script>

<style lang="scss" scoped>
  :deep(.refonte-modal) {
    .modal-header {
      padding-top: 8px;
      padding-bottom: 20px;
    }
    .modal-footer {
      @apply flex-col items-center justify-center;
      padding-top: 24px;
      gap: 16px;
    }
  }
  .refonte-conventions-header {
    @apply flex flex-grow flex-col justify-center;
    gap: 4px;

    span {
      @apply text-refonte-black-60;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }
  }
  :deep(.refonte-table) {
    .refonte-table-row {
      .table-row-content {
        .refonte-table-item {
          .table-item-content {
            .table-item-content-left {
              .table-item-content-icon {
                svg {
                  @apply text-refonte-black;
                }
              }
            }
          }
        }
      }
    }
  }
  .refonte-conventions-select {
    :deep(.refonte-filters-item) {
      @apply w-full justify-between border border-refonte_blue-100;
    }
  }
  :deep(.refonte-search-form) {
    @apply bg-refonte-pale-violet-2;
    border-radius: 4px;
    max-width: 320px;

    .search-form-input {
      @apply bg-refonte-pale-violet-2;
    }
  }
  .refonte-conventions-footer {
    @apply flex items-center justify-center;
    gap: 2px;

    :deep(.refonte-number) {
      span {
        @apply text-refonte_blue-200;
        font-size: 13px;
        font-weight: 600;
        line-height: 15px;
      }
    }

    span {
      @apply text-refonte_blue-200;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  :deep(.refonte-conventions-modal-detail) {
    max-width: 718px;

    .refonte-table {
      .refonte-table-row {
        .table-row-content {
          @apply justify-start;
          gap: 6px 12px;
        }
      }
    }
    .refonte-conventions-select {
      max-width: 220px;
      width: 100%;
    }
    .refonte-conventions-participants {
      @apply overflow-y-auto overflow-x-hidden;
      max-height: 204px;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #505d8a;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: darken(#505d8a, 10%);
        cursor: pointer;
      }
    }
  }
  :deep(.refonte-conventions-modal-list) {
    max-width: 774px;

    .refonte-conventions-select {
      @apply flex flex-col;
      gap: 4px;

      &.select-formations {
        max-width: 200px;
        width: 100%;
      }
      &.select-conventions {
        max-width: 234px;
        width: 100%;
      }
    }
  }
</style>
