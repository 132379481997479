<template>
  <template v-if="!loading && detail">
    <formations-details-main-cursus v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      v-bind:title="`Formations (${detail.formations.length})`"
      v-bind:start_open="true">
      <template v-if="detail.formations.length">
        <refonte-table
          v-bind:on_details="goToDetails"
          key="formations-cursus-1"
          table_index="formations-cursus-1"
          main_tags_type="typeShortcut"
          main_tags_type_label="typeLabel"
          v-bind:main_sub_text="
            (row: Formation) =>
              `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu}`
          "
          v-bind:columns="columnsFormations"
          v-bind:data="detail.formations">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.prestations.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconList"
      title="Prestations"
      v-bind:start_open="true">
      <template v-if="detail.prestations">
        <refonte-table
          key="prestations-1"
          table_index="prestations-1"
          v-bind:get_main_label="(rowIndex: number) => 'Libellé'"
          main_data_index="libelle"
          v-bind:columns="columnsPrestationsPrices"
          v-bind:data="detail.prestations">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] overflow-auto pb-[12px]">
        <refonte-item-file
          title="Convention collective"
          v-bind:subtitle="`Commande ${detail.commandeReference}`"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          v-bind:file_signed="false" />
        <refonte-item-file
          title="Convention individuelle"
          subtitle="John Doe"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          v-bind:file_signed="true" />
        <refonte-item-file
          title="PIF"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          signed_label="Signé"
          v-bind:file_signed="true" />
        <refonte-item-file
          title="Certificat"
          no_download_text="A venir"
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          title="Bon de commande"
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          title="Facture"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Participant"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:get_main_label="(rowIndex: number) => 'Nom'"
          main_data_index="nom"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.otherParticipants.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconConventionCollective"
      v-bind:title="`Autres participants (${detail.otherParticipants.length})`">
      <template v-if="detail.otherParticipants">
        <refonte-table
          v-bind:on_details="goToDetailsParticipant"
          key="otherParticipants-1"
          table_index="otherParticipants-1"
          v-bind:get_main_label="(rowIndex: number) => 'Nom'"
          main_data_index="nom"
          v-bind:columns="columnsParticipants"
          v-bind:data="detail.otherParticipants"
          class="table-no-space-between">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { h, onMounted, ref } from 'vue';
  import FormationsDetailsMainCursus from '@/views/_espace/_refonte/gestion/_formations-details/formations-details-main-cursus.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import {
    Detail,
    Participant,
    PrestationPrice
  } from '@/types/gestion/formations-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import IconList from '@/assets/img/refonte/icons/information/icon-list.svg?component';
  import number_utils from '@/mixin/number_utils';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import { Formation } from '@/types/gestion/formations-types';
  import { useRouter } from 'vue-router';
  //import { useFormationStore } from '@/stores/formations';
  //import { storeToRefs } from 'pinia';

  const detail = ref<Detail>();
  //const route = useRoute();
  const loading = ref<boolean>(false);
  //const reference = computed(() => route.query.reference);
  //const commandeId = computed(() => route.query.commandeId);
  //const clientId = computed(() => route.query.clientId);

  //const formationStore = useFormationStore();

  //const { formations } = storeToRefs(formationStore);

  const fetchFormation = async () => {
    //const response = await API.get(
    //  `client/v2/${clientId.value}/commande/${commandeId.value}/formation/${reference.value}/details`
    //);

    //if (response && response.data) {
    //  detail.value = response.data;
    //}

    detail.value = {
      id: '1',
      lieuNom: "Hôtel d'Anjou",
      catalogueUrl: '#',
      catalogueReference: 'ABC',
      cataloguePubliee: true,
      titre: 'Visa Durabilité',
      sessionReference: '12345',
      commandeReference: '123456',
      reference: '120928',
      dateDebut: '09/12/2024',
      dateFin: '09/16/2024',
      dureeMinutes: 360,
      dureeJours: 0.5,
      certifiant: true,
      organisateur: {
        email: 'test@test.test',
        telephone: '0123456789',
        nom: 'CNCC Formation'
      },
      site: {
        id: 1,
        denomination: 'CRCC de Versailles et du Centre',
        numeroVoie: '28 av. Jean-Jacques ROUSSEAU',
        codePostal: '92000',
        ville: 'La Défense, Paris',
        coordinates: ''
      },
      mode: {
        id: 1,
        label: 'Inter'
      },
      sessionDates: [
        {
          date: '09/12/2024',
          id: 1,
          matin: true,
          aprem: true
        },
        {
          date: '09/16/2024',
          id: 2,
          matin: false,
          aprem: true
        }
      ],
      ficheType: {
        id: 8,
        label: 'Cursus'
      },
      type: {
        id: 8,
        label: 'Cursus'
      },
      mentionsAccessibilite: 'Accessibilité',
      participant: {
        id: 1,
        nom: 'John Doe',
        inscription: {
          id: 2,
          label: 'Confirmé'
        },
        positionnement: {
          id: 1,
          label: 'Réussi'
        },
        validation: {
          id: 2,
          label: 'A venir'
        },
        satisfaction: {
          id: 1,
          label: 'Réalisé'
        },
        etablissement: 'Substanciel'
      },
      otherParticipants: [
        {
          id: 1,
          nom: 'John Doe',
          inscription: {
            id: 1,
            label: 'En attente'
          }
        },
        {
          id: 2,
          nom: 'John Doe',
          inscription: {
            id: 2,
            label: 'Confirmé'
          }
        },
        {
          id: 3,
          nom: 'John Doe',
          inscription: {
            id: 3,
            label: 'Annulé'
          }
        },
        {
          id: 4,
          nom: 'John Doe',
          inscription: {
            id: 4,
            label: 'Transférée'
          }
        }
      ],
      prestations: [
        {
          id: 1,
          libelle: 'Coût pédagogique',
          montantHT: 450,
          montantTVA: 90,
          montantTTC: 540,
          montantRegle: 540
        },
        {
          id: 2,
          libelle: 'Forfait organisation',
          montantHT: 50,
          montantTVA: 10,
          montantTTC: 60,
          montantRegle: 60
        }
      ],
      formations: []
    };
  };

  onMounted(async () => {
    loading.value = true;
    //await formationStore.fetchFormations({});
    await fetchFormation();
    loading.value = false;
  });

  const router = useRouter();
  const goToDetails = (row: Formation) => {
    router.push(
      `/refonte/gestion/session-participant?reference=${row.reference}&commandeId=${row.commandeId}&clientId=${row.clientId}`
    );
  };

  const columnsFormations = [
    {
      data_index: 'datesFormations',
      label: 'Dates de formations',
      width: 190
    },
    {
      data_index: 'statutLabel',
      label: 'Statut',
      width: 85
    },
    {
      data_index: 'organisateur',
      label: 'Organisateur',
      width: 150
    },
    {
      data_index: 'testPositionnementStatut',
      label: 'T. Posit.',
      width: 120,
      tooltip: 'Tests de positionnement'
    }
  ];

  const columnsPrestationsPrices = [
    {
      data_index: 'montantHT',
      label: 'Montant HT',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantHT)
        })
    },
    {
      data_index: 'montantTVA',
      label: 'Montant TVA',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTVA)
        })
    },
    {
      data_index: 'montantTTC',
      label: 'Montant TTC',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTTC)
        })
    },
    {
      data_index: 'montantRegle',
      label: 'Montant réglé',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    }
  ];

  const goToDetailsParticipant = (row: Participant) => {};

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const getParticipantPositionnementStatus = (status: string) => {
    switch (status) {
      case 'Réussi':
        return 'Réalisé';
      case 'Echoué':
        return 'Réalisé';
      case 'Non réalisé':
        return 'A réaliser';
      default:
        return '-';
    }
  };

  const columnsParticipant = [
    {
      data_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      data_index: 'positionnement',
      label: 'T. Positionnement',
      width: 140,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.positionnement
            ? getParticipantPositionnementStatus(row.positionnement.label)
            : '-'
        })
    },
    {
      data_index: 'validation',
      label: 'T. valid. acquis',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.validation ? row.validation.label : '-'
        })
    },
    {
      data_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        })
    },
    {
      data_index: 'etablissement',
      label: 'Etablissement',
      width: 120
    }
  ];

  const columnsParticipants = [
    {
      data_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;
    }
  }
</style>
