<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.dateDebut) ||
      filters_utils.methods.hasValue(filters.dateDebutPeriodeFin) ||
      filters_utils.methods.hasValue(filters.dateDebutPeriodeDebut) ||
      filters_utils.methods.hasValue(filters.dateDebutPeriodeId) ||
      filters_utils.methods.hasValue(filters.dateFin) ||
      filters_utils.methods.hasValue(filters.dateFinPeriodeDebut) ||
      filters_utils.methods.hasValue(filters.dateFinPeriodeFin) ||
      filters_utils.methods.hasValue(filters.dateFinPeriodeId) ||
      filters_utils.methods.hasValue(filters.action) ||
      filters_utils.methods.hasValue(filters.modeId) ||
      filters_utils.methods.hasValue(filters.formationId) ||
      filters_utils.methods.hasValue(filters.contactId)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:footer_button_on_click="() => updateDateDebutFilter()">
        Date de début
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="periode in PERIOD_IDS"
            v-bind:on_click="
              () =>
                updateFilter(
                  FILTERS_CONFIGURATION.dateDebutPeriodeId.name,
                  periode.id
                )
            "
            v-bind:key="periode.id">
            {{ periode.label }}
          </refonte-dropdown-item>
          <div class="p-2">
            <refonte-label label="Date" />
            <refonte-datepicker
              v-on:input="(value) => updateDateDebut('date', value)"
              class="p-1"
              placeholder="Date exacte (JJ/MM/YYYY)"
              v-bind:value="dateDebut?.date" />
          </div>
          <refonte-separator />
          <div class="p-2">
            <refonte-label label="Période" />
            <refonte-datepicker
              class="p-1"
              placeholder="Début (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateDebut('debut', value)"
              v-bind:value="dateDebut?.debut" />
            <refonte-datepicker
              class="p-1"
              placeholder="Fin (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateDebut('fin', value)"
              v-bind:value="dateDebut?.fin" />
          </div>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:footer_button_on_click="() => updateDateFinFilter()">
        Date de fin
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="periode in PERIOD_IDS"
            v-bind:on_click="
              () =>
                updateFilter(
                  FILTERS_CONFIGURATION.dateFinPeriodeId.name,
                  periode.id
                )
            "
            v-bind:key="periode.id">
            {{ periode.label }}
          </refonte-dropdown-item>

          <div class="p-2">
            <refonte-label label="Date" />
            <refonte-datepicker
              class="p-1"
              placeholder="Date exacte (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateFin('date', value)"
              v-bind:value="filters?.dateFin" />
          </div>
          <refonte-separator />
          <div class="p-2">
            <refonte-label label="Période" />
            <refonte-datepicker
              class="p-1"
              placeholder="Début (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateFin('debut', value)"
              v-bind:value="filters?.dateFinPeriodeDebut" />
            <refonte-datepicker
              class="p-1"
              placeholder="Fin (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateFin('fin', value)"
              v-bind:value="filters?.dateFinPeriodeFin" />
          </div>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateParticipantValue(value)">
        Participant
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="participantList.length"
            v-for="participant in participantList"
            v-bind:on_click="
              () =>
                updateFilter(
                  FILTERS_CONFIGURATION.contactId.name,
                  participant.id
                )
            "
            v-bind:key="participant.id">
            <div>
              {{ participant.nomPrenom }}
            </div>
            <div>
              {{ participant.email }}
            </div>
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value) => updateFormationValue(value)">
        Inscription
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="formationList.length"
            v-for="formation in formationList"
            v-bind:on_click="
              () =>
                updateFilter(
                  FILTERS_CONFIGURATION.formationId.name,
                  formation.id
                )
            "
            v-bind:key="formation.id">
            {{ formation.titre }}
          </refonte-dropdown-item>

          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Mode
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="mode in MODES"
            v-bind:on_click="
              () => updateFilter(FILTERS_CONFIGURATION.modeId.name, mode.id)
            "
            v-bind:key="mode.id">
            {{ mode.label }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Action à réaliser
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="action in ACTIONS"
            v-bind:on_click="
              () => updateFilter(FILTERS_CONFIGURATION.action.name, action.id)
            "
            v-bind:key="action.id">
            {{ action.label }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref } from 'vue';
  import {
    FILTERS_CONFIGURATION,
    useFormationFilterStore
  } from '@/stores/formations';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import {
    FormationMetadataFormation,
    FormationMetadataParticipant
  } from '@/types/gestion/formations-filters-types';
  import { API } from '@/http-common';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import filters_utils from '@/mixin/utils/filters_utils';

  const participantSearchValue = ref<string>('');

  const updateParticipantValue = (value: string) => {
    participantSearchValue.value = value;
  };

  const formationSearchValue = ref<string>('');
  const updateFormationValue = (value: string) => {
    formationSearchValue.value = value;
  };
  const MODES = computed(() => [
    {
      id: 1,
      label: 'Inter'
    },
    {
      id: 2,
      label: 'Intra'
    }
  ]);

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDateDebut = (name: string, value: undefined | Date | string) => {
    dateDebut.value[name] = value;
  };

  const updateDateFin = (name: string, value: undefined | Date | string) => {
    dateFin.value[name] = value;
  };

  const getLabel = (
    list: { id: string | number; label: string }[],
    id: number | string | undefined,
    name: string
  ) => {
    const item = list.find((mode) => mode.id == id);
    return item ? filters_utils.methods.formatFilter(name, item.label) : '';
  };
  const getProduitTitre = (id: number | undefined) => {
    const item = formationFilterList.value.find((mode) => mode.id == id);
    return item
      ? filters_utils.methods.formatFilter('Formation', item.titre)
      : '';
  };
  const getParticipantNomPrenom = (id: number | undefined) => {
    const item = participantFilterList.value.find((mode) => mode.id == id);
    return item
      ? filters_utils.methods.formatFilter('Participant', item.nomPrenom)
      : '';
  };

  const ACTIONS = computed(() => [
    {
      id: 'CONVENTIONS_A_SIGNER',
      label: 'Conventions à signer'
    },
    {
      id: 'PIF_A_SIGNER',
      label: 'PIF à signer'
    },
    {
      id: 'T_POS_NON_REALISE',
      label: 'T.Pos. non réalisé'
    }
  ]);

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);
  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const formationFilterStore = useFormationFilterStore();
  const { filters } = storeToRefs(formationFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean
  ) => {
    formationFilterStore.setFilter(name, value);
  };
  const dateDebut = ref<DateFilter>({
    date: filters.value.dateDebut,
    debut: filters.value.dateDebutPeriodeDebut,
    fin: filters.value.dateDebutPeriodeFin,
    periodeId: filters.value.dateDebutPeriodeId
  });

  const dateFin = ref<DateFilter>({
    date: filters.value.dateFin,
    debut: filters.value.dateFinPeriodeDebut,
    fin: filters.value.dateFinPeriodeFin,
    periodeId: filters.value.dateFinPeriodeId
  });

  const resetFilters = () => {
    formationFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    dateDebut.value = { ...dateFilterDefaut };
    dateFin.value = { ...dateFilterDefaut };
  };
  const updateDateDebutFilter = () => {
    const dateDebutValue = dateDebut.value;
    updateFilter(FILTERS_CONFIGURATION.dateDebut.name, dateDebutValue.date);
    updateFilter(
      FILTERS_CONFIGURATION.dateDebutPeriodeDebut.name,
      dateDebutValue.debut
    );
    updateFilter(
      FILTERS_CONFIGURATION.dateDebutPeriodeFin.name,
      dateDebutValue.fin
    );
  };

  const updateDateFinFilter = () => {
    const dateFinValue = dateFin.value;
    updateFilter(FILTERS_CONFIGURATION.dateFin.name, dateFinValue.date);
    updateFilter(
      FILTERS_CONFIGURATION.dateFinPeriodeDebut.name,
      dateFinValue.debut
    );
    updateFilter(
      FILTERS_CONFIGURATION.dateFinPeriodeFin.name,
      dateFinValue.fin
    );
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: FILTERS_CONFIGURATION.dateDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de début',
        formatDate(filters.value.dateDebut)
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateDebutPeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de début',
        getPeriodFilterApply(filters.value.dateDebutPeriodeId)
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateDebutPeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de début',
        `Après le
          ${formatDate(filters.value.dateDebutPeriodeDebut)}`
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateDebutPeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de début',
        `Avant le
          ${formatDate(filters.value.dateDebutPeriodeFin)}`
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de fin',
        formatDate(filters.value.dateFin)
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateFinPeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de fin',
        getPeriodFilterApply(filters.value.dateFinPeriodeId)
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateFinPeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de fin',
        `Après le
          ${formatDate(filters.value.dateFinPeriodeDebut)}`
      )
    },
    {
      id: FILTERS_CONFIGURATION.dateFinPeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de fin',
        `Avant le
          ${formatDate(filters.value.dateFinPeriodeFin)}`
      )
    },
    {
      id: FILTERS_CONFIGURATION.formationId.name,
      value: getProduitTitre(filters.value.formationId)
    },
    {
      id: FILTERS_CONFIGURATION.contactId.name,
      value: getParticipantNomPrenom(filters.value.contactId)
    },
    {
      id: FILTERS_CONFIGURATION.modeId.name,
      value: getLabel(MODES.value, filters.value.modeId, 'Mode')
    },
    {
      id: FILTERS_CONFIGURATION.action.name,
      value: getLabel(ACTIONS.value, filters.value.action, 'Action à réaliser')
    }
  ]);

  const participantFilterList = ref<FormationMetadataParticipant[]>([]);

  const participantList = computed(() =>
    participantFilterList.value.filter(
      (p) =>
        participantSearchValue.value.length < 3 ||
        p.nomPrenom
          .toLowerCase()
          .includes(participantSearchValue.value.toLowerCase())
    )
  );

  const formationFilterList = ref<FormationMetadataFormation[]>([]);

  const formationList = computed(() =>
    formationFilterList.value.filter(
      (f) =>
        formationSearchValue.value.length < 3 ||
        f.titre.toLowerCase().includes(formationSearchValue.value.toLowerCase())
    )
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const fetchMetadata = async () => {
    const response = await API.get(
      `client/v2/formations/metadata?clientIds=${activeClientIds.value}`
    );

    if (response && response.data) {
      formationFilterList.value = response.data.formations;
      participantFilterList.value = response.data.participants;
    }
  };

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
