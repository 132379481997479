<template>
  <div class="p-[8px]">
    <template v-if="contrats.length">
      <refonte-table
        key="contrats-1"
        table_index="contrats-1"
        main_data_index="periode"
        v-bind:get_main_label="(rowIndex: number) => 'Période'"
        v-bind:main_width="200"
        v-bind:main_data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:main_data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:columns="columns"
        v-bind:data="contrats" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Contrat } from '@/types/gestion/contrats-types';
  import { useContratStore } from '@/stores/contrats';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';

  const columns = [
    {
      data_index: 'natureLabel',
      label: 'Nature',
      width: 80
    },
    {
      data_index: 'formateurNomPrenom',
      label: 'Formateur',
      width: 150
    },
    {
      data_index: 'typeLabel',
      label: 'Type',
      width: 80
    },
    {
      data_index: 'formation',
      label: 'Formation',
      content_sub_text: (row: Contrat) => {
        return `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu}`;
      },
      width: 200
    },
    {
      data_index: 'organisme',
      label: 'Organisme de formation',
      width: 170
    },
    {
      data_index: 'date',
      label: 'Date session',
      width: 100
    },
    {
      data_index: 'statutLabel',
      label: 'Contrat',
      width: 100,
      data_icon: (row: Contrat) => {
        if (row.statutLabel === 'A signer') {
          return IconUpload;
        }
        if (row.statutLabel === 'Validé') {
          return IconDownload;
        } else {
          return undefined;
        }
      },
      on_click: (rowIndex: number) => {},
      on_click_disabled: (row: Contrat) => row.statutLabel === 'Signé'
    }
  ];

  const contratStore = useContratStore();

  const { contrats } = storeToRefs(contratStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
