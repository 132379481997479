<template>
  <div
    class="refonte-item-file"
    v-bind:class="props.class">
    <div class="item-file-content">
      <div class="item-file-content-title">
        <div class="item-file-content-title-text">
          <div class="item-file-content-title-text-top">
            {{ props.title }}
          </div>
          <div
            v-if="props.subtitle"
            class="item-file-content-title-text-bottom">
            {{ props.subtitle }}
          </div>
        </div>
      </div>
      <refonte-button
        v-if="props.on_click_download"
        class="item-file-content-download"
        variant="secondary"
        v-bind:icon_left="IconDownload"
        v-on:click="props.on_click_download" />
      <div
        v-else
        class="item-file-content-no-download">
        {{ props.no_download_text }}
      </div>
      <IconSeparatorVertical
        v-if="!props.no_signature_needed"
        class="item-file-content-download-separator" />
      <div class="item-file-content-status">
        <div
          v-if="!props.no_signature_needed"
          class="item-file-content-status-text">
          <div class="item-file-content-status-text-top">
            <div class="item-file-content-status-text-top-icon">
              <IconValidate20 v-if="props.file_signed" />
              <IconTime v-else />
            </div>
            <span>{{
              props.file_signed ? props.signed_label : props.to_sign_label
            }}</span>
          </div>
        </div>
      </div>
      <IconSeparatorVertical
        v-if="!props.no_signature_needed && !props.file_signed"
        class="shrink-0" />
      <refonte-button
        v-if="!props.no_signature_needed && !props.file_signed"
        class="item-file-content-upload"
        variant="secondary">
        Sélectionner
      </refonte-button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';
  import IconValidate20 from '@/assets/img/refonte/icons/information/icon-validate-20.svg?component';

  const props = withDefaults(
    defineProps<{
      title: string;
      subtitle?: string;
      on_click_download?: Function;
      no_download_text?: string;
      signed_label?: string;
      to_sign_label?: string;
      file_signed?: boolean;
      no_signature_needed?: boolean;
      class?: string;
    }>(),
    {
      no_download_text: '-',
      signed_label: 'Signée',
      to_sign_label: 'A signer',
      file_signed: false,
      no_signature_needed: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-item-file {
    @apply flex items-center bg-refonte-pale-violet-2;
    gap: 8px;
    border-radius: 8px;
    padding: 6px 6px 6px 12px;
    max-width: 640px;
    flex: 1 0 640px;
    min-height: 48px;

    .item-file-content {
      @apply flex flex-grow items-center;
      gap: 4px;

      .item-file-content-title {
        @apply flex items-start;
        gap: 2px;
        width: 100%;
        max-width: 240px;

        .item-file-content-title-text {
          @apply flex flex-grow flex-col items-start;
          gap: 2px;

          .item-file-content-title-text-top {
            @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }
          .item-file-content-title-text-bottom {
            @apply self-stretch overflow-hidden text-ellipsis text-refonte-black-40;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
      .item-file-content-download {
        padding: 3px;
        border-radius: 4px;
      }
      .item-file-content-no-download {
        @apply flex items-center justify-center text-refonte_blue-600;
        padding: 3px;
        min-width: 24px;
        font-size: 13px;
        line-height: 16px;
      }
      .item-file-content-download-separator {
        @apply shrink-0;
      }

      .item-file-content-status {
        @apply flex flex-grow items-center;
        gap: 4px;

        .item-file-content-status-text {
          @apply flex flex-grow flex-col items-start justify-center;

          .item-file-content-status-text-top {
            @apply flex items-center;
            gap: 2px;

            .item-file-content-status-text-top-icon {
              @apply flex shrink-0 items-center justify-center;
              width: 20px;
              height: 20px;

              :deep(svg) {
                @apply text-refonte_blue-600;
                width: 18px;
                height: 18px;
              }
            }
            span {
              @apply text-refonte_blue-600;
              font-size: 13px;
              line-height: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
</style>
