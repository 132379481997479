<template>
  <template v-if="!loading">
    <refonte-card>
      <interlocuteurs-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <formations-number
          v-bind:number="interlocuteurs.length"
          v-bind:label="
            interlocuteurs.length > 1 ? 'Interlocuteurs' : 'Interlocuteur'
          " />
        <IconSeparatorVertical />
        <refonte-button
          v-bind:disabled="activeEntrepriseId === ALL_ENTREPRISE_FILTER"
          v-bind:on_click="() => openInterlocuteurModalOpen()"
          v-bind:title="
            activeEntrepriseId === ALL_ENTREPRISE_FILTER
              ? 'Veuillez séléctionner un établissement pour pouvoir cliquer sur ce bouton'
              : undefined
          ">
          Ajouter interlocuteur
        </refonte-button>
      </div>
      <refonte-separator class="px-[12px]" />
      <interlocuteurs-content
        v-if="!dataLoading"
        v-bind:update_interlocuteur="updateInterlocuteur" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
  <refonte-modal-interlocuteur
    v-bind:show="isInterlocuteurModalOpen"
    v-bind:on_close="() => closeInterlocuteurModalOpen()"
    v-bind:on_submit="() => closeInterlocuteurModalOpen()"
    v-bind:active_interlocutor="activeInterlocuteur"
    v-bind:available_participants="[
      {
        id: '2',
        clientDenomination: 'CNCC FORMATION',
        contactNom: 'Doe',
        contactPrenom: 'John',
        contactNomPrenom: 'Doe John',
        compteJiniusLabel: 'Oui',
        email: 'test@test.test',
        interlocuteurLabel: 'Non',
        profilLabel: 'Stagiaire EC',
        supprimable: false
      }
    ]" />
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InterlocuteursContent from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-content.vue';
  import {
    useInterlocuteurFilterStore,
    useInterlocuteurStore
  } from '@/stores/interlocuteurs';
  import { storeToRefs } from 'pinia';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import InterlocuteursFilters from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import RefonteModalInterlocuteur from '@/components/refonte/modals/refonte-modal-interlocuteur.vue';
  import { Interlocuteur } from '@/types/gestion/interlocuteurs-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeEntrepriseId, activeClientIds } = storeToRefs(entrepriseStore);
  const interlocuteurFilterStore = useInterlocuteurFilterStore();

  const { filters } = storeToRefs(interlocuteurFilterStore);

  const dataLoading = ref<boolean>(false);
  const interlocuteurStore = useInterlocuteurStore();
  const { interlocuteurs } = storeToRefs(interlocuteurStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await interlocuteurStore.fetchInterlocuteurs(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    interlocuteurFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });

  const activeInterlocuteur = ref<Interlocuteur>();
  const isInterlocuteurModalOpen = ref<boolean>(false);

  function closeInterlocuteurModalOpen() {
    isInterlocuteurModalOpen.value = false;
    activeInterlocuteur.value = undefined;
  }
  function openInterlocuteurModalOpen() {
    isInterlocuteurModalOpen.value = true;
    activeInterlocuteur.value = undefined;
  }
  function updateInterlocuteur(value: Interlocuteur) {
    isInterlocuteurModalOpen.value = true;
    activeInterlocuteur.value = value;
  }
</script>

<style lang="scss" scoped></style>
