import { SatisfactionResponse } from '@/types/gestion/satisfaction-types';

const clients = {
    REELIANT: {
        id: 28389,
        denomination: 'Reeliant',
        siret: '832 014 872 00028'
    },
    CNCC_FORMATION: {
        id: 2,
        denomination: 'CNCC FORMATION'
    },
    STEAMULO: {
        id: 28390,
        denomination: 'Steamulo',
        siret: '795 367 655 00052'
    },
    ASA: {
        id: 4,
        denomination: 'A.S.A. AUDIT SERVICES ADMINISTRATIFS',
        siret: '000 000 000 00000'
    },
    ACR: {
        id: 5,
        denomination:
            "A.C.R. AUDIT CONSEIL REVISION SOCIETE D'EXPERTISE COMPTABLE ET DE COMMISSARIAT AUX COMPTES",
        siret: '000 000 000 00000'
    }
};

export const satisfactionData: SatisfactionResponse[] = [
    {
        id: '1',
        repondu: false,
        client: clients.REELIANT,
        dateQuestionnaire: '10/09/2024',
        organisme: {
            id: 1,
            nom: 'CNCC Formation'
        },
        formation: 'Alignement des activités à la Taxonomie'
    },
    {
        id: '2',
        repondu: true,
        client: clients.CNCC_FORMATION,
        dateQuestionnaire: '08/09/2024',
        organisme: {
            id: 1,
            nom: 'CNCC Formation'
        },
        formation: 'Alignement des activités à la Taxonomie',
        dateReponse: '03/09/2024',
        reponduPar: 'Doe John'
    },
    {
        id: '3',
        repondu: false,
        client: clients.STEAMULO,
        dateQuestionnaire: '06/09/2024',
        organisme: {
            id: 1,
            nom: 'CNCC Formation'
        },
        formation: 'Alignement des activités à la Taxonomie'
    },
    {
        id: '4',
        repondu: true,
        client: clients.ASA,
        dateQuestionnaire: '04/09/2024',
        organisme: {
            id: 1,
            nom: 'CNCC Formation'
        },
        formation: 'Alignement des activités à la Taxonomie',
        dateReponse: '03/09/2024',
        reponduPar: 'Doe John'
    },
    {
        id: '5',
        repondu: false,
        client: clients.ACR,
        dateQuestionnaire: '02/09/2024',
        organisme: {
            id: 1,
            nom: 'CNCC Formation'
        },
        formation: 'Alignement des activités à la Taxonomie',
        dateReponse: '03/09/2024',
        reponduPar: 'Doe John'
    }
];
