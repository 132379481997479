<template>
  <template v-if="!loading">
    <refonte-card>
      <factures-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-checkbox
          label="Tout sélectionner"
          v-on:input="(value) => selectAllRows(value)"
          class="!self-center" />
        <IconSeparatorVertical />
        <div class="flex flex-wrap items-start gap-[8px]">
          <refonte-button
            v-bind:disabled="!hasFacturesSelected"
            v-bind:icon_left="IconDownload"
            v-on:click="() => {}">
            <div class="inline-flex items-center gap-[5px]">
              Télécharger
              <IconBills />
            </div>
          </refonte-button>
        </div>
        <IconSeparatorVertical />
        <formations-number
          v-bind:number="factures.length"
          v-bind:label="factures.length > 1 ? 'Factures' : 'Facture'" />
        <IconSeparatorVertical />
        <formations-number
          v-bind:number="nombreFacturesARegler"
          v-bind:label="`${
            nombreFacturesARegler > 1 ? 'Factures' : 'Facture'
          } à régler`" />
      </div>
      <refonte-separator class="px-[12px]" />
      <factures-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import FacturesContent from '@/views/_espace/_refonte/gestion/_factures/factures-content.vue';
  import {
    FACTURES_FILTERS_CONFIGURATION,
    useFactureFilterStore,
    useFactureStore
  } from '@/stores/factures';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { Facture } from '@/types/gestion/factures-types';
  import FacturesFilters from '@/views/_espace/_refonte/gestion/_factures/factures-filters.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconBills from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const factureFilterStore = useFactureFilterStore();

  const { filters } = storeToRefs(factureFilterStore);

  const dataLoading = ref<boolean>(false);
  const factureStore = useFactureStore();
  const { factures, hasFacturesSelected } = storeToRefs(factureStore);

  const nombreFacturesARegler = computed(
    () =>
      factures.value.filter(
        (f: Facture) => f.statutLabel && f.statutLabel === 'Non acquittée'
      ).length
  );

  const selectAllRows = (value: boolean) => {
    factureStore.selectAllFactureItems(value);
  };
  const fetchData = async () => {
    dataLoading.value = true;
    await factureStore.fetchFactures(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    factureFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();

  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, [
      FACTURES_FILTERS_CONFIGURATION.date.name,
      FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });
</script>

<style lang="scss" scoped></style>
