import { API } from '@/http-common.js';
import { etablissementsData } from '@/stores/data/etablissements-data';
import { Filters } from '@/types/gestion/etablissements-filters-types';
import {
    Etablissement,
    EtablissementResponse
} from '@/types/gestion/etablissements-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: []
};

const filterInitialState: Filters = {
    clientIds: []
};

export const ETABLISSEMENTS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    }
};

export const useEtablissementFilterStore = defineStore(
    'etablissements-filter',
    () => {
        const filters = ref<Filters>(filterInitialState);

        const setFilter = (name: string, value: any) => {
            filters.value = { ...filters.value, [name]: value };
        };
        const resetFilters = () => {
            filters.value = noFilterState;
        };

        const parsePayload = (payload: LocationQuery): Filters => {
            const newPayload = { ...payload };

            Object.keys(newPayload).forEach((key) => {
                const filter = ETABLISSEMENTS_FILTERS_CONFIGURATION[key] as any;

                if (filter && filter.isArray) {
                    newPayload[key] = newPayload[key].split(',');
                }
            });

            return newPayload;
        };

        const initFiltersFromQuery = (payload: LocationQuery) => {
            const parsedPayload = parsePayload(payload) as Filters;
            if (payload && Object.keys(payload).length > 0) {
                filters.value = {
                    ...noFilterState,
                    ...parsedPayload
                };
            } else {
                filters.value = {
                    ...filterInitialState,
                    ...parsedPayload
                };
            }
        };

        return {
            resetFilters,
            setFilter,
            filters,
            initFiltersFromQuery
        };
    }
);

export const useEtablissementStore = defineStore('etablissements', () => {
    const etablissements = ref<Etablissement[]>([]);

    const fetchEtablissements = async (query: Filters) => {
        const response = await API.get(`client/v2/etablissements`, {
            params: query
        });
        if (response?.data) {
            etablissements.value = response?.data.map(
                (e: EtablissementResponse) => mapEtablissement(e)
            );
        }
    };

    const mapEtablissement = (e: EtablissementResponse): Etablissement => {
        return {
            ...e,
            inscriptionLabel: e.inscription.label,
            facturationAutresTiersLabel: e.facturationAutresTiers.label
        };
    };

    return {
        etablissements,
        fetchEtablissements
    };
});
