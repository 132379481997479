<template>
  <div
    class="refonte-input"
    v-bind:class="`${props.error ? 'input-error' : ''} ${props.class}`">
    <input
      v-model="inputValue"
      v-on:input="updateValue"
      v-bind:type="props.type"
      v-bind:placeholder="props.placeholder"
      v-bind:disabled="props.disabled"
      v-bind:maxlength="props.maxlength"
      v-on:keypress="format === 'numeric' && onlyNumber($event)"
      v-bind:class="props.input_class" />
    <label v-bind:class="{ 'input-with-value': inputValue !== '' }">
      {{ props.placeholder }}<span v-if="props.required"> *</span>
    </label>
    <div
      v-if="props.error && props.error_message"
      class="input-error-message">
      {{ props.error_message }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      type?: string;
      value?: string;
      placeholder: string;
      required?: boolean;
      disabled?: boolean;
      error?: boolean;
      error_message?: string;
      maxlength?: number;
      format?: string;
      class?: string;
      input_class?: string;
    }>(),
    {
      type: 'text',
      value: '',
      required: false,
      disabled: false,
      error: false,
      class: '',
      input_class: ''
    }
  );

  const inputValue = ref<string>(props.value);

  const emit = defineEmits(['input']);

  function updateValue() {
    emit('input', inputValue.value);
  }
  function onlyNumber($event: any) {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;

    if ([32, 46].includes(keyCode)) {
      // 46 is dot, 322 is space
      return;
    }
    if (keyCode < 48 || keyCode > 57) {
      $event.preventDefault();
    }
  }
</script>

<style lang="scss" scoped>
  .refonte-input {
    @apply relative;
    padding-bottom: 16px;

    input {
      @apply flex flex-grow items-center self-stretch border border-refonte_blue-100 bg-refonte-white text-refonte-black;
      padding: 17px 7px 4px;
      border-radius: 4px;
      gap: 6px;
      height: 44px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;

      &::placeholder {
        @apply text-refonte-white;
      }
      &:focus-visible {
        outline: none;
      }
      &:hover {
        @apply border-refonte_blue-600;
      }
      &:focus {
        @apply border-refonte_blue-800;
      }
      &[disabled] {
        @apply cursor-not-allowed border-refonte_blue-150 text-refonte_blue-200;
      }
    }
    label {
      @apply absolute text-refonte_blue-300;
      left: 8px;
      top: 14px;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
    label.input-with-value,
    input:focus + label {
      transform: translateY(-9px);
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
    }

    &.input-error {
      input {
        @apply border-jinius-red text-jinius-red #{!important};
      }
      label {
        @apply text-jinius-red #{!important};
      }
    }
    .input-error-message {
      @apply absolute text-jinius-red;
      bottom: 2px;
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
    }
  }
</style>
