<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    v-bind:title="
      active_interlocutor
        ? 'Modifier un interlocuteur'
        : 'Ajouter un interlocuteur'
    "
    class="!max-w-[640px]">
    <template v-slot:modalBody>
      <div v-if="firstStep">
        <refonte-select
          placeholder="Depuis l'annuaire"
          input_class="w-full"
          v-bind:options="available_participants"
          value_label="contactNomPrenom"
          v-bind:value="
            available_participants.find(
              (participant) => contact === participant.id
            )
          "
          v-on:input="setAvailableParticipant($event)" />
        <div
          class="my-4 flex w-full justify-center text-center text-refonte_blue-300">
          Ou
        </div>
        <refonte-button
          class="w-full justify-center text-center"
          v-bind:on_click="() => setFirstStep(false)">
          Créer un nouvel interlocuteur
        </refonte-button>
      </div>
      <form
        v-else
        v-on:submit.prevent="submit">
        <refonte-input
          v-bind:disabled="!!active_interlocutor || !!contact"
          placeholder="Nom"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="interlocuteurForm.nom"
          v-on:input="setInterlocuteurForm($event, 'nom')"
          v-bind:required="true"
          v-bind:error="v$.interlocuteurForm.nom.$error" />
        <refonte-input
          v-bind:disabled="!!active_interlocutor || !!contact"
          placeholder="Prénom"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="interlocuteurForm.prenom"
          v-on:input="setInterlocuteurForm($event, 'prenom')"
          v-bind:required="true"
          v-bind:error="v$.interlocuteurForm.prenom.$error" />
        <refonte-input
          v-bind:disabled="!!active_interlocutor || !!contact"
          placeholder="Email"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="interlocuteurForm.email"
          v-on:input="setInterlocuteurForm($event, 'email')"
          v-bind:required="true"
          v-bind:error="v$.interlocuteurForm.email.$error"
          error_message="Format invalide" />
        <refonte-select
          placeholder="Fonction"
          input_class="w-full"
          v-bind:options="roles"
          value_label="label"
          v-bind:value="
            roles.find((role) => interlocuteurForm.fonctionId === role.id)
          "
          v-on:input="setInterlocuteurForm($event.id, 'fonctionId')"
          v-bind:required="true"
          v-bind:error="v$.interlocuteurForm.fonctionId.$error" />
        <div
          class="flex w-full items-center justify-end gap-[8px] pt-[16px] text-refonte-black"
          v-bind:class="{
            'radio-error': v$.interlocuteurForm.droitAdministrateur.$error
          }">
          <div class="flex flex-grow items-center gap-[4px]">
            <label>Administrateur de la société ?* </label>
            <div
              v-tooltip="
                !interlocuteurForm.droitAdministrateur
                  ? {
                      content: `
                        <div>
                            L'administrateur possède l'intégralité des droits sur la société.
                        </div>
                        <div>Plusieurs administrateurs peuvent être nommés sur la même société.</div>`
                    }
                  : null
              ">
              <font-awesome-icon
                v-if="!interlocuteurForm.droitAdministrateur"
                icon="info-circle" />
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <refonte-radio
              name="droitAdministrateur"
              v-bind:value="true"
              label="Oui"
              v-bind:checked="interlocuteurForm.droitAdministrateur"
              v-on:input="setInterlocuteurForm($event, 'droitAdministrateur')"
              class="!min-w-0" />
            <refonte-radio
              name="droitAdministrateur"
              v-bind:value="false"
              label="Non"
              v-bind:checked="interlocuteurForm.droitAdministrateur"
              v-on:input="setInterlocuteurForm($event, 'droitAdministrateur')"
              class="!min-w-0" />
          </div>
        </div>
        <template v-if="interlocuteurForm.droitAdministrateur">
          <div class="pt-[16px] text-justify text-sm italic">
            L'administrateur possède l'intégralité des droits sur la société.
          </div>
          <div class="pt-[16px] text-justify text-sm italic">
            En plus de pouvoir procéder à des inscriptions au nom de la société
            et de voir l'intégralité des commandes, inscriptions et documents
            liés, il peut également gérer les droits d'accès et les informations
            administratives de la société (adresse, entités de facturation...).
            Plusieurs administrateurs peuvent être nommés sur la même société.
          </div>
        </template>
        <template v-else>
          <div
            class="flex w-full items-center justify-end gap-[8px] pt-[16px] text-refonte-black"
            v-bind:class="{
              'radio-error': v$.interlocuteurForm.droitInscription.$error
            }">
            <div class="flex flex-grow items-center gap-[4px]">
              <label>
                Droit de procéder à des inscriptions pour le compte de la
                société ?*
              </label>
            </div>
            <div class="flex items-center gap-[8px]">
              <refonte-radio
                name="droitInscription"
                v-bind:value="true"
                label="Oui"
                v-bind:checked="interlocuteurForm.droitInscription"
                v-on:input="setInterlocuteurForm($event, 'droitInscription')"
                class="!min-w-0" />
              <refonte-radio
                name="droitInscription"
                v-bind:value="false"
                label="Non"
                v-bind:checked="interlocuteurForm.droitInscription"
                v-on:input="setInterlocuteurForm($event, 'droitInscription')"
                class="!min-w-0" />
            </div>
          </div>
          <div
            class="flex w-full items-center justify-end gap-[8px] pt-[16px] text-refonte-black"
            v-bind:class="{
              'radio-error': v$.interlocuteurForm.droitAccesDocuments.$error
            }">
            <div class="flex flex-grow items-center gap-[4px]">
              <label>
                Droit d'accéder à l'ensemble des documents (bons de commandes,
                factures, conventions, certificats de réalisation...) de la
                société ?*
              </label>
            </div>
            <div class="flex items-center gap-[8px]">
              <refonte-radio
                name="droitAccesDocuments"
                v-bind:value="true"
                label="Oui"
                v-bind:checked="interlocuteurForm.droitAccesDocuments"
                v-on:input="setInterlocuteurForm($event, 'droitAccesDocuments')"
                class="!min-w-0" />
              <refonte-radio
                name="droitAccesDocuments"
                v-bind:value="false"
                label="Non"
                v-bind:checked="interlocuteurForm.droitAccesDocuments"
                v-on:input="setInterlocuteurForm($event, 'droitAccesDocuments')"
                class="!min-w-0" />
            </div>
          </div>
        </template>
      </form>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler
      </refonte-button>
      <refonte-button
        v-if="!firstStep"
        v-bind:on_click="submit">
        {{ active_interlocutor ? 'Modifier' : 'Ajouter' }}
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import { computed, ref, toRefs, watch } from 'vue';
  import RefonteSelect from '@/components/refonte/inputs/refonte-select.vue';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import { email, required, requiredIf } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import { roles } from '@/assets/data/data_form';
  import { Interlocuteur } from '@/types/gestion/interlocuteurs-types';
  import { Annuaire } from '@/types/gestion/annuaire-types';
  import RefonteRadio from '@/components/refonte/inputs/refonte-radio.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      class?: string;
      active_interlocutor: Interlocuteur;
      available_participants: Annuaire[];
    }>(),
    {
      class: ''
    }
  );

  const contact = ref<string>();
  const firstStep = ref<boolean>(
    props.available_participants && !props.active_interlocutor
  );

  function setFirstStep(value: boolean) {
    firstStep.value = value;
  }

  const interlocuteurForm = ref<{
    nom?: string;
    prenom?: string;
    email?: string;
    fonctionId?: number;
    droitAdministrateur?: boolean;
    droitInscription?: boolean;
    droitAccesDocuments?: boolean;
  }>({});

  const rules = computed(() => ({
    interlocuteurForm: {
      nom: {
        required
      },
      prenom: {
        required
      },
      email: {
        email,
        required
      },
      fonctionId: {
        required
      },
      droitAdministrateur: { required },
      droitInscription: {
        required: requiredIf(function () {
          return !interlocuteurForm.value.droitAdministrateur;
        })
      },
      droitAccesDocuments: {
        required: requiredIf(function () {
          return !interlocuteurForm.value.droitAdministrateur;
        })
      }
    }
  }));

  const { active_interlocutor } = toRefs(props);

  watch(active_interlocutor, (value) => {
    if (value) {
      contact.value = undefined;
      firstStep.value = false;
      interlocuteurForm.value = {
        nom: active_interlocutor.value.interlocuteurNom,
        prenom: active_interlocutor.value.interlocuteurPrenom,
        email: active_interlocutor.value.email,
        fonctionId: active_interlocutor.value.fonctionId,
        droitAdministrateur: active_interlocutor.value.administrateur,
        droitInscription: active_interlocutor.value.droitInscription,
        droitAccesDocuments: active_interlocutor.value.accesDocuments
      };
    } else {
      contact.value = undefined;
      firstStep.value = !!props.available_participants;
      interlocuteurForm.value = {};
    }
  });

  const v$ = useVuelidate(rules, { interlocuteurForm });

  function close() {
    props.on_close();
    contact.value = undefined;
    firstStep.value =
      props.available_participants && !props.active_interlocutor;
    interlocuteurForm.value = {};
    v$.value.$reset();
  }
  function setInterlocuteurForm(
    value: string | number | boolean,
    name:
      | 'nom'
      | 'prenom'
      | 'email'
      | 'fonctionId'
      | 'droitAdministrateur'
      | 'droitInscription'
      | 'droitAccesDocuments'
  ) {
    interlocuteurForm.value[name] = value;
  }
  function setAvailableParticipant(value: Annuaire) {
    contact.value = value.id;
    setInterlocuteurForm(value.contactNom, 'nom');
    setInterlocuteurForm(value.contactPrenom, 'prenom');
    setInterlocuteurForm(value.email, 'email');
    setFirstStep(false);
  }
  function submit(e: Event) {
    e.preventDefault();
    v$.value.$touch();
    if (!v$.value.$invalid) {
      props.on_submit && props.on_submit(interlocuteurForm.value);
      close();
    }
  }
</script>

<style lang="scss" scoped>
  .radio-error {
    @apply text-jinius-red;

    :deep(.refonte-radio) {
      @apply text-jinius-red;

      .radio-checkmark {
        @apply border-jinius-red;
      }
      .radio-label {
        @apply text-jinius-red;
      }
    }
  }
</style>
