<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    v-bind:title="title"
    class="!max-w-[704px]">
    <template v-slot:modalBody>
      <div class="flex flex-wrap gap-[8px] overflow-auto pb-[12px]">
        <refonte-item-file
          v-bind:title="props.file_title"
          v-bind:subtitle="props.file_subtitle"
          v-bind:on_click_download="props.file_on_click_download"
          v-bind:file_signed_label="props.file_signed_label"
          v-bind:file_to_sign_label="props.file_to_sign_label"
          v-bind:file_signed="props.file_signed" />
      </div>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      title?: string;
      file_title: string;
      file_subtitle?: string;
      file_on_click_download?: Function;
      file_signed_label?: string;
      file_to_sign_label?: string;
      file_signed?: boolean;
      class?: string;
    }>(),
    {
      title: 'Document',
      file_signed_label: 'Signée',
      file_to_sign_label: 'A signer',
      file_signed: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
