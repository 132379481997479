<template>
  <header
    class="refonte-header"
    v-bind:class="props.class">
    <div class="header-content-container">
      <div
        class="header-content-menu"
        v-on:click="props.toggle_is_mobile_side_menu_open()">
        <img
          class="header-content-menu-img"
          src="@/assets/img/refonte/logos/jinius-menu.svg"
          alt="Menu" />
      </div>
      <div class="header-content-espace" />
      <div class="header-content">
        <div class="header-content-left">
          <div class="header-content-title">
            <div class="header-content-title-icon">
              <component
                v-if="props.icon"
                v-bind:is="props.icon" />
            </div>
            <div
              class="header-content-title-text"
              v-bind:class="{
                'header-content-title-text-with-subtitle':
                  props.sub_title && props.sub_title !== ''
              }">
              {{ props.title }}
              <template v-if="props.sub_title && props.sub_title !== ''">
                <IconChevronRight />
                <span class="header-content-subtitle">{{
                  props.sub_title
                }}</span>
              </template>
            </div>
          </div>
          <div
            v-if="!props.hide_scope"
            class="header-scope-desktop">
            <header-scope v-if="!props.sub_title || props.sub_title === ''" />
          </div>
        </div>
        <div
          class="header-alert"
          title="Ce nouvel espace est actuellement en cours de développement. Les fonctionnalités (boutons, liens...) ne sont pas encore implémentées et les données affichées sont factices">
          <span>
            <b>Espace en cours de développement</b><br />
            Les fonctionnalités (boutons, liens...) ne sont pas encore
            implémentées et les données affichées sont factices.
          </span>
        </div>
        <div
          class="header-alert-mobile"
          v-on:click="() => setIsMobileAlertOpen(true)">
          <IconWarning20 />
        </div>
        <refonte-modal
          v-bind:show="isMobileAlertOpen"
          v-on:close="() => setIsMobileAlertOpen(false)"
          title="Espace en cours de développement">
          <template v-slot:modalBody>
            <span>
              Ce nouvel espace est actuellement en cours de développement. Les
              fonctionnalités (boutons, liens...) ne sont pas encore
              implémentées et les données affichées sont factices.
            </span>
          </template>
        </refonte-modal>
        <div class="header-content-right">
          <refonte-dropdown
            class="header-content-user-dropdown"
            dropdown_align_right>
            <div class="header-content-user">
              <div class="header-content-user-infos">
                <div class="header-content-user-infos-name">
                  {{ firstName }} {{ lastName }}
                </div>
                <div class="header-content-user-infos-account">Mon compte</div>
              </div>
              <div class="header-content-user-icon">
                {{ firstName.charAt(0) }}{{ lastName.charAt(0) }}
              </div>
            </div>
            <template v-slot:dropdownContent>
              <refonte-dropdown-item v-bind:icon="IconMyAccount">
                Mon compte
              </refonte-dropdown-item>
              <refonte-dropdown-item v-bind:icon="IconList">
                Informations personnelles
              </refonte-dropdown-item>
              <refonte-dropdown-item v-bind:icon="IconSettings">
                Préférences
              </refonte-dropdown-item>
            </template>
          </refonte-dropdown>
        </div>
      </div>
    </div>
    <div class="header-scope-mobile">
      <header-scope />
    </div>
  </header>
</template>

<script setup lang="ts">
  import IconChevronRight from '@/assets/img/refonte/icons/display/icon-chevron-right.svg?component';
  import IconList from '@/assets/img/refonte/icons/information/icon-list.svg?component';
  import IconSettings from '@/assets/img/refonte/icons/action/icon-settings.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import headerScope from '@/components/refonte/header/refonte-header-scope.vue';
  import { computed, ref } from 'vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import { useStore } from 'vuex';
  const store = useStore();
  import IconWarning20 from '@/assets/img/refonte/icons/information/icon-warning-sign-20.svg?component';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      toggle_is_mobile_side_menu_open: Function;
      title: string;
      sub_title?: string;
      hide_scope?: boolean;
      class?: string;
      icon?: Element;
    }>(),
    {
      hide_scope: false,
      class: ''
    }
  );
  const firstName = computed(() => store.getters['auth/firstName']);
  const lastName = computed(() => store.getters['auth/lastName']);

  const isMobileAlertOpen = ref<boolean>(false);

  function setIsMobileAlertOpen(value: boolean) {
    isMobileAlertOpen.value = value;
  }
</script>

<style lang="scss" scoped>
  header.refonte-header {
    @apply relative flex flex-col items-start justify-center self-stretch bg-refonte-white text-refonte-black;
    padding: 8px 24px 8px 40px;
    gap: 16px;
    box-shadow: 0 3px 9px 0 rgba(169, 178, 209, 0.16);
    height: 80px;

    .header-content-container {
      @apply flex items-center self-stretch;

      .header-content-menu {
        @apply hidden;
      }
      .header-content-espace {
        @apply hidden;
      }
      .header-content {
        @apply flex flex-grow items-center justify-between;

        .header-content-left {
          @apply flex items-center self-stretch;
          gap: 16px;

          .header-content-title {
            @apply flex items-center;
            gap: 8px;

            .header-content-title-icon {
              @apply flex items-center justify-center;
              gap: 10px;
              width: 34px;
              height: 34px;

              :deep(svg) {
                @apply text-refonte-black;
                width: 34px;
                height: 34px;
              }
            }
            .header-content-title-text {
              &:not(.header-content-title-text-with-subtitle) {
                @apply overflow-hidden text-ellipsis;
                font-size: 20px;
                font-weight: 600;
                line-height: 140%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
              &.header-content-title-text-with-subtitle {
                @apply flex items-center whitespace-nowrap text-refonte-black-60;
                font-size: 16px;
                font-weight: 400;
                line-height: 125%;

                :deep(svg) {
                  min-width: 16px;
                  width: 16px;
                  height: 16px;
                }

                .header-content-subtitle {
                  @apply overflow-hidden text-ellipsis whitespace-normal text-refonte-black;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                }
              }
            }
          }
        }
        .header-content-right {
          @apply flex items-center justify-end;
          gap: 10px;

          .header-content-user-dropdown.dropdown-open {
            .header-content-user {
              @apply bg-refonte-pale-violet-3;
            }
          }

          .header-content-user {
            @apply flex cursor-pointer items-center;
            gap: 8px;
            padding: 8px 10px;
            width: 200px;
            min-width: 200px;
            border-radius: 8px;

            &:hover {
              @apply bg-refonte-pale-violet-3;
            }

            .header-content-user-infos {
              @apply flex flex-grow flex-col items-end justify-center;
              gap: 2px;

              .header-content-user-infos-name {
                @apply self-stretch overflow-hidden text-ellipsis whitespace-nowrap text-right;
                max-width: 132px;
                font-size: 13px;
                font-weight: 600;
                line-height: 125%;
              }
              .header-content-user-infos-account {
                color: #a3aed6;
                font-size: 12px;
                font-weight: 400;
                line-height: 120%;
              }
            }
            .header-content-user-icon {
              @apply flex shrink-0 items-center justify-center text-refonte-white;
              gap: 10px;
              padding: 8px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: linear-gradient(
                228deg,
                #32537a 11.71%,
                #32537a 59.04%,
                #64b389 59.07%,
                #64b389 72.41%,
                #ea434c 72.75%
              );
              font-size: 14px;
              font-weight: 700;
              line-height: 125%;
            }
          }
        }
      }
    }
    .header-scope-mobile {
      @apply hidden;
    }
    @media (max-width: 1024.98px) {
      .header-content-container {
        .header-content {
          .header-content-right {
            .header-content-user {
              width: 60px;
              min-width: 60px;

              .header-content-user-infos {
                @apply hidden;
              }
            }
          }
        }
      }
    }
    @media (max-width: 767.98px) {
      @apply items-center bg-transparent;
      padding: 0;
      gap: 8px;
      box-shadow: none;
      height: auto;

      .header-content-container {
        @apply bg-refonte-white;

        .header-content-menu {
          @apply flex cursor-pointer items-center justify-center bg-refonte-menu;
          min-width: 56px;
          width: 56px;
          height: 56px;

          .header-content-menu-img {
            width: 36px;
            height: 40px;
          }
        }
        .header-content-espace {
          @apply block bg-jinius-blue;
          min-width: 8px;
          width: 8px;
          height: 56px;
          padding: 3px 4px 4px 4px;
        }
        .header-content {
          padding: 0 8px 0 12px;

          .header-content-left {
            .header-content-title {
              .header-content-title-icon {
                width: 24px;
                height: 24px;

                :deep(svg) {
                  width: 24px;
                  height: 24px;
                }
              }

              .header-content-title-text {
                &:not(.header-content-title-text-with-subtitle) {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 125%;
                }
              }
            }
            .header-scope-desktop {
              @apply hidden;
            }
          }
          .header-content-right {
            @apply justify-center;

            .header-content-user {
              gap: 4px;
              padding: 0;
              width: 40px;
              min-width: 40px;

              &:hover {
                background-color: transparent;
              }

              .header-content-user-infos {
                @apply hidden;
              }
            }
          }
        }
      }
      .header-scope-mobile {
        @apply flex flex-col items-center self-stretch;
        padding: 0 8px;
      }
    }
    @media (max-width: 424.98px) {
      .header-content-container {
        .header-content {
          .header-content-left {
            .header-content-title {
              .header-content-title-text {
                &.header-content-title-text-with-subtitle {
                  @apply overflow-hidden text-ellipsis whitespace-normal text-refonte-black;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 125%;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;

                  :deep(svg) {
                    @apply hidden;
                  }

                  .header-content-subtitle {
                    @apply hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
    .header-alert {
      background-color: #f8b44e;
      padding: 8px;
      border-radius: 4px;
      max-width: 380px;

      span {
        @apply overflow-hidden text-ellipsis whitespace-normal text-refonte-white;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;

        b {
          font-size: 14px;
          line-height: 125%;
        }
      }
    }

    .header-alert-mobile {
      @apply hidden cursor-pointer;
      background-color: transparent;
      border: 1px solid #f8b44e;
      padding: 8px;
      border-radius: 4px;
      max-width: 380px;
    }

    @media (max-width: 1279.98px) {
      .header-alert {
        @apply hidden;
      }

      .header-alert-mobile {
        @apply block;
      }
    }
  }
</style>
