// tailwind.config.js
// import {loadEnv} from 'vite';
const env = {};

// console.log('TAIL', env, 'WIND');
/*=============================================>>>>>
= Tailwind Default Theme Colors =
===============================================>>>>>*/
import colors from 'tailwindcss/colors';
delete colors['lightBlue'];
delete colors['warmGray'];
delete colors['trueGray'];
delete colors['coolGray'];
delete colors['blueGray'];

import { extra_palettes } from './src/assets/data/data_colors.js';

const available_palettes = Object.assign({}, colors, extra_palettes);
/*=============================================>>>>>
= Setting colors from .env configuration file =
===============================================>>>>>*/
const CONFIG_CORE =
    env.VITE_CORE_COLOR && available_palettes[env.VITE_CORE_COLOR]
        ? available_palettes[env.VITE_CORE_COLOR]
        : available_palettes.core;

const CONFIG_PRIMARY =
    env.VITE_PRIMARY_COLOR && available_palettes[env.VITE_PRIMARY_COLOR]
        ? available_palettes[env.VITE_PRIMARY_COLOR]
        : available_palettes.sky;

const CONFIG_SECONDARY =
    env.VITE_SECONDARY_COLOR && available_palettes[env.VITE_SECONDARY_COLOR]
        ? available_palettes[env.VITE_SECONDARY_COLOR]
        : available_palettes.slate;

/*=============================================>>>>>
= Tailwind Configuration =
===============================================>>>>>*/
export default {
    content: [
        './src/index.html',
        './src/**/*.{js,vue}',
        './src/assets/data/data_maps.js',
        './src/assets/data/faq.js'
    ],
    safelist: [
        'bg-jinius-red',
        'bg-jinius-green',
        'bg-jinius-blue',
        'bg-jinius-dark-green',
        'bg-jinius-purple',
        'bg-jinius-yellow',
        'bg-jinius-pink',
        'lg:w-1/2',
        'lg:w-1/3',
        'lg:w-1/4',
        'lg:w-1/5',
        'lg:w-1/6',
        {
            pattern:
                /(bg|border|text)-(secondary|teal|blue|cyan|orange|purple|rose|green|danger|accent)-(600)/,
            variants: ['group-hover', 'hover']
        }
    ],
    theme: {
        fontFamily: {
            sans: [
                'Roboto',
                'Helvetica',
                'ui-sans-serif',
                'system-ui',
                'sans-serif'
            ],
            refonte: ['Inter', 'ui-sans-serif', 'system-ui', 'sans-serif']
        },
        colors: {
            current: 'currentColor',

            core: CONFIG_CORE,
            primary: CONFIG_PRIMARY,
            secondary: CONFIG_SECONDARY,

            accent: available_palettes.gray,
            success: available_palettes.custom_greenVivid,
            info: available_palettes.custom_blue,
            danger: available_palettes.custom_redVivid,
            white: available_palettes.white,
            black: available_palettes.black,
            transparent: available_palettes.transparent,
            yellow: available_palettes.custom_yellowVivid,
            orange: available_palettes.custom_orangeVivid,
            cyan: available_palettes.custom_cyan,
            teal: available_palettes.custom_teal,
            purple: available_palettes.custom_purple,
            blue: available_palettes.custom_blue,
            green: available_palettes.custom_green,
            rose: available_palettes.rose,
            refonte_blue: available_palettes.refonte_blue,

            'jinius-green': available_palettes['jinius-green'],
            'jinius-dark-green': available_palettes['jinius-dark-green'],
            'jinius-blue': available_palettes['jinius-blue'],
            'jinius-red': available_palettes['jinius-red'],
            'jinius-purple': available_palettes['jinius-purple'],
            'jinius-rose': available_palettes['jinius-rose'],
            'jinius-yellow': available_palettes['jinius-yellow'],
            'jinius-pink': available_palettes['jinius-pink'],
            'refonte-menu': available_palettes['refonte-menu'],
            'refonte-menu-30': available_palettes['refonte-menu-30'],
            'refonte-menu-40': available_palettes['refonte-menu-40'],
            'refonte-menu-60': available_palettes['refonte-menu-60'],
            'refonte-black': available_palettes['refonte-black'],
            'refonte-black-30': available_palettes['refonte-black-30'],
            'refonte-black-40': available_palettes['refonte-black-40'],
            'refonte-black-60': available_palettes['refonte-black-60'],
            'refonte-white': available_palettes['refonte-white'],
            'refonte-white-10': available_palettes['refonte-white-10'],
            'refonte-white-30': available_palettes['refonte-white-30'],
            'refonte-white-60': available_palettes['refonte-white-60'],
            'refonte-background-0': available_palettes['refonte-background-0'],
            'refonte-background-1': available_palettes['refonte-background-1'],
            'refonte-background-2': available_palettes['refonte-background-2'],
            'refonte-pale-violet-1':
                available_palettes['refonte-pale-violet-1'],
            'refonte-pale-violet-2':
                available_palettes['refonte-pale-violet-2'],
            'refonte-pale-violet-3':
                available_palettes['refonte-pale-violet-3'],
            'refonte-presentiel': available_palettes['refonte-presentiel'],
            'refonte-conference': available_palettes['refonte-conference'],
            'refonte-e-learning': available_palettes['refonte-e-learning'],
            'refonte-webinar': available_palettes['refonte-webinar'],
            'refonte-classe-virtuelle':
                available_palettes['refonte-classe-virtuelle'],
            'refonte-cursus': available_palettes['refonte-cursus']
        },
        extend: {
            screens: {
                '3xl': '2200px',
                '4xl': '2500px'
            },
            width: {
                28: '7rem'
            },
            spacing: {
                72: '18rem',
                84: '21rem',
                96: '24rem',
                108: '27rem',
                120: '30rem',
                132: '33rem',
                144: '36rem',
                156: '39rem',
                168: '42rem'
            },
            borderWidth: {
                1: '1px'
            },
            skew: {
                '-30': '-30deg'
            }
        }
    }
};
