<template>
  <div class="espace">
    <template v-if="!loading">
      <template v-if="$breakpoints.w >= $breakpoints.screens.lg">
        <transition
          name="slide"
          v-bind:key="sidebar">
          <aside
            v-if="showSidebar && sidebar"
            class="espace__aside espace__aside--left">
            <div>
              <div
                v-if="
                  espaces_list['entreprise'] &&
                  !espaces_list['entreprise'].restricted &&
                  !espaces_list['entreprise'].menuHidden
                "
                v-on:click="navigateToNewEntrepriseSpace()"
                v-bind:class="`aside__item aside__item--jinius-new`">
                ESPACE GESTION (NOUVEAU)
              </div>
              <template
                v-for="espace in espaces_list"
                v-bind:key="espace.label">
                <div
                  v-if="!espace.restricted && !espace.menuHidden"
                  v-on:click="navigateToSpace(espace.id)"
                  v-bind:class="`aside__item ${espace.id === space_id ? 'aside__item--active' : ''} ${!hasValidateContactInfo || espace.disabled || hasAnecsCjecEndDateToValidate ? 'aside__item--disabled' : ''} aside__item--${displayEspaceColor(espace.id)}`">
                  {{ espace.label }}
                </div>
              </template>
            </div>
          </aside>
        </transition>
      </template>

      <div
        class="espace__body"
        v-bind:class="{
          'espace__body--opened-sidebar': showSidebar && sidebar
        }">
        <div class="mb-4 flex flex-wrap lg:mb-0 lg:mt-6 lg:flex-wrap-reverse">
          <div
            v-if="is_valid_space"
            class="body__header">
            <template v-for="rubrique in espaces_list[space_id].rubriques">
              <div
                v-if="!rubrique.restricted && !rubrique.menuHidden"
                v-show="
                  $route.params.space_id === 'account' ||
                  $breakpoints.w >= $breakpoints.screens.lg
                "
                v-on:click="navigateToSubSpace(rubrique.id)"
                v-bind:key="`desktop-${rubrique.id}`"
                class="header__item"
                v-bind:class="[
                  { 'header__item--active': rubrique.id === sub_space_id },
                  `header__item--${displayEspaceColor(space_id)}`
                ]">
                {{ rubrique.label }}
              </div>
              <div
                v-bind:key="`mobile-${rubrique.id}`"
                v-if="
                  $route.params.space_id !== 'account' &&
                  $breakpoints.w < $breakpoints.screens.lg &&
                  rubrique.id === sub_space_id
                "
                class="flex w-full">
                <breadcrumb-nav
                  v-bind:ref_list="espaces_list"
                  class="p-4"
                  v-bind:color="espaces_list[space_id].color" />
              </div>
            </template>
          </div>
          <div
            v-if="space_id === 'entreprise'"
            class="mb-8 flex h-16 w-full flex-wrap px-4 sm:mb-0 lg:h-20">
            <div class="flex items-center">
              <span class="mb-2 border-l-2 px-4 text-lg leading-none">
                Sélection de l'entreprise
              </span>
            </div>
            <header-entreprise-button class="header__link" />
          </div>
        </div>

        <div
          id="body__main"
          class="body__main"
          v-bind:class="[
            `body__main--${displayEspaceColor(space_id)}`,
            { '!bg-core-100 lg:!bg-core-200': is_details || is_my_account }
          ]"
          v-bind:key="$breakpoints.w >= $breakpoints.screens.lg">
          <div
            class="main__wrapper"
            ref="scrollable_body__main">
            <transition
              name="fade-router"
              mode="out-in">
              <component
                v-if="is_component_available"
                v-bind:is="fetchRubriqueComponent()"
                v-bind:props="
                  $props
                    ? { ...$props, check_is_unsaved }
                    : { check_is_unsaved }
                "
                class="h-full" />
              <error
                v-else
                no_return_home />
            </transition>
          </div>
          <simplebar-scroll
            v-if="$breakpoints.w >= $breakpoints.screens.lg"
            colors="bg-core-700"
            target="scrollable_body__main" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="w-full lg:h-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>
    </template>

    <anecs-cjec-modal
      v-bind:modal_show="anecs_cjec_modal"
      v-bind:onclose="closeANECSCJECModal" />
  </div>
</template>

<script>
  import participantSession from '@/views/_espace/_participant/sessions.vue';
  import participantRessources from '@/views/_espace/_participant/ressources.vue';
  import participantDetails from '@/views/_espace/_participant/details.vue';
  import participantCommandes from '@/views/_espace/_participant/commandes.vue';
  import participantFactures from '@/views/_espace/_participant/factures.vue';

  import entrepriseEntreprise from '@/views/_espace/_entreprise/entreprise.vue';
  import entrepriseCommandes from '@/views/_espace/_entreprise/commandes.vue';
  import entrepriseInscriptions from '@/views/_espace/_entreprise/inscriptions.vue';
  import entrepriseFactures from '@/views/_espace/_entreprise/factures.vue';
  import entrepriseContrats from '@/views/_espace/_entreprise/contrats.vue';
  import entrepriseDetails from '@/views/_espace/_entreprise/details.vue';

  import formateurSession from '@/views/_espace/_formateur/sessions.vue';
  import formateurCandidature from '@/views/_espace/_formateur/candidatures.vue';
  import formateurAgenda from '@/views/_espace/_formateur/agenda.vue';
  import formateurCv from '@/views/_espace/_formateur/cv.vue';
  import formateurAppreciations from '@/views/_espace/_formateur/appreciations.vue';
  import formateurContrats from '@/views/_espace/_formateur/contrats.vue';
  import formateurDetails from '@/views/_espace/_formateur/details.vue';

  import controleurDeStageECStagiaires from '@/views/_espace/_stage/_controleur/stagiaires.vue';
  import controleurDeStageCACStagiaires from '@/views/_espace/_stage/_controleur/stagiaires.vue';

  import maitreDeStageStagiaires from '@/views/_espace/_stage/_maitre/stagiaires.vue';

  import stagiaireElearning from '@/views/_espace/_stage/_stagiaire/elearning.vue';
  import stagiaireSession from '@/views/_espace/_stage/_stagiaire/sessions.vue';
  import stagiaireDetails from '@/views/_espace/_stage/_stagiaire/details.vue';

  import stagiaireCACInformations from '@/views/_espace/_stage/_stagiaire/informations-cac.vue';
  import stagiaireCACSession from '@/views/_espace/_stage/_stagiaire/sessions.vue';
  import stagiaireCACDetails from '@/views/_espace/_stage/_stagiaire/details.vue';
  import stagiaireCACFormations from '@/views/_espace/_stage/_stagiaire/formations-libres.vue';

  import accountInformations from '@/views/_espace/_account/informations';
  import accountPreferences from '@/views/_espace/_account/preferences';

  import error from '@/views/errors/error-404.vue';
  import simplebarScroll from '@/components/utils/simplebar-scroll.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';

  import spinner from '@/components/utils/spinner.vue';
  import breadcrumbNav from '@/components/utils/breadcrumb-nav.vue';
  import AnecsCjecModal from '@/views/_espace/_account/_informations/_modal/anecs-cjec-modal';

  import headerEntrepriseButton from '@/components/header/header-entreprise-button.vue';

  import { createNamespacedHelpers } from 'vuex';
  import { map_unsaved_forms } from '@/assets/data/data_unsaved_form';
  import pifAccesLms from '@/components/utils/pif-acces-lms.vue';

  const { mapState: mapStateEspace, mapActions: mapActionsEspace } =
    createNamespacedHelpers('espace');
  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');
  const { mapState: mapStateFormateur, mapActions: mapActionsFormateur } =
    createNamespacedHelpers('formateur');
  const { mapState: mapStateObserver, mapMutations: mapMutationsObserver } =
    createNamespacedHelpers('observer');
  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  const { mapActions: mapActionsCatalogue } =
    createNamespacedHelpers('catalogue');

  export default {
    components: {
      spinner,
      breadcrumbNav,
      error,
      simplebarScroll,
      participantSession,
      participantRessources,
      participantDetails,
      participantCommandes,
      participantFactures,

      entrepriseEntreprise,
      entrepriseCommandes,
      entrepriseInscriptions,
      entrepriseFactures,
      entrepriseContrats,
      entrepriseDetails,

      formateurSession,
      formateurCandidature,
      formateurAgenda,
      formateurCv,
      formateurAppreciations,
      formateurContrats,
      formateurDetails,

      controleurDeStageECStagiaires,
      controleurDeStageCACStagiaires,

      maitreDeStageStagiaires,

      stagiaireElearning,
      stagiaireSession,
      stagiaireDetails,

      stagiaireCACInformations,
      stagiaireCACSession,
      stagiaireCACDetails,
      stagiaireCACFormations,

      accountInformations,
      accountPreferences,

      AnecsCjecModal,
      pifAccesLms,

      headerEntrepriseButton
    },
    provide: {
      components_list: {
        participantSession,
        participantRessources,
        participantDetails,
        participantCommandes,
        participantFactures,

        entrepriseEntreprise,
        entrepriseCommandes,
        entrepriseInscriptions,
        entrepriseFactures,
        entrepriseDetails,

        formateurSession,
        formateurCandidature,
        formateurAgenda,
        formateurCv,
        formateurAppreciations,
        formateurDetails,

        controleurDeStageECStagiaires,
        controleurDeStageCACStagiaires,

        maitreDeStageStagiaires,

        stagiaireElearning,
        stagiaireSession,
        stagiaireDetails,

        stagiaireCACInformations,
        stagiaireCACSession,
        stagiaireCACDetails,
        stagiaireCACFormations,

        accountInformations,
        accountPreferences
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.check_is_unsaved) {
        this.openModalRoute(next);
      } else {
        next();
      }
    },
    props: {
      space_id: String,
      sub_space_id: String,
      sub_children_id: String
    },
    data: () => ({
      current_rubrique: null,
      loading: false,
      anecs_cjec_modal: false,
      map_unsaved_forms
    }),
    computed: {
      ...mapStateObserver(['sidebar', 'showSidebar']),
      ...mapStateEspace(['espaces_list']),
      ...mapStateAccount([
        'hasValidateContactInfo',
        'entreprise',
        'formateur',
        'participant',
        'hasAnecsCjecEndDateToValidate',
        'accountForm',
        'savedAccountForm',
        'preferencesForm',
        'savedPreferencesForm',
        'controleurDeStageEC',
        'controleurDeStageCAC',
        'maitreDeStage',
        'stagiaire',
        'stagiaireCAC',
        'pif_stage'
      ]),
      ...mapStateFormateur([
        'isFormateur',
        'hasNavettes',
        'hasAppreciations',
        'hasContrats',
        'cvForm',
        'savedCvForm'
      ]),
      ...mapStateClient(['entiteForm', 'savedEntiteForm', 'profile']),
      is_valid_space() {
        return Object.prototype.hasOwnProperty.call(
          this.espaces_list,
          this.space_id
        );
      },
      is_component_available() {
        return this.$options.components[this.fetchRubriqueComponent()];
      },
      is_details() {
        return (
          this.$route.params.sub_space_id &&
          this.$route.params.sub_space_id === 'details'
        );
      },
      is_my_account() {
        return (
          this.$route.params.space_id &&
          this.$route.params.space_id === 'account'
        );
      },
      checkIsUnsaved() {
        return map_unsaved_forms.some(
          (form) =>
            this.$route.params.space_id === form.space_id &&
            this.$route.params.sub_space_id === form.sub_space_id &&
            (!form.sub_children_id ||
              this.$route.params.sub_children_id === form.sub_children_id) &&
            JSON.stringify(this[form.savedStateName]) !==
              JSON.stringify(this[form.stateName])
        );
      }
    },
    watch: {
      async space_id() {
        await this.fetchEspaceInfo(this.space_id);
      },
      async ['profile.activeEntrepriseId']() {
        await this.fetchEspaceInfo(this.space_id);
      },
      $route() {
        if (this.hasAnecsCjecEndDateToValidate) {
          if (
            this.$router.history.current.path !== '/account/informations' &&
            this.$router.history.current.path !== '/account/preferences'
          ) {
            this.$router.push('/account/informations');
          }
        }
      }
    },
    async created() {
      this.loading = true;
      await this.fetchUserInfo();
      await this.fetchEspaceInfo(this.space_id);
      this.hasEspaceLinkVisible(this.espaces_list);
      this.loadSubRubrique();
      this.loading = false;
    },
    beforeUpdate() {
      this.loadSubRubrique();
    },
    methods: {
      ...mapActionsEspace([ACTION_TYPES.SET_ESPACE, ACTION_TYPES.SET_RUBRIQUE]),
      ...mapActionsAccount([
        ACTION_TYPES.GET_CONTACT_PROFILE,
        ACTION_TYPES.GET_STAGIAIRE_PROFILE,
        ACTION_TYPES.SET_MODAL_ROUTE
      ]),
      ...mapActionsFormateur([ACTION_TYPES.GET_FORMATEUR_PROFILE]),
      ...mapActionsClient([ACTION_TYPES.GET_ENTREPRISE_PROFILE]),
      ...mapActionsCatalogue([ACTION_TYPES.GET_PARTICIPANT_CATALOGUE]),
      ...mapMutationsObserver(['setShowSidebar']),
      async fetchUserInfo() {
        // Get user profile
        await this[ACTION_TYPES.GET_CONTACT_PROFILE]();
        if (this.stagiaire) {
          await this.refreshStagiaireProfile();
        }
        // Then set espace's visibility accordingly to the user's profile
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'entreprise',
          name: 'restricted',
          value: !this.entreprise
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'formateur',
          name: 'restricted',
          value: !this.formateur
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'participant',
          name: 'restricted',
          value: !this.participant
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'controleurDeStageEC',
          name: 'restricted',
          value: !this.controleurDeStageEC
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'controleurDeStageCAC',
          name: 'restricted',
          value: !this.controleurDeStageCAC
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'maitreDeStage',
          name: 'restricted',
          value: !this.maitreDeStage
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'stagiaire',
          name: 'restricted',
          value: !this.stagiaire
        });
        await this[ACTION_TYPES.SET_ESPACE]({
          espace: 'stagiaireCAC',
          name: 'restricted',
          value: !this.stagiaireCAC
        });
        await this[ACTION_TYPES.GET_PARTICIPANT_CATALOGUE]();

        // Finally redirect the user if some profile's info are missing
        if (!this.hasValidateContactInfo) {
          await this.$router.push('/account/informations');
        }
        if (this.hasAnecsCjecEndDateToValidacollapsete) {
          if (this.$router.history.current.path !== '/account/informations') {
            await this.$router.push('/account/informations');
          }
          this.openANECSCJECModal();
        }
      },
      async refreshStagiaireProfile() {
        await this[ACTION_TYPES.GET_STAGIAIRE_PROFILE]();
      },
      openModalRoute(next) {
        this[ACTION_TYPES.SET_MODAL_ROUTE]({
          modal_route: true,
          next_route: next
        });
      },
      loadSubRubrique() {
        if (!this.sub_space_id) {
          this.initSubRubrique();
        }
      },
      initSubRubrique() {
        if (
          this.is_valid_space &&
          this.espaces_list[this.space_id] &&
          !this.espaces_list[this.space_id].restricted
        ) {
          const current_rubriques = this.espaces_list[this.space_id].rubriques;

          if (!this.current_rubrique) {
            this.current_rubrique = Object.keys(current_rubriques)[0];
          }

          this.navigateToSubSpace(this.current_rubrique);
        } else {
          const ALT_ROOT_URLS = [
            'aide-en-ligne',
            'rgpd',
            'error-server',
            'error-authentification'
          ];

          if (ALT_ROOT_URLS.includes(this.$route.params.space_id)) {
            this.$router.push({ name: this.$route.params.space_id });
          } else {
            // Filtre les espaces disabled
            const espaces = Object.keys(this.espaces_list);
            const espacesEnabled = espaces
              .filter((t) => !this.espaces_list[t].disabled)
              .filter((t) => !this.espaces_list[t].restricted);
            // Ordre décroissant des redirectPriority

            espacesEnabled.sort(
              (a, b) =>
                this.espaces_list[b].connexionPriority -
                this.espaces_list[a].connexionPriority
            );
            // Dernier élement
            const defaultConnexionUrl =
              espacesEnabled.length > 0 ? espacesEnabled.pop() : '/account';

            this.$router.push({ path: defaultConnexionUrl });
          }
        }
      },
      async navigateToSpace(space_id) {
        await this.fetchEspaceInfo(space_id);
        this.current_rubrique = null;
        this.$router.push({ path: `/${space_id}` });
      },
      async navigateToNewEntrepriseSpace() {
        this.$router.push({ path: `/refonte/gestion` });
      },
      navigateToSubSpace(sub_space_id) {
        // check unsaved changes
        if (this.check_is_unsaved) {
          this.openModalRoute((to) => {
            if (to !== false) {
              this.pushToSubSpace(sub_space_id);
            }
          });
        } else {
          this.pushToSubSpace(sub_space_id);
        }
      },
      pushToSubSpace(sub_space_id) {
        this.current_rubrique = null;
        this.$router.push({ path: `/${this.space_id}/${sub_space_id}` });
      },
      fetchRubriqueComponent() {
        const espace = this.espaces_list[this.space_id];
        const rubrique =
          espace && espace.rubriques && espace.rubriques[this.sub_space_id];

        if (
          espace &&
          !espace.restricted &&
          !espace.disabled &&
          rubrique &&
          !rubrique.restricted &&
          this.sub_space_id
        ) {
          const rubriqueName = this.sub_space_id;
          const rubriqueNameCapitalized =
            rubriqueName.charAt(0).toUpperCase() + rubriqueName.slice(1);

          const rubriqueComponentName = this.space_id + rubriqueNameCapitalized;

          return rubriqueComponentName;
        }
      },
      hasEspaceLinkVisible(espaces_list) {
        const has_visible_espace = Object.values(espaces_list).some(
          (espace) =>
            (!espace.restricted && !espace.menuHidden) || this.stagiaire
        );

        if (has_visible_espace) {
          !this.showSidebar && this.setShowSidebar(true);
        } else {
          this.showSidebar && this.setShowSidebar(false);
        }
      },
      displayEspaceColor(id) {
        return this.espaces_list[id] && this.espaces_list[id].color
          ? this.espaces_list[id].color
          : 'jinius-green';
      },
      async fetchEspaceInfo(spaceId) {
        if (spaceId === 'formateur' && this.formateur) {
          await this[ACTION_TYPES.GET_FORMATEUR_PROFILE]();
          this[ACTION_TYPES.SET_RUBRIQUE]({
            espace: 'formateur',
            rubrique: 'candidature',
            name: 'menuHidden',
            value: !this.hasNavettes
          });
          this[ACTION_TYPES.SET_RUBRIQUE]({
            espace: 'formateur',
            rubrique: 'appreciations',
            name: 'menuHidden',
            value: !this.hasAppreciations
          });
          this[ACTION_TYPES.SET_RUBRIQUE]({
            espace: 'formateur',
            rubrique: 'contrats',
            name: 'menuHidden',
            value: !this.hasContrats
          });
        } else if (spaceId === 'entreprise' && this.entreprise) {
          const response = await this[ACTION_TYPES.GET_ENTREPRISE_PROFILE]();

          if (
            response &&
            response.data &&
            response.data.entreprises &&
            response.data.entreprises.length > 0
          ) {
            const entreprises = response.data.entreprises;

            const index = entreprises.findIndex(
              (e) => e.entrepriseId === this.profile.activeEntrepriseId
            );
            const entreprise =
              response.data.entreprises[index !== -1 ? index : 0];

            this[ACTION_TYPES.SET_RUBRIQUE]({
              espace: 'entreprise',
              rubrique: 'entreprise',
              name: 'restricted',
              value: !(
                entreprise.droitAdministrateur ||
                entreprise.droitAccesDocuments ||
                entreprise.droitInscription
              )
            });
            this[ACTION_TYPES.SET_RUBRIQUE]({
              espace: 'entreprise',
              rubrique: 'contrats',
              name: 'menuHidden',
              value: !entreprise.hasContrats
            });
          }
        }
      },
      openANECSCJECModal() {
        this.anecs_cjec_modal = true;
      },
      closeANECSCJECModal() {
        this.anecs_cjec_modal = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .espace {
    @apply flex h-full justify-between;

    .espace__aside {
      @apply flex w-2/12 min-w-[20rem] flex-col px-4;
      overflow: inherit !important;

      &.espace__aside--closed {
        @apply w-0;
      }

      .aside__item {
        @apply mb-4 inline-flex h-16 w-full cursor-pointer items-center justify-center rounded border-l-8 bg-core-100 p-4 text-center font-bold uppercase leading-tight shadow after:absolute after:right-0 after:z-[999px] after:-mr-4 after:content-[''] hover:text-core-900;

        &:after {
          opacity: 0;
          transition: opacity 0.3s linear;
          transition-delay: 0.2s;
        }

        &.aside__item--disabled {
          @apply pointer-events-none bg-core-200 text-core-400;
        }

        &.aside__item--active {
          @apply relative rounded-r-none text-white shadow-none;
          background-position: left;
          &:after {
            opacity: 1;
            width: 0;
            height: 0;
            border-top: 2rem solid transparent;
            border-left: 1rem solid var(--item-color);
            border-bottom: 2rem solid transparent;
          }
        }

        &.aside__item--jinius-red {
          @apply border-jinius-red;
          --item-color: theme('colors.jinius-red');
        }

        &.aside__item--jinius-blue {
          @apply border-jinius-blue;
          --item-color: theme('colors.jinius-blue');
        }

        &.aside__item--jinius-green {
          @apply border-jinius-green;
          --item-color: theme('colors.jinius-green');
        }

        &.aside__item--jinius-purple {
          @apply border-jinius-purple;
          --item-color: theme('colors.jinius-purple');
        }

        &.aside__item--jinius-rose {
          @apply border-jinius-rose;
          --item-color: theme('colors.jinius-rose');
        }

        &.aside__item--jinius-yellow {
          @apply border-jinius-yellow;
          --item-color: theme('colors.jinius-yellow');
        }

        &.aside__item--jinius-pink {
          @apply border-jinius-pink;
          --item-color: theme('colors.jinius-pink');
        }

        &.aside__item--jinius-new {
          border-color: rgba(49, 83, 122, 0.6);
          --item-color: rgba(49, 83, 122, 0.6);
        }

        background: linear-gradient(
          to left,
          transparent 50%,
          var(--item-color) 50%
        );
        background-size: 225% 100%;
        background-position: right;
        transition:
          background 0.2s linear,
          color 0.1s linear;
      }

      &.espace__aside--left {
        @apply mt-4 max-h-full justify-between overflow-y-auto;

        .aside__item:not(.aside__item--active) {
          @apply hover:bg-white;
        }
      }
    }

    .espace__body {
      @apply relative flex w-full flex-col transition-all ease-in-out lg:px-4;

      &.espace__body--opened-sidebar {
        @apply lg:w-10/12 lg:pl-0;
      }

      .body__header {
        @apply flex w-full items-center overflow-x-auto lg:h-[4rem] lg:space-x-2 lg:pt-4;

        .header__item {
          @apply inline-flex h-full w-full cursor-pointer items-center justify-center overflow-hidden rounded-t-lg border-l border-r border-t border-transparent bg-core-300 p-2 text-center text-sm font-bold uppercase leading-tight shadow-sm transition duration-200 ease-in hover:bg-core-200 hover:text-core-900 hover:shadow-md lg:p-0 lg:text-base;

          &.header__item--active {
            @apply relative border-core-300 bg-white text-core-900 shadow;
            &:before {
              content: '';
              @apply absolute top-0 h-2 w-full border-t-2;
            }

            &.header__item--jinius-red:before {
              @apply border-jinius-red;
            }

            &.header__item--jinius-blue:before {
              @apply border-jinius-blue;
            }

            &.header__item--jinius-green:before {
              @apply border-jinius-green;
            }

            &.header__item--jinius-purple:before {
              @apply border-jinius-purple;
            }

            &.header__item--jinius-yellow:before {
              @apply border-jinius-yellow;
            }

            &.header__item--jinius-pink:before {
              @apply border-jinius-pink;
            }
          }
        }
      }

      .body__main {
        @apply relative flex max-h-full w-full flex-auto overflow-y-auto rounded-b-lg border-b border-l border-r border-core-300 bg-white lg:max-h-[calc(100vh-8rem-42px)];

        .main__wrapper {
          @apply relative h-full w-full lg:px-5 lg:pb-4 lg:pt-5;
        }

        :deep(section) {
          &.section__primary {
            @apply mb-6 flex w-full flex-wrap rounded-lg border border-core-200 bg-white px-3 shadow lg:px-6;
          }

          .section__body {
            @apply my-4 flex w-full flex-col p-3 lg:px-8 lg:py-4;
          }
        }

        :deep(h1.section__title) {
          @apply ml-1 mt-8 flex w-full border-l-4 pl-5 text-2xl font-bold uppercase leading-none text-core-900 lg:text-3xl;
        }

        &.body__main--jinius-red {
          :deep(h1.section__title) {
            @apply border-jinius-red;
          }
        }
        &.body__main--jinius-blue {
          :deep(h1.section__title) {
            @apply border-jinius-blue;
          }
        }
        &.body__main--jinius-green {
          :deep(h1.section__title) {
            @apply border-jinius-green;
          }
        }
        &.body__main--jinius-purple {
          :deep(h1.section__title) {
            @apply border-jinius-purple;
          }
        }
        &.body__main--jinius-yellow {
          :deep(h1.section__title) {
            @apply border-jinius-yellow;
          }
        }
        &.body__main--jinius-pink {
          :deep(h1.section__title) {
            @apply border-jinius-pink;
          }
        }

        :deep(h2.section__subtitle) {
          @apply mb-4 mt-4 border-l-2 pl-4 font-bold leading-none;
        }

        :deep(.section__description) {
          @apply my-4 border-l-4 border-jinius-green pl-4 font-bold leading-none sm:text-lg sm:leading-tight lg:mb-0 lg:mt-2 lg:text-xl;
        }

        :deep(.question) {
          @apply w-full text-base font-bold leading-tight text-core-900;
        }

        :deep(.description) {
          @apply mb-1 w-full rounded-sm border-l-2 border-core-500 bg-gradient-to-r from-white via-white to-transparent px-6 py-2 italic leading-tight text-core-600 lg:text-lg;
        }

        :deep(.submit__button) {
          @apply z-10 mt-0 flex cursor-pointer items-center justify-center rounded bg-jinius-dark-green px-8 py-3 text-lg text-core-200 shadow transition hover:bg-jinius-green hover:text-white;
          &:disabled {
            @apply cursor-not-allowed border-core-400 bg-core-200 text-core-500;
          }
        }

        :deep(.cancel__button) {
          @apply mb-2 ml-0 w-64 rounded-md border bg-core-100 p-3 text-center font-bold text-core-600 shadow hover:bg-white;
          &:disabled {
            @apply pointer-events-none bg-core-200 text-core-400;
          }
        }

        :deep(.button__available) {
          @apply flex h-6 w-full cursor-pointer items-center justify-center break-normal rounded bg-success-700 px-4 text-sm font-bold leading-none text-white shadow;
        }

        :deep(.button__unavailable) {
          @apply flex h-6 w-full cursor-not-allowed items-center justify-center break-normal rounded bg-accent-400 px-4 text-sm font-bold leading-none text-white shadow;
        }

        :deep(.form__item) {
          @apply mb-6 w-144 max-w-full;
        }

        :deep(.form__group-label) {
          @apply my-4 -ml-4 border-l-2 pl-3 font-bold leading-none text-core-900;
        }

        :deep(.simplebar-content) {
          @apply h-full;
        }
        :deep(.simplebar-scrollbar::before) {
          opacity: 0.1;
          @apply bg-core-600 transition duration-300 ease-out;
        }
        &:hover :deep(.simplebar-scrollbar::before) {
          @apply opacity-25;
        }
      }
    }
  }
</style>
