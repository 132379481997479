<template>
  <template v-if="!loading">
    <refonte-card>
      <contrats-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <formations-number
          class="!pr-0"
          v-bind:number="contrats.length"
          v-bind:label="`${contrats.length > 1 ? 'Contrats' : 'Contrat'} dont`" />
        <formations-number
          class="!pl-0"
          v-bind:number="nombreContratsASigner"
          label="à signer" />
      </div>
      <refonte-separator class="px-[12px]" />
      <contrats-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import ContratsContent from '@/views/_espace/_refonte/gestion/_contrats/contrats-content.vue';
  import { useContratFilterStore, useContratStore } from '@/stores/contrats';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import ContratsFilters from '@/views/_espace/_refonte/gestion/_contrats/contrats-filters.vue';
  import { Contrat } from '@/types/gestion/contrats-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const contratFilterStore = useContratFilterStore();

  const { filters } = storeToRefs(contratFilterStore);

  const dataLoading = ref<boolean>(false);
  const contratStore = useContratStore();
  const { contrats } = storeToRefs(contratStore);

  const nombreContratsASigner = computed(
    () =>
      contrats.value.filter(
        (c: Contrat) => c.statutLabel && c.statutLabel === 'A signer'
      ).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await contratStore.fetchContrats(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    contratFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });
</script>

<style lang="scss" scoped></style>
