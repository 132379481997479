<template>
  <div
    class="refonte-table"
    v-bind:class="props.class">
    <refonte-table-row
      v-for="(row, rowIndex) in data"
      v-bind:key="props.table_index + rowIndex"
      v-bind:selectable="props.selectable"
      v-bind:table_small="props.table_small"
      v-bind:row_selected="row.selected"
      v-on:select="(value: boolean) => props.select_row(row, value)"
      v-bind:row="row"
      v-bind:on_details="
        props.on_details ? () => props.on_details(row) : undefined
      "
      v-bind:on_details_disabled="
        props.on_details_disabled
          ? () => props.on_details_disabled(row)
          : undefined
      "
      v-bind:on_details_variant="
        props.on_details_variant ? props.on_details_variant : undefined
      "
      v-bind:on_details_label="
        props.on_details_label ? () => props.on_details_label(row) : undefined
      ">
      <refonte-table-item
        v-if="props.main_data_etablissement"
        key="etablissement"
        v-bind:label="`Etablissement${props.main_data_etablissement_status && props.main_data_etablissement_status(row) ? ` - ${props.main_data_etablissement_status(row)}` : ''}`"
        v-bind:label_icon="
          props.main_data_etablissement_icon
            ? props.main_data_etablissement_icon(row)
            : undefined
        "
        class="refonte-table-item-etablissement"
        v-bind:width="props.main_data_etablissement_width ?? 175">
        <template v-slot:content>
          <div
            v-bind:title="
              row[props.main_data_etablissement]
                ? row[props.main_data_etablissement]
                : '-'
            ">
            {{
              row[props.main_data_etablissement]
                ? row[props.main_data_etablissement]
                : '-'
            }}
          </div>
          <div
            v-if="
              props.main_data_etablissement_siret &&
              row[props.main_data_etablissement_siret]
            "
            v-bind:title="row[props.main_data_etablissement_siret]"
            class="refonte-table-item-sub-text uppercase">
            SIRET {{ row[props.main_data_etablissement_siret] }}
          </div>
        </template>
      </refonte-table-item>
      <refonte-table-main-item
        v-bind:status="row[props.main_status ? props.main_status : 'status']"
        v-bind:width="props.main_width ? props.main_width : 308"
        v-bind:label="
          props.get_main_label ? props.get_main_label(rowIndex) : undefined
        ">
        <template
          v-if="
            (props.main_tags_count && row[props.main_tags_count]) ||
            (props.main_tags_type && props.main_tags_type_label)
          "
          v-slot:itemTags>
          <refonte-tag
            v-if="props.main_tags_count && row[props.main_tags_count]"
            v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{
              row[props.main_tags_count]
            }}</refonte-number>
          </refonte-tag>
          <refonte-tag
            v-if="props.main_tags_type && props.main_tags_type_label"
            v-bind:variant="row[props.main_tags_type]">
            {{ row[props.main_tags_type_label] }}
          </refonte-tag>
        </template>
        <template
          v-if="
            (props.main_date && row[props.main_date]) ||
            (props.main_dates && row[props.main_dates]) ||
            (props.main_start_date &&
              row[props.main_start_date] &&
              props.main_end_date &&
              row[props.main_end_date]) ||
            (props.main_start_time &&
              row[props.main_start_time] &&
              props.main_end_time &&
              row[props.main_end_time])
          "
          v-slot:itemDates>
          <refonte-date
            v-if="props.main_date && row[props.main_date]"
            v-bind:dates="[new Date(row[props.main_date])]" />
          <refonte-date
            v-if="props.main_dates && row[props.main_dates]"
            v-bind:dates="row[props.main_dates]" />
          <refonte-date-range
            v-if="
              props.main_start_date &&
              row[props.main_start_date] &&
              props.main_end_date &&
              row[props.main_end_date]
            "
            v-bind:start_date="row[props.main_start_date]"
            v-bind:end_date="row[props.main_end_date]" />
          <refonte-time
            v-if="
              props.main_start_time &&
              row[props.main_start_time] &&
              props.main_end_time &&
              row[props.main_end_time]
            "
            v-bind:start_time="row[props.main_start_time]"
            v-bind:end_time="row[props.main_end_time]" />
        </template>
        <span
          v-bind:title="
            row[props.main_data_index ? props.main_data_index : 'titre']
          ">
          {{ row[props.main_data_index ? props.main_data_index : 'titre'] }}
        </span>
        <div
          v-if="props.main_sub_text && props.main_sub_text(row)"
          class="refonte-table-item-sub-text">
          {{ props.main_sub_text(row) }}
        </div>
        <div
          v-if="props.main_cursus && props.main_cursus(row)"
          class="refonte-table-item-sub-text !text-refonte-cursus">
          Cursus :
          <router-link
            v-if="props.main_cursus_on_click && props.main_cursus_on_click(row)"
            v-bind:to="{ path: props.main_cursus_on_click(row) }"
            class="!text-refonte-black hover:underline">
            {{ props.main_cursus(row) }}
          </router-link>
          <span
            v-else
            class="!text-refonte-black">
            {{ props.main_cursus(row) }}
          </span>
        </div>
      </refonte-table-main-item>

      <refonte-table-item
        v-for="(column, colIndex) in columns"
        v-bind:key="colIndex"
        v-bind:no_label="!!column.content_button_icon"
        v-bind:label="column.label"
        v-bind:label_icon="column.label_icon"
        v-bind:size="column.size"
        v-bind:width="column.width"
        v-bind:tooltip="column.tooltip"
        v-bind:content_icon="column.content_icon"
        v-bind:on_click="
          column.on_click &&
          (column.on_click_disabled === undefined ||
            (column.on_click_disabled && !column.on_click_disabled(row)))
            ? () => column.on_click && column.on_click(rowIndex)
            : undefined
        "
        v-bind:pending="
          column.is_pending ? column.is_pending(row) : !row[column.data_index]
        "
        v-bind:icon="column.data_icon ? column.data_icon(row) : undefined"
        v-bind:class="column.class ? column.class(row) : undefined">
        <template v-slot:content>
          <div v-if="column.content_custom">
            <component v-bind:is="column.content_custom(row)" />
          </div>
          <div
            v-else-if="
              column.content_button_icon &&
              column.content_button_icon(row) !== undefined
            "
            class="refonte-table-item-button">
            <refonte-button
              size="sm"
              v-bind:variant="
                column.content_button_variant
                  ? column.content_button_variant(row)
                  : 'primary'
              "
              v-bind:title="
                column.content_button_title && column.content_button_title(row)
              "
              v-bind:on_click="
                column.content_button_on_click
                  ? () => column.content_button_on_click(row)
                  : undefined
              "
              v-bind:disabled="
                column.content_button_disabled &&
                column.content_button_disabled(row)
              ">
              <component v-bind:is="column.content_button_icon(row)" />
            </refonte-button>
          </div>
          <span
            v-else-if="
              column.content_button_icon &&
              column.content_button_icon(row) === undefined
            "></span>
          <span
            v-else
            v-bind:title="
              row[column.data_index] ? row[column.data_index] : '-'
            ">
            {{ row[column.data_index] ? row[column.data_index] : '-' }}
          </span>
          <div
            v-if="column.content_sub_text && column.content_sub_text(row)"
            class="refonte-table-item-sub-text">
            {{ column.content_sub_text(row) }}
          </div>
        </template>
      </refonte-table-item>
    </refonte-table-row>
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTableMainItem from '@/components/refonte/tables/refonte-table-main-item.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteTableRow from '@/components/refonte/tables/refonte-table-row.vue';
  import RefonteTableItem from '@/components/refonte/tables/refonte-table-item.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteDate from '@/components/refonte/dates/refonte-date.vue';
  import RefonteDateRange from '@/components/refonte/dates/refonte-date-range.vue';
  import RefonteTime from '@/components/refonte/dates/refonte-time.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';

  export interface Column {
    data_index: string;
    data_icon?: (row: any) => Element;
    content_icon?: Element;
    content_custom?: (row: any) => Element;
    content_button_icon?: (row: any) => Element | undefined;
    content_button_on_click?: (row: any) => void;
    content_button_variant?: (row: any) => string;
    content_button_title?: (row: any) => string;
    content_button_disabled?: (row: any) => boolean;
    content_sub_text?: (row: any) => string;
    data_placeholder?: (row: any) => string;
    no_label?: boolean;
    label?: string;
    label_icon?: Element;
    size?: string;
    on_click?: (rowIndex: number) => void;
    on_click_disabled?: (row: any) => boolean;
    is_pending?: (row: any) => boolean;
    width: number;
    tooltip?: string;
    class?: (row: any) => string;
  }

  const props = withDefaults(
    defineProps<{
      table_index: string;
      columns: Column[];
      data: Object[];
      selectable?: boolean;
      select_row?: (row: any, value: boolean) => void;
      get_main_label?: (rowIndex: number) => string;
      main_data_etablissement?: string;
      main_data_etablissement_siret?: string;
      main_data_etablissement_width?: number;
      main_data_etablissement_icon?: (row: any) => Element;
      main_data_etablissement_status?: (row: any) => string | undefined;
      main_status?: boolean;
      main_tags_count?: string;
      main_tags_type?: string;
      main_tags_type_label?: string;
      main_date?: string;
      main_dates?: string;
      main_start_date?: string;
      main_end_date?: string;
      main_start_time?: string;
      main_end_time?: string;
      main_sub_text?: (row: any) => string;
      main_cursus?: (row: any) => string;
      main_cursus_on_click?: (row: any) => string;
      main_data_index?: string;
      main_width?: number;
      table_small?: boolean;
      on_details?: (row: any) => void;
      on_details_disabled?: (row: any) => boolean;
      on_details_variant?: string;
      on_details_label?: (row: any) => string;
      class?: string;
    }>(),
    {
      selectable: false,
      table_small: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-table {
    @apply flex flex-col overflow-auto;
    gap: 2px;
    padding-bottom: 12px;
  }
  .refonte-table-item-button {
    @apply flex items-end;
    min-height: 42px;

    :deep(.refonte-btn.btn-sm) {
      padding: 5px;

      svg {
        min-width: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
  .refonte-table-item-sub-text {
    @apply overflow-hidden text-ellipsis whitespace-normal text-refonte-black-40;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
  .refonte-table-item-etablissement {
    :deep(.table-item-label-icon) {
      width: auto !important;
      height: auto !important;
      margin-right: 4px;

      svg {
        width: auto !important;
        height: auto !important;
      }
    }
  }
</style>
