<template>
  <template v-if="!loading">
    <refonte-card>
      <commandes-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <formations-number
          v-bind:number="commandes.length"
          v-bind:label="commandes.length > 1 ? 'Commandes' : 'Commande'" />
      </div>
      <refonte-separator class="px-[12px]" />
      <commandes-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import CommandesContent from '@/views/_espace/_refonte/gestion/_commandes/commandes-content.vue';
  import {
    COMMANDES_FILTERS_CONFIGURATION,
    useCommandeFilterStore,
    useCommandeStore
  } from '@/stores/commandes';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import CommandesFilters from '@/views/_espace/_refonte/gestion/_commandes/commandes-filters.vue';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const commandeFilterStore = useCommandeFilterStore();

  const { filters } = storeToRefs(commandeFilterStore);

  const dataLoading = ref<boolean>(false);
  const commandeStore = useCommandeStore();
  const { commandes } = storeToRefs(commandeStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await commandeStore.fetchCommandes(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    commandeFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, [
      COMMANDES_FILTERS_CONFIGURATION.date.name,
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });
</script>

<style lang="scss" scoped></style>
