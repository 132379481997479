<template>
  <div
    class="refonte-table-item"
    v-bind:class="`table-item-${props.size} ${props.on_click ? 'table-item-clickable' : ''} ${props.pending ? 'table-item-pending' : ''} ${props.class}`"
    v-bind:style="props.width ? `width: ${props.width}px` : ''"
    v-on:click="onItemClick"
    v-bind:title="props.tooltip">
    <div
      v-if="!props.no_label"
      class="table-item-label">
      <div
        v-if="props.label_icon"
        class="table-item-label-icon">
        <component v-bind:is="props.label_icon" />
      </div>
      <span v-if="props.label">{{ props.label }}</span>
    </div>
    <div class="table-item-content">
      <div
        v-if="props.icon || props.number"
        class="table-item-content-left">
        <div
          v-if="props.icon"
          class="table-item-content-icon">
          <component v-bind:is="props.icon" />
        </div>
        <div
          v-if="props.number"
          class="table-item-content-number-container">
          <refonte-number class="table-item-content-number">
            {{ props.number }}
          </refonte-number>
        </div>
      </div>
      <div
        v-if="props.content_icon"
        class="table-item-content-left">
        <div
          v-if="props.content_icon"
          v-bind:class="
            props.pending
              ? 'table-item-content-icon'
              : 'table-item-content-data-icon'
          ">
          <component v-bind:is="props.content_icon" />
        </div>
      </div>
      <span class="table-item-content-text">
        <slot name="content"></slot>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      tooltip?: string;
      no_label?: boolean;
      label?: string;
      label_icon?: Element;
      size?: 'sm' | 'md';
      number?: string;
      pending?: boolean;
      on_click?: Function;
      width?: number;
      icon?: Element;
      content_icon?: Element;
      class?: string;
    }>(),
    {
      no_label: false,
      size: 'md',
      class: ''
    }
  );

  function onItemClick(e: Event) {
    if (props.on_click) {
      props.on_click();
      e.stopPropagation();
    }
  }
</script>

<style lang="scss" scoped>
  .refonte-table-item {
    @apply inline-flex flex-col items-start;
    gap: 2px;
    border-radius: 4px;

    &.table-item-sm {
      padding: 7px 4px;
    }
    &.table-item-md {
      padding: 7px 8px;
    }

    .table-item-label {
      @apply flex items-center uppercase text-refonte_blue-200;
      height: 20px;
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;

      .table-item-label-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
    }
    .table-item-content {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-content-left {
        @apply flex items-center;

        .table-item-content-icon {
          @apply flex shrink-0 items-center justify-center;
          width: 20px;
          height: 20px;

          :deep(svg) {
            @apply text-refonte_blue-200;
            width: 20px;
            height: 20px;
          }
        }
        .table-item-content-data-icon {
          @apply flex shrink-0 items-center justify-center;
          width: 20px;
          height: 20px;

          :deep(svg) {
            width: 20px;
            height: 20px;
          }
        }
        .table-item-content-number-container {
          @apply flex shrink-0 items-center justify-center;
          width: 20px;
          height: 20px;

          .table-item-content-number {
            :deep(span) {
              font-weight: 500;
            }
          }
        }
      }
      .table-item-content-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
    }

    &.table-item-clickable {
      @apply cursor-pointer bg-refonte-pale-violet-2;

      &:hover {
        @apply bg-refonte-pale-violet-3;

        .table-item-label {
          @apply text-refonte_blue-600;
        }
      }
    }
    &.table-item-pending {
      .table-item-content {
        .table-item-content-text {
          @apply text-refonte_blue-200;
        }
      }
      &.table-item-clickable {
        @apply cursor-default bg-transparent;

        &:hover {
          @apply bg-transparent;

          .table-item-label {
            @apply text-refonte_blue-200;
          }
        }
      }
    }
  }
</style>
