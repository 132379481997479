<template>
  <label
    class="refonte-radio"
    v-bind:class="`${props.disabled ? 'radio-disabled' : ''} ${props.class}`">
    <input
      type="radio"
      class="radio-input"
      v-bind:name="props.name"
      v-bind:value="props.value"
      v-bind:disabled="props.disabled"
      v-bind:checked="props.checked === props.value"
      v-on:change="updateValue(props.value)" />
    <span class="radio-background"></span>
    <span class="radio-checkmark"></span>
    <span class="radio-label">
      {{ props.label }}
    </span>
  </label>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      name: string;
      value: string;
      label: string;
      checked: string;
      disabled?: boolean;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const emit = defineEmits(['input']);

  function updateValue(value: string) {
    emit('input', value);
  }
</script>

<style lang="scss" scoped>
  .refonte-radio {
    @apply relative inline-flex cursor-pointer items-center self-stretch bg-refonte-pale-violet-2 text-refonte_blue-600;
    padding: 9px 10px 9px 23px;
    border-radius: 4px;
    gap: 6px;
    user-select: none;
    min-width: 120px;

    .radio-input {
      @apply absolute h-0 w-0 cursor-pointer opacity-0;
    }
    .radio-background {
      @apply absolute bottom-0 left-0 right-0 top-0;
      border-radius: 4px;
    }
    input:checked ~ .radio-background {
      @apply bg-refonte_blue-600;
    }
    .radio-checkmark {
      @apply absolute border border-refonte_blue-100 bg-refonte-white;
      top: 13px;
      left: 7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;

      &:after {
        @apply absolute bg-refonte_blue-600;
        content: '';
        display: none;
        left: 1px;
        top: 1px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    input:checked ~ .radio-checkmark {
      @apply border-refonte-white;

      &:after {
        display: block;
      }
    }
    .radio-label {
      @apply relative cursor-pointer overflow-hidden text-ellipsis text-refonte_blue-600;
      z-index: 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    input:checked ~ .radio-label {
      @apply text-refonte-white;
    }

    &:hover {
      .radio-background {
        @apply bg-refonte-pale-violet-3;
      }
      input:checked ~ .radio-background {
        @apply bg-refonte_blue-600;
      }
    }
    &.radio-disabled {
      @apply cursor-not-allowed;
      opacity: 0.3;

      &:hover {
        .radio-background {
          @apply bg-refonte-pale-violet-2;
        }
        .radio-label {
          @apply cursor-not-allowed;
        }
      }
    }
  }
</style>
