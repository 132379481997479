<template>
  <div
    class="refonte-side-menu-overlay"
    v-bind:class="{
      'side-menu-overlay--open': props.is_mobile_side_menu_open
    }"
    v-on:click="props.toggle_is_mobile_side_menu_open()" />
  <aside
    class="refonte-side-menu"
    v-bind:class="`${props.is_side_menu_reduced ? 'side-menu--reduced' : ''} ${props.is_mobile_side_menu_open ? 'side-menu-mobile--open' : ''} ${props.class}`">
    <div class="side-menu-logo">
      <img
        class="side-menu-logo-img"
        src="@/assets/img/refonte/logos/jinius.svg"
        alt="Logo Jinius" />
      <img
        class="side-menu-logo-img-reduced"
        src="@/assets/img/refonte/logos/jinius-reduced.svg"
        alt="Logo Jinius" />
      <IconClose
        class="side-menu-close"
        v-on:click="props.toggle_is_mobile_side_menu_open()" />
    </div>
    <div
      class="side-menu-espace-container"
      v-bind:class="{
        'side-menu-espace-container-with-espaces': espaces && espaces.length > 0
      }">
      <div class="side-menu-espace-title">
        {{ `Espace${espaces.length > 0 ? 's' : ''}` }}
      </div>
      <refonte-dropdown
        v-if="espaces && espaces.length > 0"
        class="self-stretch">
        <div class="side-menu-espace">
          <div class="side-menu-espace-button">
            <IconEspace />
          </div>
          <div class="side-menu-espace-dropdown">
            <span>{{ arborescence[selectedEspace].label }}</span>
            <IconChevronDown />
          </div>
        </div>
        <template v-slot:dropdownContent>
          <refonte-side-menu-espace-item
            v-bind:on_click="() => navigateToOldEntrepriseSpace()"
            pastille="entreprise"
            label="Entreprise (Ancien)" />
          <refonte-side-menu-espace-item
            v-for="(espace, index) in espaces.filter(
              (e) => e !== selectedEspace
            )"
            v-bind:key="index"
            v-bind:on_click="() => navigateToSpace(espace)"
            v-bind:label="arborescence[espace].label" />
        </template>
      </refonte-dropdown>
      <div
        v-else
        class="side-menu-espace">
        <div class="side-menu-espace-button">
          <IconEspace />
        </div>
        <div class="side-menu-espace-dropdown">
          <span>{{ arborescence[selectedEspace].label }}</span>
          <IconChevronDown />
        </div>
      </div>
    </div>
    <div class="side-menu-catalogue">
      <refonte-dropdown
        v-bind:on_search="(value) => console.log(value)"
        class="self-stretch">
        <side-menu-link label="Catalogues">
          <IconCatalogue />
        </side-menu-link>
        <template v-slot:dropdownContent>
          <refonte-side-menu-catalogue-item
            v-for="catalogue in catalogues"
            v-bind:key="catalogue.id"
            v-bind:src="getCatalogueLogo(catalogue.code)"
            v-bind:on_click="() => openCatalogue(catalogue.catalogueUrl)"
            v-bind:name="catalogue.nom" />
        </template>
      </refonte-dropdown>
    </div>
    <div class="side-menu-links">
      <div class="side-menu-links-top">
        <div class="side-menu-links-categories">
          <template
            v-bind:key="rubrique"
            v-for="rubrique in Object.keys(
              arborescence[selectedEspace].rubriques
            )">
            <side-menu-category
              v-if="
                !arborescence[selectedEspace].rubriques[rubrique]
                  .need_admin_rights ||
                (arborescence[selectedEspace].rubriques[rubrique]
                  .need_admin_rights &&
                  hasAdminRights)
              "
              v-bind:title="
                arborescence[selectedEspace].rubriques[rubrique].label
              "
              v-bind:reduced_title="
                arborescence[selectedEspace].rubriques[rubrique].reduced_label
              ">
              <template
                v-bind:key="sousRubrique"
                v-for="sousRubrique in Object.keys(
                  arborescence[selectedEspace].rubriques[rubrique]
                    .sous_rubriques
                )">
                <side-menu-link
                  v-if="
                    !arborescence[selectedEspace].rubriques[rubrique]
                      .sous_rubriques[sousRubrique].menuHidden
                  "
                  v-bind:url="`/refonte/${selectedEspace}/${sousRubrique}`"
                  v-bind:label="
                    arborescence[selectedEspace].rubriques[rubrique]
                      .sous_rubriques[sousRubrique].label
                  "
                  v-bind:active="
                    route.path.startsWith(
                      `/refonte/${selectedEspace}/${sousRubrique}`
                    )
                  ">
                  <component
                    v-bind:is="
                      ICONS[
                        arborescence[selectedEspace].rubriques[rubrique]
                          .sous_rubriques[sousRubrique].icon
                      ]
                    " />
                </side-menu-link>
              </template>
            </side-menu-category>
          </template>
        </div>
      </div>
      <div class="side-menu-links-bottom">
        <side-menu-link
          label="Me déconnecter"
          v-bind:url="logoutFromOidcProvider"
          v-bind:replace="true">
          <IconLogout />
        </side-menu-link>
        <side-menu-link
          label="Aide et Support"
          url="/aide-en-ligne">
          <IconHelp />
        </side-menu-link>
        <div class="side-menu-version">{{ version }}</div>
      </div>
    </div>
    <div
      class="side-menu-reduce"
      v-on:click="props.toggle_is_side_menu_reduced">
      <div class="side-menu-reduce-icon">
        <IconExpand v-if="props.is_side_menu_reduced" />
        <IconReduce v-else />
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
  import IconCatalogue from '@/assets/img/refonte/icons/menu/icon-catalogue.svg?component';
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import IconEspace from '@/assets/img/refonte/icons/profil/icon-espace.svg?component';
  import IconExpand from '@/assets/img/refonte/icons/display/icon-expand.svg?component';
  import IconHelp from '@/assets/img/refonte/icons/information/icon-help.svg?component';
  import IconLogout from '@/assets/img/refonte/icons/action/icon-logout.svg?component';
  import IconReduce from '@/assets/img/refonte/icons/display/icon-reduce.svg?component';
  import sideMenuCategory from '@/components/refonte/side-menu/refonte-side-menu-category.vue';
  import sideMenuLink from '@/components/refonte/side-menu/refonte-side-menu-link.vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteSideMenuCatalogueItem from '@/components/refonte/side-menu/refonte-side-menu-catalogue-item.vue';
  import RefonteSideMenuEspaceItem from '@/components/refonte/side-menu/refonte-side-menu-espace-item.vue';
  import { computed, ComputedRef, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { ICONS } from '@/store/constants/Icons';
  import { useArborescenceStore } from '@/stores/arborescence';
  import { storeToRefs } from 'pinia';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import { Entreprise } from '@/types/gestion/entreprise';
  import { useCatalogueStore } from '@/stores/catalogue';
  import { map_domain_per_env } from '@/assets/data/data_maps';
  const props = withDefaults(
    defineProps<{
      is_mobile_side_menu_open: boolean;
      is_side_menu_reduced: boolean;
      toggle_is_mobile_side_menu_open: Function;
      toggle_is_side_menu_reduced: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
  const getCatalogueLogo = (code: string) => {
    const cdn = `https://cdn.${map_domain_per_env[import.meta.env.VITE_CONTEXT]}`;
    return `${cdn}/logos/${code}.png`;
  };
  const openCatalogue = (url: string) => {
    window.open(url);
  };

  const arborescenceStore = useArborescenceStore();
  const { arborescence } = storeToRefs(arborescenceStore);
  const catalogueStore = useCatalogueStore();
  const { catalogues } = storeToRefs(catalogueStore);
  const espaces = Object.keys(arborescence.value);
  const route = useRoute();
  const router = useRouter();
  const selectedEspace = ref(route.params.space_id);
  const version = computed(() => `Version ${import.meta.env.VITE_VERSION}`);
  const logoutFromOidcProvider = computed(
    () =>
      `${import.meta.env.VITE_API_AUTH}/logout?to=${import.meta.env.VITE_AUTH_REDIRECT}`
  );
  function navigateToSpace(espace: string) {
    router.push(`/refonte/${espace}/accueil`);
  }
  function navigateToOldEntrepriseSpace() {
    router.push(`/entreprise`);
  }

  const entrepriseStore = useEntrepriseStore();

  const { entreprises, activeEntrepriseId } = storeToRefs(entrepriseStore);

  const selectedEntity: ComputedRef<Entreprise> = computed(() => {
    const index = entreprises.value.findIndex(
      (e: Entreprise) => e.entrepriseId == activeEntrepriseId.value
    );
    return index != -1 ? entreprises.value[index] : entreprises.value[0];
  });

  const hasAdminRights: ComputedRef<boolean> = computed(() => {
    if (activeEntrepriseId.value !== ALL_ENTREPRISE_FILTER) {
      return selectedEntity.value.droitAdministrateur;
    } else {
      return (
        entreprises.value.filter((e: Entreprise) => e.droitAdministrateur)
          .length > 0
      );
    }
  });
</script>

<style lang="scss" scoped>
  s .refonte-side-menu-overlay {
    @apply hidden;

    @media (max-width: 767.98px) {
      &.side-menu-overlay--open {
        @apply absolute bottom-0 left-0 right-0 top-0 block bg-refonte-white;
        z-index: 9;
      }
    }
  }
  .refonte-side-menu {
    @apply relative flex shrink-0 flex-col items-start self-stretch bg-refonte-menu text-refonte-white;
    width: 208px;
    transition:
      width 0.2s,
      left 0.2s;
    z-index: 10;

    .side-menu-logo {
      @apply flex items-center self-stretch;
      height: 72px;
      padding: 16px 24px;
      transition: padding 0.2s;

      .side-menu-logo-img {
        width: 85px;
        height: 34px;
      }
      .side-menu-logo-img-reduced {
        @apply hidden;
      }
      .side-menu-close {
        @apply hidden;
      }
    }
    .side-menu-espace-container {
      @apply flex flex-col items-start self-stretch bg-refonte-black-40;
      padding: 16px;
      transition: padding 0.2s;

      .side-menu-espace-title {
        @apply flex items-center self-stretch whitespace-nowrap text-refonte-white-30;
        height: 20px;
        gap: 10px;
        padding: 0 11px 4px;
        font-size: 12px;
        line-height: 120%;
        font-weight: 500;
      }
      .side-menu-espace {
        @apply flex items-center self-stretch bg-refonte-menu-30;
        height: 44px;
        border-radius: 4px;

        .side-menu-espace-button {
          @apply flex shrink-0 items-center justify-center self-stretch bg-refonte-menu-30;
          width: 44px;
          height: 44px;
          padding: 10px;
          border-radius: 4px 0 0 4px;
        }
        .side-menu-espace-dropdown {
          @apply flex flex-grow items-center justify-between self-stretch;
          height: 44px;
          padding: 0 10px;

          :deep(svg) {
            @apply hidden;
          }
        }
      }
      &.side-menu-espace-container-with-espaces {
        .side-menu-espace {
          @apply cursor-pointer;

          &:hover {
            @apply bg-refonte-menu-40;
          }

          .side-menu-espace-dropdown {
            :deep(svg) {
              @apply inline-block text-refonte-white;
              width: 16px;
            }
          }
        }
      }
    }
    .side-menu-catalogue {
      @apply self-stretch;
      padding: 24px 16px 8px;
    }
    .side-menu-links {
      @apply flex flex-grow flex-col items-start justify-between self-stretch overflow-y-auto overflow-x-hidden;
      gap: 16px;
      padding: 8px 16px 24px;
      max-height: calc(100vh - 72px - 76px - 72px);
      transition: padding 0.2s;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .side-menu-links-top {
        @apply flex flex-col items-start self-stretch;
        gap: 16px;

        .side-menu-links-categories {
          @apply flex flex-col items-start self-stretch;
          gap: 24px;
        }
      }
      .side-menu-links-bottom {
        @apply flex flex-col items-start self-stretch;

        .side-menu-version {
          @apply flex items-center justify-center self-stretch text-refonte-white-30;
          height: 20px;
          gap: 10px;
          padding: 0 11px;
          font-size: 12px;
          line-height: 120%;
          font-weight: 500;
        }
      }
    }
    .side-menu-reduce {
      @apply hidden;
    }

    @media (min-width: 768px) {
      &.side-menu--reduced {
        width: 64px;

        .side-menu-logo {
          padding: 16px 12px;

          .side-menu-logo-img {
            @apply hidden;
          }
          .side-menu-logo-img-reduced {
            @apply block;
            width: 40px;
            height: 40px;
          }
        }
        .side-menu-espace-container {
          padding: 16px 10px;

          .side-menu-espace-title {
            @apply justify-center;
            padding: 0 0 4px;
          }
          .side-menu-espace {
            .side-menu-espace-button {
              border-radius: 4px;
            }
            .side-menu-espace-dropdown {
              @apply hidden;
            }
          }
        }
        .side-menu-catalogue {
          padding: 24px 8px 8px;
        }
        .side-menu-links {
          padding: 8px 8px 24px;

          .side-menu-links-bottom {
            .side-menu-version {
              @apply hidden;
            }
          }
        }
      }
      .side-menu-reduce {
        @apply absolute flex cursor-pointer items-center justify-center bg-refonte-menu;
        width: 24px;
        height: 32px;
        right: -12px;
        top: 20px;
        border-radius: 4px;
        box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.25);

        .side-menu-reduce-icon {
          @apply flex shrink-0 items-center justify-center;
          width: 20px;
          height: 20px;

          :deep(svg) {
            @apply text-refonte-white-30;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    @media (max-width: 767.98px) {
      @apply absolute bottom-0 top-0;
      width: 272px;
      left: -272px;

      &.side-menu-mobile--open {
        @apply left-0;
      }

      .side-menu-logo {
        @apply justify-between;
        height: 56px;
        padding: 0 16px 0 32px;

        .side-menu-close {
          @apply block cursor-pointer text-refonte-white;
          height: 12px;
          width: 12px;
        }
      }
      .side-menu-espace-container {
        padding: 6px 8px;

        .side-menu-espace {
          @apply flex items-center self-stretch bg-refonte-menu-30;
          height: 44px;
          border-radius: 4px;
        }
      }
      .side-menu-catalogue {
        padding: 8px 16px 4px;
      }
      .side-menu-links {
        padding: 4px 16px 8px;
        max-height: calc(100vh - 56px - 56px - 48px);

        .side-menu-links-top {
          gap: 8px;

          .side-menu-links-categories {
            gap: 12px;
          }
        }
      }
    }
    :deep(.refonte-dropdown) {
      .dropdown-container {
        top: calc(100% + 6px);
        box-shadow: 0 3px 9px 0 rgba(45, 50, 65, 0.2);
      }
    }
  }
</style>
