<template>
  <div class="p-[8px]">
    <template v-if="factures.length">
      <refonte-table
        v-bind:select_row="(row, value: boolean) => selectRow(row.id, value)"
        key="factures-1"
        table_index="factures-1"
        v-bind:selectable="true"
        main_data_index="reference"
        v-bind:get_main_label="(rowIndex: number) => 'Référence'"
        v-bind:main_width="120"
        v-bind:main_data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:main_data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:columns="columns"
        v-bind:data="factures" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useFactureStore } from '@/stores/factures';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';

  const selectRow = (id: string, value: boolean) => {
    factureStore.selectFactureItem(id, value);
  };

  const columns = [
    {
      data_index: 'type',
      label: 'Type',
      width: 140
    },
    {
      data_index: 'date',
      label: 'Emise le',
      width: 100
    },
    {
      data_index: 'montantTtc',
      label: 'Montant TTC',
      width: 120
    },
    {
      data_index: 'montantRegle',
      label: 'Montant Réglé',
      width: 120
    },
    {
      data_index: 'organisateur',
      label: 'Organisme de formation',
      width: 170
    },
    {
      data_index: 'statutLabel',
      label: 'Statut',
      width: 110
    },
    {
      data_index: 'commandes',
      label: 'Commandes',
      width: 170
    },
    {
      data_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (rowIndex: number) => {},
      data_icon: () => IconDownload
    }
  ];

  const factureStore = useFactureStore();

  const { factures } = storeToRefs(factureStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
