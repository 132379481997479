import { IdLabel } from '@/types/gestion/entreprise';

export type Formation = {
    id: string;
    reference: string;
    commandeId: number;
    clientId: number;
    clientDenomination: string;
    clientSiret?: string;
    typeShortcut: string;
    typeLabel: string;
    statutLabel: string;
    modeLabel?: string;
    titre: string;
    startTime?: string;
    endTime?: string;
    selected?: boolean;
    convention?: Document;
    pif?: Document;
    conventionStatut?: string;
    pifStatut?: string;
    datesFormations: string;
    certificatId?: number;
    certificatLabel?: string;
    titreAlt?: string;
    produit: Produit;
    parcours?: Produit;
    cursus?: Produit;
    participantNomPrenom: string;
    lieu: string;
    testPositionnementStatut?: string;
};

export type FormationResponse = {
    id: string;
    dateDebut: string;
    dateFin: string;
    produit: Produit;
    parcours?: Produit;
    cursus?: Produit;
    participant: Participant;
    statut: IdLabel;
    type: IdLabel;
    mode?: IdLabel;
    lieu: string;
    organisateur: string;
    reference: string;
    commandeId: number;
    client: {
        id: number;
        denomination: string;
        siret?: string;
    };
    convention?: Document;
    pif?: Document;
    certificatId?: number;
    familleStageEC?: boolean;
    familleStageCAC?: boolean;
    testPositionnement?: {
        disponible?: boolean;
        realise?: boolean;
    };
};

export type Participant = {
    id: number;
    nomPrenom: string;
};

export type Produit = {
    id: number;
    titre: string;
};

export type Document = {
    id: number;
    disponible: boolean;
    signee: boolean;
    validee: boolean;
};

export const typeMapping: { [key: number]: string } = {
    1: 'presentiel',
    2: 'e-learning',
    3: 'webinar',
    5: 'classe-virtuelle',
    6: 'conference',
    8: 'cursus'
};
