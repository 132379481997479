<template>
  <div
    class="refonte-table-main-item"
    v-bind:class="props.class"
    v-bind:style="props.width ? `width: ${props.width}px` : ''">
    <div class="table-main-item-label">
      <refonte-pastille
        v-if="props.status"
        v-bind:status="props.status" />
      <slot
        v-if="!!slots['itemTags']"
        name="itemTags"></slot>
      <div
        v-if="props.label"
        class="table-main-item-label-text">
        {{ props.label }}
      </div>
      <slot
        v-if="!!slots['itemDates']"
        name="itemDates"></slot>
      <div class="flex flex-grow items-center justify-end">
        <refonte-button
          v-if="props.button_on_click"
          variant="tag"
          v-bind:on_click="props.button_on_click"
          v-bind:icon_left="IconSend">
        </refonte-button>
      </div>
    </div>
    <div class="table-main-item-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconSend from '@/assets/img/refonte/icons/action/icon-send.svg?component';
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      label?: string;
      status?: string;
      tag_variant?: string;
      tag_label?: string;
      button_on_click?: Function;
      width?: number;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-table-main-item {
    @apply flex flex-col items-start;
    gap: 4px;
    border-radius: 4px;
    padding: 7px 4px;
    max-width: 344px;

    .table-main-item-label {
      @apply flex items-center justify-start self-stretch overflow-hidden;
      gap: 4px;

      .table-main-item-label-text {
        @apply flex items-center uppercase text-refonte_blue-200;
        height: 20px;
        gap: 2px;
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .table-main-item-content {
      @apply self-stretch text-refonte-black;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
</style>
