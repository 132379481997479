<template>
  <div class="p-[8px]">
    <template v-if="satisfaction.length">
      <refonte-table
        v-bind:on_details="answerSatisfaction"
        v-bind:on_details_disabled="(row: Satisfaction) => row.repondu"
        on_details_variant="success"
        v-bind:on_details_label="
          (row: Satisfaction) => (row.repondu ? 'Répondu' : 'Répondre')
        "
        key="satisfaction-1"
        table_index="satisfaction-1"
        main_data_index="dateQuestionnaire"
        v-bind:get_main_label="(rowIndex: number) => 'Date questionnaire'"
        v-bind:main_width="140"
        v-bind:main_data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:main_data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:main_data_etablissement_width="350"
        v-bind:columns="columns"
        v-bind:data="satisfaction" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useSatisfactionStore } from '@/stores/satisfaction';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Satisfaction } from '@/types/gestion/satisfaction-types';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';

  const answerSatisfaction = (row: Satisfaction) => {};

  const columns = [
    {
      data_index: 'organismeNom',
      label: 'Organisme de formation',
      width: 175
    },
    {
      data_index: 'formation',
      label: 'Formation',
      width: 350
    },
    {
      data_index: 'dateReponse',
      label: 'Répondu le',
      width: 110
    },
    {
      data_index: 'reponduPar',
      label: 'Répondu par',
      width: 180
    }
  ];

  const satisfactionStore = useSatisfactionStore();

  const { satisfaction } = storeToRefs(satisfactionStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
