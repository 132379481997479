<template>
  <div
    class="refonte-pastille-icon"
    v-bind:class="props.class">
    <IconPastilleActive v-if="props.status === 'active'" />
    <IconPastilleWarning v-else-if="props.status === 'warning'" />
    <IconPastilleCanceled v-else-if="props.status === 'canceled'" />
    <IconPastilleUrgent v-else-if="props.status === 'urgent'" />
    <IconPastilleDeactivated v-else-if="props.status === 'deactivated'" />
    <IconPastillePending v-else-if="props.status === 'pending'" />
    <IconPastilleRealised v-else-if="props.status === 'realised'" />
    <IconPastilleEntreprise v-else-if="props.status === 'entreprise'" />
    <IconPastilleParticipants v-else-if="props.status === 'participants'" />
    <IconPastilleFormateur v-else-if="props.status === 'formateur'" />
    <IconPastilleCursus v-else-if="props.status === 'cursus'" />
  </div>
</template>

<script setup lang="ts">
  import IconPastilleActive from '@/assets/img/refonte/icons/states/icon-pastille-active.svg?component';
  import IconPastilleWarning from '@/assets/img/refonte/icons/states/icon-pastille-warning.svg?component';
  import IconPastilleCanceled from '@/assets/img/refonte/icons/states/icon-pastille-canceled.svg?component';
  import IconPastilleUrgent from '@/assets/img/refonte/icons/states/icon-pastille-urgent.svg?component';
  import IconPastilleDeactivated from '@/assets/img/refonte/icons/states/icon-pastille-deactivated.svg?component';
  import IconPastillePending from '@/assets/img/refonte/icons/states/icon-pastille-pending.svg?component';
  import IconPastilleRealised from '@/assets/img/refonte/icons/states/icon-pastille-realised.svg?component';
  import IconPastilleEntreprise from '@/assets/img/refonte/icons/states/icon-pastille-entreprise.svg?component';
  import IconPastilleParticipants from '@/assets/img/refonte/icons/states/icon-pastille-participants.svg?component';
  import IconPastilleFormateur from '@/assets/img/refonte/icons/states/icon-pastille-formateur.svg?component';
  import IconPastilleCursus from '@/assets/img/refonte/icons/states/icon-pastille-cursus.svg?component';

  const props = withDefaults(
    defineProps<{
      status: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-pastille-icon {
    @apply flex shrink-0 items-center justify-center;
    width: 8px;
    height: 8px;
  }
</style>
