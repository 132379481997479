import { API } from '@/http-common.js';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const ALL_ENTREPRISE_FILTER = -1;

export const useEntrepriseStore = defineStore('entreprise', () => {
    const entreprises = ref([]);

    const activeEntrepriseId = ref(ALL_ENTREPRISE_FILTER);

    function setActiveEntrepriseId(id) {
        activeEntrepriseId.value = id;
    }

    const profile = ref();

    const getProfile = async () => {
        const response = await API.get('client/v2/profile');
        if (response?.data) {
            profile.value = response?.data;
            entreprises.value = response.data.entreprises;
        }
    };

    const activeClientIds = computed(() =>
        activeEntrepriseId.value !== ALL_ENTREPRISE_FILTER
            ? activeEntrepriseId.value.toString()
            : ''
    );

    const initActiveEntrepriseIdFromQuery = (clientIds) => {
        if (clientIds) {
            activeEntrepriseId.value = clientIds;
        }
    };

    return {
        initActiveEntrepriseIdFromQuery,
        activeClientIds,
        entreprises,
        activeEntrepriseId,
        setActiveEntrepriseId,
        profile,
        getProfile
    };
});
