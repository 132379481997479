<template>
  <div
    class="refonte-textarea"
    v-bind:class="`${props.error ? 'textarea-error' : ''} ${props.class}`">
    <textarea
      v-model="inputValue"
      v-on:input="updateValue"
      v-bind:placeholder="props.placeholder"
      v-bind:disabled="props.disabled"
      v-bind:maxlength="props.maxlength"
      v-bind:rows="props.rows"
      v-bind:class="props.input_class" />
    <label v-bind:class="{ 'textarea-with-value': inputValue !== '' }">
      {{ props.placeholder }}<span v-if="props.required"> *</span>
    </label>
    <div class="textarea-count">
      Caractères restants : {{ props.maxlength - inputValue.length }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      value?: string;
      placeholder: string;
      required?: boolean;
      disabled?: boolean;
      error?: boolean;
      maxlength?: number;
      rows?: number;
      class?: string;
      input_class?: string;
    }>(),
    {
      type: 'text',
      value: '',
      required: false,
      disabled: false,
      error: false,
      maxlength: 500,
      rows: 4,
      class: '',
      input_class: ''
    }
  );

  const inputValue = ref<string>(props.value);

  const emit = defineEmits(['input']);

  function updateValue() {
    emit('input', inputValue.value);
  }
</script>

<style lang="scss" scoped>
  .refonte-textarea {
    @apply relative;
    padding-bottom: 16px;

    &:after {
      @apply absolute bg-refonte-white;
      content: '';
      top: 1px;
      height: 17px;
      left: 1px;
      right: 16px;
      border-top-left-radius: 4px;
      z-index: 1;
      pointer-events: none;
    }
    textarea {
      @apply flex flex-grow items-center self-stretch border border-refonte_blue-100 bg-refonte-white text-refonte-black;
      padding: 17px 7px 4px;
      border-radius: 4px;
      gap: 6px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;

      &::placeholder {
        @apply text-refonte-white;
      }
      &:focus-visible {
        outline: none;
      }
      &:hover {
        @apply border-refonte_blue-600;
      }
      &:focus {
        @apply border-refonte_blue-800;
      }
      &[disabled] {
        @apply cursor-not-allowed border-refonte_blue-150 text-refonte_blue-200;
      }
    }
    label {
      @apply absolute text-refonte_blue-300;
      left: 8px;
      top: 14px;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      z-index: 2;
    }
    label.textarea-with-value,
    textarea:focus + label {
      transform: translateY(-9px);
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
    }

    &.textarea-error {
      textarea {
        @apply border-jinius-red text-jinius-red #{!important};
      }
      label {
        @apply text-jinius-red #{!important};
      }
    }
    .textarea-count {
      @apply absolute text-refonte_blue-300;
      bottom: 2px;
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
    }
  }
</style>
