export const extra_palettes = {
    'jinius-green': '#64B389',
    'jinius-dark-green': '#167C80',
    'jinius-blue': '#32537A',
    'jinius-red': '#EA444C',
    'jinius-purple': '#7030A0',
    'jinius-rose': '#3730a3',
    'jinius-yellow': '#FFC000',
    'jinius-pink': '#DB2777',
    'refonte-menu': '#31537A',
    'refonte-menu-30': 'rgba(49,83,122,.3)',
    'refonte-menu-40': 'rgba(49,83,122,.4)',
    'refonte-menu-60': 'rgba(49,83,122,.6)',
    'refonte-black': '#161616',
    'refonte-black-30': 'rgba(22,22,22,.3)',
    'refonte-black-40': 'rgba(22,22,22,.4)',
    'refonte-black-60': 'rgba(22,22,22,.6)',
    'refonte-white': '#FFFFFF',
    'refonte-white-10': 'rgba(255,255,255,.1)',
    'refonte-white-30': 'rgba(255,255,255,.3)',
    'refonte-white-60': 'rgba(255,255,255,.6)',
    'refonte-background-0': '#FAFAFC',
    'refonte-background-1': '#F7F8FA',
    'refonte-background-2': '#F5F5F7',
    'refonte-pale-violet-1': '#F3F4FC',
    'refonte-pale-violet-2': '#F7F8FD',
    'refonte-pale-violet-3': '#EFF1FB',
    'refonte-presentiel': '#76D6A3',
    'refonte-conference': '#7D63E3',
    'refonte-e-learning': '#639FE3',
    'refonte-webinar': '#58B3C9',
    'refonte-classe-virtuelle': '#6BD9F5',
    'refonte-cursus': '#C9688F',
    refonte_blue: {
        75: '#EBEFFF',
        100: '#D7DEF7',
        150: '#DEE5FF',
        200: '#B6C3F0',
        300: '#ADBFFF',
        400: '#A3AED6',
        600: '#869DF0',
        800: '#5678F0',
        900: '#445FBD'
    },
    core: {
        100: '#f7f7f7',
        200: '#f2f2f2',
        300: '#e8e9e9',
        400: '#cbd0d1',
        500: '#79878c',
        600: '#3d525a',
        700: '#1f3841',
        800: '#102b34',
        900: '#001d27'
    },
    custom_red: {
        100: '#FACDCD',
        200: '#F29B9B',
        300: '#E66A6A',
        400: '#D64545',
        500: '#BA2525',
        600: '#A61B1B',
        700: '#911111',
        800: '#780A0A',
        900: '#610404'
    },
    custom_lightRed: {
        100: '#FADFDE',
        200: '#F5BCB8',
        300: '#F09C96',
        400: '#EB7F77',
        500: '#EC9794',
        600: '#E77E7A',
        700: '#E36762',
        800: '#DF524C',
        900: '#DB3E38'
    },
    custom_redVivid: {
        100: '#FFBDBD',
        200: '#FF9B9B',
        300: '#F86A6A',
        400: '#EF4E4E',
        500: '#E12D39',
        600: '#CF1124',
        700: '#AB091E',
        800: '#8A041A',
        900: '#610316'
    },
    custom_orange: {
        100: '#FFD3BA',
        200: '#FAB38B',
        300: '#EF8E58',
        400: '#E67635',
        500: '#C65D21',
        600: '#AB4E19',
        700: '#8C3D10',
        800: '#77340D',
        900: '#572508'
    },
    custom_orangeVivid: {
        100: '#FFD0B5',
        200: '#FFB088',
        300: '#FF9466',
        400: '#F9703E',
        500: '#F35627',
        600: '#DE3A11',
        700: '#C52707',
        800: '#AD1D07',
        900: '#841003'
    },
    custom_yellow: {
        100: '#FCEFC7',
        200: '#F8E3A3',
        300: '#F9DA8B',
        400: '#F7D070',
        500: '#E9B949',
        600: '#C99A2E',
        700: '#A27C1A',
        800: '#7C5E10',
        900: '#513C06'
    },
    custom_yellowVivid: {
        100: '#FFF3C4',
        200: '#FCE588',
        300: '#FADB5F',
        400: '#F7C948',
        500: '#F0B429',
        600: '#DE911D',
        700: '#CB6E17',
        800: '#B44D12',
        900: '#8D2B0B'
    },
    custom_limeGreen: {
        100: '#E2F7C2',
        200: '#C7EA8F',
        300: '#ABDB5E',
        400: '#94C843',
        500: '#7BB026',
        600: '#63921A',
        700: '#507712',
        800: '#42600C',
        900: '#2B4005'
    },
    custom_limeGreenVivid: {
        100: '#E6FFBF',
        200: '#CAFF84',
        300: '#AFF75C',
        400: '#8DED2D',
        500: '#6CD410',
        600: '#5CB70B',
        700: '#399709',
        800: '#2E7B06',
        900: '#1E5303'
    },
    custom_green: {
        100: '#C1EAC5',
        200: '#A3D9A5',
        300: '#7BC47F',
        400: '#57AE5B',
        500: '#3F9142',
        600: '#2F8132',
        700: '#207227',
        800: '#0E5814',
        900: '#05400A'
    },
    custom_greenVivid: {
        100: '#C1F2C7',
        200: '#91E697',
        300: '#51CA58',
        400: '#31B237',
        500: '#18981D',
        600: '#0F8613',
        700: '#0E7817',
        800: '#07600E',
        900: '#014807'
    },
    custom_teal: {
        100: '#C6F7E2',
        200: '#8EEDC7',
        300: '#65D6AD',
        400: '#3EBD93',
        500: '#27AB83',
        600: '#199473',
        700: '#147D64',
        800: '#0C6B58',
        900: '#014D40'
    },
    custom_tealVivid: {
        100: '#C6F7E9',
        200: '#8EEDD1',
        300: '#5FE3C0',
        400: '#2DCCA7',
        500: '#17B897',
        600: '#079A82',
        700: '#048271',
        800: '#016457',
        900: '#004440'
    },
    custom_cyan: {
        100: '#BEF8FD',
        200: '#87EAF2',
        300: '#54D1DB',
        400: '#38BEC9',
        500: '#2CB1BC',
        600: '#14919B',
        700: '#0E7C86',
        800: '#0A6C74',
        900: '#044E54'
    },
    custom_cyanVivid: {
        100: '#C1FEF6',
        200: '#92FDF2',
        300: '#62F4EB',
        400: '#3AE7E1',
        500: '#1CD4D4',
        600: '#0FB5BA',
        700: '#099AA4',
        800: '#07818F',
        900: '#05606E'
    },
    custom_lightBlue: {
        100: '#D1EEFC',
        200: '#A7D8F0',
        300: '#7CC1E4',
        400: '#55AAD4',
        500: '#3994C1',
        600: '#2D83AE',
        700: '#1D6F98',
        800: '#166086',
        900: '#0B4F71'
    },
    custom_lightBlueVivid: {
        100: '#B3ECFF',
        200: '#81DEFD',
        300: '#5ED0FA',
        400: '#40C3F7',
        500: '#2BB0ED',
        600: '#1992D4',
        700: '#127FBF',
        800: '#0B69A3',
        900: '#035388'
    },
    custom_blue: {
        100: '#B6E0FE',
        200: '#84C5F4',
        300: '#62B0E8',
        400: '#4098D7',
        500: '#2680C2',
        600: '#186FAF',
        700: '#0F609B',
        800: '#0A558C',
        900: '#003E6B'
    },
    custom_blueVivid: {
        100: '#BAE3FF',
        200: '#7CC4FA',
        300: '#47A3F3',
        400: '#2186EB',
        500: '#0967D2',
        600: '#0552B5',
        700: '#03449E',
        800: '#01337D',
        900: '#002159'
    },
    custom_indigo: {
        100: '#BED0F7',
        200: '#98AEEB',
        300: '#7B93DB',
        400: '#647ACB',
        500: '#4C63B6',
        600: '#4055A8',
        700: '#35469C',
        800: '#2D3A8C',
        900: '#19216C'
    },
    custom_indigoVivid: {
        100: '#B0D0FF',
        200: '#88B1FC',
        300: '#5E8AEE',
        400: '#3A66DB',
        500: '#2251CC',
        600: '#1D3DBF',
        700: '#132DAD',
        800: '#0B1D96',
        900: '#061178'
    },
    custom_purple: {
        100: '#CFBCF2',
        200: '#A081D9',
        300: '#8662C7',
        400: '#724BB7',
        500: '#653CAD',
        600: '#51279B',
        700: '#421987',
        800: '#34126F',
        900: '#240754'
    },
    custom_purpleVivid: {
        100: '#DAC4FF',
        200: '#B990FF',
        300: '#A368FC',
        400: '#9446ED',
        500: '#8719E0',
        600: '#7A0ECC',
        700: '#690CB0',
        800: '#580A94',
        900: '#44056E'
    },
    custom_magenta: {
        100: '#ECBDF2',
        200: '#CE80D9',
        300: '#BB61C7',
        400: '#AD4BB8',
        500: '#A23DAD',
        600: '#90279C',
        700: '#7C1A87',
        800: '#671270',
        900: '#4E0754'
    },
    custom_magentaVivid: {
        100: '#F8C4FE',
        200: '#F48FFF',
        300: '#F368FC',
        400: '#ED47ED',
        500: '#E019D0',
        600: '#CB10B8',
        700: '#B30BA3',
        800: '#960888',
        900: '#6E0560'
    },
    custom_pink: {
        100: '#FAB8D9',
        200: '#F191C1',
        300: '#E668A7',
        400: '#DA4A91',
        500: '#C42D78',
        600: '#AD2167',
        700: '#9B1B5A',
        800: '#781244',
        900: '#5C0B33'
    },
    custom_pinkVivid: {
        100: '#FFB8D2',
        200: '#FF8CBA',
        300: '#F364A2',
        400: '#E8368F',
        500: '#DA127D',
        600: '#BC0A6F',
        700: '#A30664',
        800: '#870557',
        900: '#620042'
    },
    custom_blueGrey: {
        100: '#D9E2EC',
        200: '#BCCCDC',
        300: '#9FB3C8',
        400: '#829AB1',
        500: '#627D98',
        600: '#486581',
        700: '#334E68',
        800: '#243B53',
        900: '#102A43'
    },
    custom_coolGrey: {
        100: '#E4E7EB',
        200: '#CBD2D9',
        300: '#9AA5B1',
        400: '#7B8794',
        500: '#616E7C',
        600: '#52606D',
        700: '#3E4C59',
        800: '#323F4B',
        900: '#1F2933'
    },
    custom_grey: {
        100: '#E1E1E1',
        200: '#CFCFCF',
        300: '#B1B1B1',
        400: '#9E9E9E',
        500: '#7E7E7E',
        600: '#626262',
        700: '#515151',
        800: '#3B3B3B',
        900: '#222222'
    },
    custom_warmGrey: {
        100: '#E8E6E1',
        200: '#D3CEC4',
        300: '#B8B2A7',
        400: '#A39E93',
        500: '#857F72',
        600: '#625D52',
        700: '#504A40',
        800: '#423D33',
        900: '#27241D'
    }
};
