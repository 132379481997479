<template>
  <div
    class="refonte-date"
    v-bind:class="`${nextDate && parseToFrDate(nextDate) === parseToFrDate(todayDate) ? 'date-today' : ''} ${props.class}`">
    <div
      v-if="props.dates.length === 1"
      class="refonte-date-icon">
      <IconDate />
    </div>
    <refonte-tag
      v-else
      class="refonte-date-tag"
      v-bind:icon="IconDate">
      <refonte-number variant="count"
        >{{ nextDateIndex }}/{{ props.dates.length }}</refonte-number
      >
    </refonte-tag>
    <span>{{ parseToFrDate(nextDate) }}</span>
  </div>
</template>

<script setup lang="ts">
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import { computed, ref } from 'vue';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      dates: Date[];
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const todayDate: Date = new Date();

  const nextDateIndex = ref<number>(1);
  function setNextDateIndex(newIndex: number) {
    nextDateIndex.value = newIndex;
  }

  function parseToFrDate(date: Date) {
    return format(date.toString(), 'dd/MM/yyyy', {
      locale: fr as unknown as Locale
    });
  }
  function compareDates(dateA: Date, dateB: Date) {
    dateA.setHours(0, 0, 0, 0);
    dateB.setHours(0, 0, 0, 0);
    return dateA >= dateB;
  }

  const nextDate = computed<Date>(() => {
    let newNextDate;
    const sortedDates = props.dates;
    sortedDates.sort((a, b) => a.getTime() - b.getTime());
    if (sortedDates.filter((date) => compareDates(date, todayDate))[0]) {
      newNextDate = sortedDates.filter((date) =>
        compareDates(date, todayDate)
      )[0];
    } else {
      newNextDate = props.dates[props.dates.length - 1];
    }
    setNextDateIndex(sortedDates.indexOf(newNextDate) + 1);
    return newNextDate;
  });
</script>

<style lang="scss" scoped>
  .refonte-date {
    @apply inline-flex shrink-0 items-center;
    gap: 2px;

    .refonte-date-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte_blue-400;
        width: 16px;
        height: 16px;
      }
    }
    .refonte-date-tag {
      margin-right: 2px;
    }
    span {
      @apply text-refonte_blue-400;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }

    &.date-today {
      .refonte-date-icon {
        :deep(svg) {
          @apply text-refonte_blue-800;
        }
      }
      span {
        @apply text-refonte_blue-800;
      }
    }
  }
</style>
