import { API } from '@/http-common.js';
import { contratsData } from '@/stores/data/contrats-data';
import { Filters } from '@/types/gestion/contrats-filters-types';
import { Contrat, ContratResponse } from '@/types/gestion/contrats-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    statutId: undefined,
    formateurId: undefined,
    natures: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    statutId: undefined,
    formateurId: undefined,
    natures: undefined
};

export const CONTRATS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    statutId: {
        name: 'statutId',
        isArray: false
    },
    formateurId: {
        name: 'formateurId',
        isArray: false
    },
    natures: {
        name: 'natures',
        isArray: true
    }
};

export const useContratFilterStore = defineStore('contrats-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = CONTRATS_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useContratStore = defineStore('contrats', () => {
    const contrats = ref<Contrat[]>([]);

    const fetchContrats = async (query: Filters) => {
        // TODO BACK pour connecter
        // const response = await API.get(`client/v2/contrats`, {
        //     params: query
        // });
        // if (response?.data) {
        //     contrats.value = response?.map((c: ContratResponse) =>
        //         mapContrat(c)
        //     );
        // }

        let allContrats = contratsData;
        if (query.statutId) {
            allContrats = allContrats.filter(
                (c) => c.statut?.id.toString() === query.statutId?.toString()
            );
        }
        if (query.formateurId) {
            allContrats = allContrats.filter(
                (c) =>
                    c.formateur.id.toString() === query.formateurId?.toString()
            );
        }
        if (query.natures) {
            allContrats = allContrats.filter(
                (c) => query.natures && query.natures.includes(c.nature.id)
            );
        }
        if (query.clientIds) {
            allContrats = allContrats.filter(
                (c) => c.client && c.client?.id == query.clientIds
            );
        }
        contrats.value = allContrats?.map((c: ContratResponse) =>
            mapContrat(c)
        );
    };

    const mapContrat = (c: ContratResponse): Contrat => {
        return {
            ...c,
            clientDenomination: c.client.denomination,
            clientSiret: c.client.siret ?? undefined,
            natureLabel: c.nature.label,
            formateurNomPrenom: `${c.formateur.nom} ${c.formateur.prenom}`,
            typeLabel: c.type.label,
            statutLabel: c.statut ? c.statut.label : undefined,
            ...(c.mode && { modeLabel: c.mode.label })
        };
    };

    return {
        contrats,
        fetchContrats
    };
});
