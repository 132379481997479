<template>
  <template v-if="!loading">
    <refonte-card>
      <formations-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <formations-number
          v-bind:number="formations.length"
          v-bind:label="
            formations.length > 1 ? 'Inscriptions' : 'Inscription'
          " />
        <IconSeparatorVertical />
        <formations-number
          v-bind:number="nombreConventionsASigner"
          v-bind:label="`${
            nombreConventionsASigner > 1 ? 'Conventions' : 'Convention'
          } à signer`"
          v-bind:on_click="
            () => {
              formationFilterStore.setFilter(
                FILTERS_CONFIGURATION.action.name,
                'CONVENTIONS_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical />
        <formations-number
          v-bind:number="nombrePifsASigner"
          label="PIF à signer"
          v-bind:on_click="
            () => {
              formationFilterStore.setFilter(
                FILTERS_CONFIGURATION.action.name,
                'PIF_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical />
        <formations-number
          v-bind:number="nombreTestsPositionnementNonRealise"
          v-bind:label="`${
            nombreTestsPositionnementNonRealise > 1 ? 'Tests' : 'Test'
          } de positionnement non ${
            nombreTestsPositionnementNonRealise > 1 ? 'réalisés' : 'réalisé'
          }`"
          v-bind:on_click="
            () => {
              formationFilterStore.setFilter(
                FILTERS_CONFIGURATION.action.name,
                'T_POS_NON_REALISE'
              );
            }
          " />
      </div>
      <refonte-separator class="px-[12px]" />
      <formations-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import FormationsContent from '@/views/_espace/_refonte/gestion/_formations/formations-content.vue';
  import {
    FILTERS_CONFIGURATION,
    useFormationFilterStore,
    useFormationStore
  } from '@/stores/formations';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import FormationsNumber from '@/views/_espace/_refonte/gestion/_formations/formations-number.vue';
  import FormationsFilters from '@/views/_espace/_refonte/gestion/_formations/formations-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { Formation } from '@/types/gestion/formations-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const formationFilterStore = useFormationFilterStore();

  const { filters } = storeToRefs(formationFilterStore);

  const dataLoading = ref<boolean>(false);
  const formationStore = useFormationStore();
  const { formations } = storeToRefs(formationStore);

  const nombreConventionsASigner = computed(
    () =>
      formations.value.filter(
        (f: Formation) =>
          f.conventionStatut && f.conventionStatut === 'A signer'
      ).length
  );

  const nombrePifsASigner = computed(
    () =>
      formations.value.filter((f: Formation) => f.pifStatut === 'A signer')
        .length
  );

  const nombreTestsPositionnementNonRealise = computed(
    () =>
      formations.value.filter(
        (f: Formation) => f.testPositionnementStatut === 'Non réalisé'
      ).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await formationStore.fetchFormations(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    formationFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, [
      FILTERS_CONFIGURATION.dateDebut.name,
      FILTERS_CONFIGURATION.dateFin.name,
      FILTERS_CONFIGURATION.dateDebutPeriodeDebut.name,
      FILTERS_CONFIGURATION.dateDebutPeriodeFin.name,
      FILTERS_CONFIGURATION.dateFinPeriodeDebut.name,
      FILTERS_CONFIGURATION.dateFinPeriodeFin.name
    ]);

    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });
</script>

<style lang="scss" scoped></style>
