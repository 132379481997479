<template>
  <div
    class="refonte-filters"
    v-bind:class="props.class">
    <div class="filters-buttons">
      <div class="filters-buttons-icon">
        <IconFilters />
      </div>
      <div class="filters-buttons-list">
        <slot></slot>
      </div>
    </div>
    <div
      v-if="!!slots['filtersApplied'] && show_filters_applied"
      class="filters-applied">
      <div class="filters-applied-list">
        <slot name="filtersApplied"></slot>
      </div>
      <refonte-button
        variant="text"
        v-on:click="on_delete_click"
        >Effacer tout</refonte-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconFilters from '@/assets/img/refonte/icons/action/icon-filters.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      class?: string;
      on_delete_click?: Function;
      show_filters_applied: boolean;
    }>(),
    {
      class: '',
      show_filters_applied: false
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-filters {
    @apply flex flex-col items-start justify-center bg-refonte-white text-refonte_blue-600;
    gap: 4px;
    border-radius: 8px;
    padding: 0 8px 0 12px;
    width: 100%;

    .filters-buttons {
      @apply flex items-center self-stretch;

      .filters-buttons-icon {
        @apply flex items-center justify-center;
        min-width: 16px;
        width: 16px;
        height: 16px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
          height: 16px;
        }
      }
      .filters-buttons-list {
        @apply flex flex-wrap items-center;
        gap: 4px;
        padding: 0 4px;
      }
    }
    .filters-applied {
      @apply flex flex-grow items-center self-stretch bg-refonte-pale-violet-1;
      gap: 6px;
      border-radius: 4px;
      padding: 5px 8px;

      .filters-applied-list {
        @apply flex flex-grow flex-wrap items-center;
        gap: 8px;
      }
    }
  }
</style>
