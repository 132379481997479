<template>
  <div class="p-[8px]">
    <template v-if="etablissements.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="etablissements-1"
        table_index="etablissements-1"
        main_data_index="adresse"
        v-bind:get_main_label="(rowIndex: number) => 'Adresse'"
        v-bind:main_width="240"
        main_data_etablissement="denomination"
        main_data_etablissement_siret="siret"
        v-bind:main_data_etablissement_status="
          (row: Etablissement) => (row.desactivable ? undefined : 'Désactivé')
        "
        v-bind:main_data_etablissement_icon="
          (row: Etablissement) =>
            row.desactivable ? IconPastilleActive : IconPastilleDeactivated
        "
        v-bind:main_data_etablissement_width="350"
        v-bind:columns="columns"
        v-bind:data="etablissements" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <refonte-modal-desactiver-etablissement
    v-bind:show="isDesactiverEtablissementModalOpen"
    v-bind:on_close="() => setIsDesactiverEtablissementModalOpen(false)"
    v-bind:on_submit="desactiverEtablissement" />
  <refonte-modal-modifier-etablissement
    v-bind:show="isModifierEtablissementModalOpen"
    v-bind:on_close="() => closeModifierEtablissementModalOpen()"
    v-bind:on_submit="modifierEtablissement"
    v-bind:active_etablissement="activeEtablissement" />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useEtablissementStore } from '@/stores/etablissements';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Etablissement } from '@/types/gestion/etablissements-types';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import IconCheck from '@/assets/img/refonte/icons/display/icon-check.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconInterlocuteurs from '@/assets/img/refonte/icons/menu/icon-user.svg?component';
  import IconPastilleActive from '@/assets/img/refonte/icons/states/icon-pastille-active.svg?component';
  import IconPastilleDeactivated from '@/assets/img/refonte/icons/states/icon-pastille-deactivated.svg?component';
  import { ref } from 'vue';
  import RefonteModalDesactiverEtablissement from '@/components/refonte/modals/refonte-modal-desactiver-etablissement.vue';
  import RefonteModalModifierEtablissement from '@/components/refonte/modals/refonte-modal-modifier-etablissement.vue';
  import { Formation } from '@/types/gestion/formations-types';
  import { useRouter } from 'vue-router';

  const columns = [
    {
      data_index: 'inscriptionLabel',
      label: 'Inscription',
      width: 120
    },
    {
      data_index: 'facturationAutresTiersLabel',
      label: 'Facturation Autres Tiers',
      width: 180
    },
    {
      data_index: 'interlocuteurs',
      label: 'Interlocuteurs',
      content_icon: IconInterlocuteurs,
      width: 120
    },
    {
      data_index: 'contacts',
      label: 'Contacts',
      content_icon: IconParticipants,
      width: 90
    },
    {
      data_index: 'modifiable',
      content_button_icon: (row: Etablissement) => IconModify,
      content_button_on_click: (row: Etablissement) => {
        openModifierEtablissementModalOpen(row);
      },
      content_button_variant: (row: Etablissement) => 'success',
      content_button_title: (row: Etablissement) =>
        row.modifiable ? 'Modifier' : 'Modification impossible',
      content_button_disabled: (row: Etablissement) => !row.modifiable,
      width: 32
    },
    {
      data_index: 'desactivable',
      content_button_icon: (row: Etablissement) =>
        row.desactivable ? IconClose : IconCheck,
      content_button_on_click: (row: Etablissement) => {
        if (row.desactivable) {
          setIsDesactiverEtablissementModalOpen(true);
        }
      },
      content_button_variant: (row: Etablissement) =>
        row.desactivable ? 'danger' : 'success',
      content_button_title: (row: Etablissement) =>
        row.desactivable ? 'Désactiver' : 'Réactiver',
      width: 40
    }
  ];

  const etablissementStore = useEtablissementStore();

  const { etablissements } = storeToRefs(etablissementStore);

  const isDesactiverEtablissementModalOpen = ref<boolean>(false);

  function setIsDesactiverEtablissementModalOpen(value: boolean) {
    isDesactiverEtablissementModalOpen.value = value;
  }
  function desactiverEtablissement(motif: string) {}

  const activeEtablissement = ref<Etablissement>();
  const isModifierEtablissementModalOpen = ref<boolean>(false);

  function closeModifierEtablissementModalOpen() {
    isModifierEtablissementModalOpen.value = false;
    activeEtablissement.value = undefined;
  }
  function openModifierEtablissementModalOpen(value: Etablissement) {
    isModifierEtablissementModalOpen.value = true;
    activeEtablissement.value = value;
  }
  function modifierEtablissement() {}

  const router = useRouter();
  const goToDetails = (row: Formation) => {
    router.push(`/refonte/entreprise/etablissements-details?id=${row.id}`);
  };
</script>

<style lang="scss" scoped></style>
