import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/formations-filters-types';
import {
    Formation,
    FormationResponse,
    typeMapping
} from '@/types/gestion/formations-types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    formationId: undefined,
    dateDebut: undefined,
    dateDebutPeriodeId: undefined,
    dateDebutPeriodeDebut: undefined,
    dateDebutPeriodeFin: undefined,
    dateFin: undefined,
    dateFinPeriodeId: undefined,
    dateFinPeriodeDebut: undefined,
    dateFinPeriodeFin: undefined,
    contactId: undefined,
    modeId: undefined,
    action: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    formationId: undefined,
    dateDebut: undefined,
    dateDebutPeriodeId: undefined,
    dateDebutPeriodeDebut: undefined,
    dateDebutPeriodeFin: undefined,
    dateFin: undefined,
    dateFinPeriodeId: undefined,
    dateFinPeriodeDebut: new Date(),
    dateFinPeriodeFin: undefined,
    contactId: undefined,
    modeId: undefined,
    action: undefined
};

export const FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    dateDebut: {
        name: 'dateDebut',
        isArray: false
    },
    dateDebutPeriodeId: {
        name: 'dateDebutPeriodeId',
        isArray: false
    },
    dateDebutPeriodeDebut: {
        name: 'dateDebutPeriodeDebut',
        isArray: false
    },
    dateDebutPeriodeFin: {
        name: 'dateDebutPeriodeFin',
        isArray: false
    },
    dateFin: {
        name: 'dateFin',
        isArray: false
    },
    dateFinPeriodeId: {
        name: 'dateFinPeriodeId',
        isArray: false
    },
    dateFinPeriodeDebut: {
        name: 'dateFinPeriodeDebut',
        isArray: false
    },
    dateFinPeriodeFin: {
        name: 'dateFinPeriodeFin',
        isArray: false
    },
    contactId: {
        name: 'contactId',
        isArray: false
    },
    modeId: {
        name: 'modeId',
        isArray: false
    },
    action: {
        name: 'action',
        isArray: false
    }
};

export const useFormationFilterStore = defineStore('formations-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
            if (
                [
                    FILTERS_CONFIGURATION.dateDebut.name,
                    FILTERS_CONFIGURATION.dateFin.name,
                    FILTERS_CONFIGURATION.dateDebutPeriodeDebut.name,
                    FILTERS_CONFIGURATION.dateDebutPeriodeFin.name,
                    FILTERS_CONFIGURATION.dateFinPeriodeDebut.name,
                    FILTERS_CONFIGURATION.dateFinPeriodeFin.name
                ].includes(key)
            ) {
                newPayload[key] = new Date(newPayload[key]);
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useFormationStore = defineStore('formations', () => {
    const allCheckboxSelected = ref(false);
    const formations = ref<Formation[]>([]);

    const fetchFormations = async (query: Filters) => {
        const response = await API.get(`client/v2/formations`, {
            params: query
        });
        if (response?.data) {
            formations.value = response?.data.map((f: FormationResponse) =>
                mapFormation(f)
            );
        }
    };

    const mapFormation = (f: FormationResponse): Formation => {
        return {
            ...f,
            clientDenomination: f.client.denomination,
            clientSiret: f.client.siret ?? undefined,
            participantNomPrenom: f.participant.nomPrenom,
            typeShortcut: typeMapping[f.type.id],
            typeLabel: f.type.label,
            statutLabel: f.statut.label,
            selected: allCheckboxSelected.value,
            ...(f.mode && { modeLabel: f.mode.label }),
            ...(f.convention?.id && {
                conventionStatut: buildDocumentStatut(
                    f.convention?.disponible,
                    f.convention?.signee,
                    f.convention?.validee
                )
            }),
            ...(f.pif?.id && {
                pifStatut: buildDocumentStatut(
                    f.pif?.disponible,
                    f.pif?.signee,
                    f.pif?.validee
                )
            }),
            ...(f.certificatId && {
                certificatLabel: 'Télécharger'
            }),
            ...(f.testPositionnement && {
                testPositionnementStatut: buildTestPositionnementStatut(
                    f.testPositionnement?.disponible,
                    f.testPositionnement?.realise
                )
            }),
            clientId: f.client.id,
            titre: `${f.produit.titre}${f.familleStageEC ? ' (Stage EC)' : ''}${f.familleStageCAC ? ' (Stage CAC)' : ''}`,
            datesFormations: buildDate(f.dateDebut, f.dateFin),
            titreAlt: f.parcours?.titre
                ? f.parcours.titre
                : f.cursus?.titre
                  ? f.cursus.titre
                  : undefined
        };
    };

    const buildTestPositionnementStatut = (
        disponible?: boolean,
        realise?: boolean
    ) => {
        if (!disponible) {
            return 'Non requis';
        } else if (disponible && !realise) {
            return 'Non réalisé';
        } else if (realise) {
            return 'Réalisé';
        }
    };
    const buildDocumentStatut = (
        disponible?: boolean,
        signee?: boolean,
        validee?: boolean
    ) => {
        if (disponible && !signee && !validee) {
            return 'A signer';
        } else if (signee) {
            return 'Signée';
        } else if (validee) {
            return 'Validée';
        }
    };
    const buildDate = (dateDebut: string, dateFin: string): string => {
        if (dateDebut === dateFin) {
            return dateDebut;
        } else {
            return `${dateDebut} - ${dateFin}`;
        }
    };

    const selectFormationItem = (id: string, value: boolean) => {
        const formationIndex = formations.value.findIndex((f) => f.id === id);
        if (formationIndex != -1) {
            const newFormations = formations.value.slice();
            newFormations[formationIndex] = {
                ...newFormations[formationIndex],
                selected: value
            };
            formations.value = newFormations;
        }
    };

    const selectAllFormationItems = (value: boolean) => {
        allCheckboxSelected.value = value;
        formations.value = formations.value
            .slice()
            .map((f) => ({ ...f, selected: value }));
    };

    const hasFormationsSelected = computed(() =>
        formations.value.some((f) => f.selected)
    );

    const getFormationsSelected = computed(() => [
        ...formations.value.filter((f) => f.selected)
    ]);
    return {
        formations,
        fetchFormations,
        selectFormationItem,
        selectAllFormationItems,
        hasFormationsSelected,
        getFormationsSelected
    };
});
