<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.references) ||
      filters_utils.methods.hasValue(filters.date) ||
      filters_utils.methods.hasValue(filters.datePeriodeFin) ||
      filters_utils.methods.hasValue(filters.datePeriodeDebut) ||
      filters_utils.methods.hasValue(filters.datePeriodeId) ||
      filters_utils.methods.hasValue(filters.vendeurId) ||
      filters_utils.methods.hasValue(filters.reglementStatutSuiviId)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateReferenceValue(value)"
        v-bind:on_open="() => resetReferences()"
        v-bind:footer_button_on_click="() => updateReferencesFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryReferences &&
            temporaryReferences.length !==
              (filters.references ? filters.references.length : 0)
          )
        "
        v-bind:number="filters.references?.length ?? undefined">
        Référence
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="referencesList.length"
            v-for="r in referencesList"
            v-bind:key="r.reference"
            v-bind:label="r.reference"
            v-bind:sub_label="
              r.date &&
              format(r.date.toString(), 'dd/MM/yyyy', {
                locale: fr as unknown as Locale
              })
            "
            v-bind:value="!!temporaryReferences?.includes(r.reference)"
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateReferences(r.reference)" />
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:footer_button_on_click="() => updateDateFilter()">
        Date
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="periode in PERIOD_IDS"
            v-bind:on_click="
              () =>
                updateFilter(
                  COMMANDES_FILTERS_CONFIGURATION.datePeriodeId.name,
                  periode.id
                )
            "
            v-bind:key="periode.id">
            {{ periode.label }}
          </refonte-dropdown-item>
          <div class="p-2">
            <refonte-label label="Date" />
            <refonte-datepicker
              v-on:input="(value) => updateDate('date', value)"
              class="p-1"
              placeholder="Date exacte (JJ/MM/YYYY)"
              v-bind:value="date?.date" />
          </div>
          <refonte-separator />
          <div class="p-2">
            <refonte-label label="Période" />
            <refonte-datepicker
              class="p-1"
              placeholder="Début (JJ/MM/YYYY)"
              v-on:input="(value) => updateDate('debut', value)"
              v-bind:value="date?.debut" />
            <refonte-datepicker
              class="p-1"
              placeholder="Fin (JJ/MM/YYYY)"
              v-on:input="(value) => updateDate('fin', value)"
              v-bind:value="date?.fin" />
          </div>
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Règlement
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="reglement in reglementsList"
            v-bind:on_click="
              () =>
                updateFilter(
                  COMMANDES_FILTERS_CONFIGURATION.reglementStatutSuiviId.name,
                  reglement.id
                )
            "
            v-bind:key="reglement.id">
            {{ reglement.label }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateVendeurValue(value)">
        Organisme de formation
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="vendeurList.length"
            v-for="vendeur in vendeurList"
            v-bind:on_click="
              () =>
                updateFilter(
                  COMMANDES_FILTERS_CONFIGURATION.vendeurId.name,
                  vendeur.id
                )
            "
            v-bind:key="vendeur.id">
            {{ vendeur.nom }}
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref } from 'vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import {
    COMMANDES_FILTERS_CONFIGURATION,
    useCommandeFilterStore
  } from '@/stores/commandes';
  import {
    CommandeMetadataReference,
    CommandeMetadataVendeur
  } from '@/types/gestion/commandes-filters-types';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { API } from '@/http-common';
  import { IdLabel } from '@/types/gestion/entreprise';
  import filters_utils from '@/mixin/utils/filters_utils';

  const referenceSearchValue = ref<string>('');

  const updateReferenceValue = (value: string) => {
    referenceSearchValue.value = value;
  };

  const vendeurSearchValue = ref<string>('');
  const updateVendeurValue = (value: string) => {
    vendeurSearchValue.value = value;
  };
  const reglementsList = ref<IdLabel[]>([]);

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDate = (name: string, value: undefined | Date | string) => {
    date.value[name] = value;
  };

  const getReglementStatutSuiviLabel = (
    list: { id: string | number; label: string }[],
    id: number | string | undefined
  ) => {
    const item = list.find((mode) => mode.id == id);
    return item
      ? filters_utils.methods.formatFilter('Règlement', item.label)
      : '';
  };
  const getVendeurNom = (id: number | undefined) => {
    const item = vendeurFilterList.value.find((v) => v.id == id);
    return item ? filters_utils.methods.formatFilter('Vendeur', item.nom) : '';
  };

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);
  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const commandeFilterStore = useCommandeFilterStore();
  const { filters } = storeToRefs(commandeFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[]
  ) => {
    commandeFilterStore.setFilter(name, value);
  };
  const date = ref<DateFilter>({
    date: filters.value.date,
    debut: filters.value.datePeriodeDebut,
    fin: filters.value.datePeriodeFin,
    periodeId: filters.value.datePeriodeId
  });

  const resetFilters = () => {
    commandeFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    date.value = { ...dateFilterDefaut };
    temporaryReferences.value = undefined;
  };
  const updateDateFilter = () => {
    const dateValue = date.value;
    updateFilter(COMMANDES_FILTERS_CONFIGURATION.date.name, dateValue.date);
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      dateValue.debut
    );
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      dateValue.fin
    );
  };

  const temporaryReferences = ref<string[] | undefined>(
    filters.value.references
  );

  const updateReferencesFilter = () => {
    updateFilter(
      COMMANDES_FILTERS_CONFIGURATION.references.name,
      temporaryReferences.value && temporaryReferences.value.length > 0
        ? temporaryReferences.value
        : undefined
    );
  };

  const resetReferences = () => {
    temporaryReferences.value = filters.value.references;
  };

  const updateReferences = (value: string) => {
    if (temporaryReferences.value?.includes(value)) {
      temporaryReferences.value = temporaryReferences.value.filter(
        (r) => r !== value
      );
    } else {
      temporaryReferences.value = temporaryReferences.value
        ? [...temporaryReferences.value, value]
        : [value];
    }
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: COMMANDES_FILTERS_CONFIGURATION.references.name,
      value: filters.value.references
        ? filters_utils.methods.formatFilter(
            `Référence${filters.value.references.length > 1 ? 's' : ''}`,
            filters.value.references.join(', ')
          )
        : undefined
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.date.name,
      value: filters_utils.methods.formatFilter(
        'Date',
        formatDate(filters.value.date)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date',
        getPeriodFilterApply(filters.value.datePeriodeId)
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date',
        `Après le
          ${formatDate(filters.value.datePeriodeDebut)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date',
        `Avant le
          ${formatDate(filters.value.datePeriodeFin)}`
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.reglementStatutSuiviId.name,
      value: getReglementStatutSuiviLabel(
        reglementsList.value,
        filters.value.reglementStatutSuiviId
      )
    },
    {
      id: COMMANDES_FILTERS_CONFIGURATION.vendeurId.name,
      value: getVendeurNom(filters.value.vendeurId)
    }
  ]);

  const referenceFilterList = ref<CommandeMetadataReference[]>([]);

  const referencesList = computed(() =>
    referenceFilterList.value.filter(
      (r) =>
        referenceSearchValue.value.length < 3 ||
        r.reference
          .toLowerCase()
          .includes(referenceSearchValue.value.toLowerCase())
    )
  );

  const vendeurFilterList = ref<CommandeMetadataVendeur[]>([]);

  const vendeurList = computed(() =>
    vendeurFilterList.value.filter(
      (f) =>
        vendeurSearchValue.value.length < 3 ||
        f.nom.toLowerCase().includes(vendeurSearchValue.value.toLowerCase())
    )
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const fetchMetadata = async () => {
    const response = await API.get(
      `client/v2/commandes/metadata?clientIds=${activeClientIds.value}`
    );

    if (response && response.data) {
      referenceFilterList.value = response.data.references;
      vendeurFilterList.value = response.data.vendeurs;
      reglementsList.value = response.data.reglements;
    }
  };

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
