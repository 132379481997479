<template>
  <div class="p-[8px]">
    <template v-if="commandes.length">
      <refonte-table
        key="commandes-1"
        table_index="commandes-1"
        main_data_index="reference"
        v-bind:get_main_label="(rowIndex: number) => 'Référence'"
        v-bind:main_width="120"
        v-bind:main_data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:main_data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:columns="columns"
        v-bind:data="commandes" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Commande } from '@/types/gestion/commandes-types';
  import { useCommandeStore } from '@/stores/commandes';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';

  const columns = [
    {
      data_index: 'organisateur',
      label: 'Organisme de formation',
      width: 170
    },
    {
      data_index: 'date',
      label: 'Date de commande',
      width: 140
    },
    {
      data_index: 'montantTtc',
      label: 'Montant TTC',
      width: 120
    },
    {
      data_index: 'montantRegle',
      label: 'Montant Réglé',
      width: 120
    },
    {
      data_index: 'reglementStatutSuiviLabel',
      label: 'Règlement',
      width: 90
    },
    {
      data_index: 'payeurLabel',
      label: 'Entité Payeur',
      width: 240
    },
    {
      data_index: 'bonCommandeLabel',
      label: 'Bon de commande',
      width: 130,
      on_click: (rowIndex: number) => {},
      data_icon: (row: Commande) =>
        row.bonCommandeId ? IconDownload : undefined
    }
  ];

  const commandeStore = useCommandeStore();

  const { commandes } = storeToRefs(commandeStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
