<template>
  <refonte-dropdown
    v-if="entreprises && entreprises.length > 1"
    class="header-scope-dropdown"
    v-bind:on_open="() => setSearchValue('')"
    v-bind:on_search="(value: string) => setSearchValue(value)">
    <div
      class="refonte-header-scope header-scope-with-entities"
      v-bind:class="activeRubrique.admin ? 'refonte-header-scope-admin' : ''">
      <div class="header-scope-icon">
        <IconEstablishments />
      </div>
      <refonte-item-all-entities
        v-if="activeEntrepriseId === ALL_ENTREPRISE_FILTER"
        v-bind:admin="activeRubrique.admin" />
      <refonte-item-entity
        v-else
        v-bind:entity="selectedEntity"
        v-bind:light="true"
        v-bind:admin="activeRubrique.admin" />
      <div class="header-scope-chevron">
        <IconChevronDown />
      </div>
    </div>
    <template v-slot:dropdownContent>
      <refonte-item-all-entities
        v-if="activeEntrepriseId !== ALL_ENTREPRISE_FILTER"
        v-on:click="() => selectEntreprise(ALL_ENTREPRISE_FILTER)"
        v-bind:admin="activeRubrique.admin" />
      <refonte-item-entity
        v-bind:key="index"
        v-for="(entity, index) in entreprises.filter(
          (e: Entreprise) =>
            e.entrepriseId != activeEntrepriseId &&
            filterBySearch(e, searchValue)
        ) as Entreprise[]"
        v-on:click="() => selectEntreprise(entity.entrepriseId)"
        v-bind:entity="entity"
        v-bind:admin="activeRubrique.admin" />
      <div
        class="refonte-item-no-result"
        v-if="
          searchValue != '' &&
          entreprises.filter(
            (e: Entreprise) =>
              e.entrepriseId != activeEntrepriseId &&
              filterBySearch(e, searchValue)
          ).length === 0
        ">
        Aucun résultat trouvé pour votre recherche
      </div>
    </template>
  </refonte-dropdown>
  <div
    v-else
    class="refonte-header-scope"
    v-bind:class="activeRubrique.admin ? 'refonte-header-scope-admin' : ''">
    <div class="header-scope-icon">
      <IconEstablishments />
    </div>
    <refonte-item-entity
      v-if="selectedEntity"
      v-bind:entity="selectedEntity" />
    <div class="header-scope-chevron">
      <IconChevronDown />
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconEstablishments from '@/assets/img/refonte/icons/menu/icon-entities.svg?component';
  import { computed, ComputedRef, Ref, ref, watch } from 'vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteItemEntity from '@/components/refonte/items/refonte-item-entity.vue';
  import RefonteItemAllEntities from '@/components/refonte/items/refonte-item-all-entities.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import { storeToRefs } from 'pinia';
  import { useRoute, useRouter } from 'vue-router';
  import { Entreprise } from '@/types/gestion/entreprise';
  import { useArborescenceStore } from '@/stores/arborescence';

  const entrepriseStore = useEntrepriseStore();

  const { entreprises, activeEntrepriseId } = storeToRefs(entrepriseStore);

  const selectedEntity: ComputedRef<Entreprise> = computed(() => {
    const index = entreprises.value.findIndex(
      (e: Entreprise) => e.entrepriseId == activeEntrepriseId.value
    );
    return index != -1 ? entreprises.value[index] : entreprises.value[0];
  });

  const searchValue: Ref<string> = ref('');

  function setSearchValue(value: string) {
    searchValue.value = value;
  }

  function filterBySearch(e: Entreprise, value: string) {
    return (
      value === '' ||
      (e.denomination + e.codePostal + e.ville + e.siret)
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }
  function selectEntreprise(id: number) {
    if (id !== ALL_ENTREPRISE_FILTER) {
      const entreprise = entreprises.value.find(
        (e: Entreprise) => e.entrepriseId === id
      );
      if (entreprise) {
        //TODO Mettre à jour les droits
        /* store.dispatch(MODULES.ESPACE + '/' + ACTION_TYPES.SET_RUBRIQUE, {
          espace: 'entreprise',
          rubrique: 'entreprise',
          name: 'restricted',
          value: !(
            entreprise.droitAdministrateur ||
            entreprise.droitAccesDocuments ||
            entreprise.droitInscription
          )
        });*/
        /*const activeEntreprise = selectedEntity.value;

      if (
         ((activeEntreprise.droitAdministrateur !==
           entreprise.droitAdministrateur ||
           activeEntreprise.droitInscription !== entreprise.droitInscription ||
           activeEntreprise.droitAccesDocuments !==
             entreprise.droitAccesDocuments) &&
           route.params.sub_space_id === 'entreprise' &&
           route.params.space_id === 'entreprise') ||
         route.params.sub_space_id === 'details'
       ) {
         router.push('/entreprise');
       }

       */
      }
    }
    entrepriseStore.setActiveEntrepriseId(id);
    setSearchValue('');
  }
  const router = useRouter();
  const route = useRoute();
  watch(activeEntrepriseId, async () => {
    if (activeEntrepriseId.value != ALL_ENTREPRISE_FILTER) {
      await router.push({
        query: { ...route.query, clientIds: activeEntrepriseId.value }
      });
    } else {
      await router.push({
        query: { ...route.query, clientIds: undefined }
      });
    }
  });

  const arborescenceStore = useArborescenceStore();
  const { activeRubrique } = storeToRefs(arborescenceStore);
</script>

<style lang="scss" scoped>
  .refonte-item-no-result {
    @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
    font-size: 13px;
    font-weight: 400;
    line-height: 125%;
    gap: 4px;
    padding: 8px;
  }
  .refonte-header-scope {
    @apply flex shrink-0 items-center bg-refonte-white;
    gap: 8px;
    width: 284px;
    max-width: 320px;

    @media (max-width: 767.98px) {
      @apply w-full;
      padding: 4px 8px !important;
      border-radius: 4px !important;
      height: 56px !important;
    }

    .header-scope-icon {
      @apply hidden;
    }
    :deep(.refonte-item-all-entities),
    :deep(.refonte-item-entity) {
      @apply cursor-default bg-transparent;
      padding: 0;
      border-radius: 0;

      &:hover {
        @apply bg-transparent;
      }
    }
    .header-scope-chevron {
      @apply hidden;
    }

    &.refonte-header-scope-admin {
      @apply bg-refonte-pale-violet-2;
    }

    &.header-scope-with-entities {
      @apply cursor-pointer;
      border-radius: 8px;
      padding: 6px 8px;
      height: 64px;
      width: 272px;

      &:hover {
        @apply bg-refonte-pale-violet-2;
      }

      &.refonte-header-scope-admin {
        &:hover {
          @apply bg-refonte-pale-violet-3;
        }
      }

      .header-scope-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 24px;
        height: 24px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 24px;
          height: 24px;
        }
      }
      :deep(.refonte-item-all-entities),
      :deep(.refonte-item-entity) {
        @apply cursor-pointer;
      }
      .header-scope-chevron {
        @apply flex shrink-0 items-center justify-center;
        width: 16px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
        }
      }
    }
  }
  .header-scope-dropdown {
    :deep(.dropdown-container) {
      min-width: 320px !important;
      width: auto !important;
      max-width: 320px !important;
    }
  }
  .header-scope-dropdown.dropdown-open {
    .refonte-header-scope {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
